/* tslint:disable:max-line-length */
import {DatePipe} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  LoadingSpinnerService
} from '@harmony-modules/configuration/sections/employee/employee-import/shared/loading-spinner/loading-spinner.service';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {ChequePrintingService} from '@harmony-modules/payroll/services/process/cheque-printing.service';
import {ReportServiceService} from '@harmony-modules/payroll/services/report/report-service.service';
import {
  IChequePaystubParams,
  IEmail,
  IEmailPayStub,
  IEmployeeChequePrinting,
  IObjList,
  IUpdatePayrollGroupPaymentDate
} from '@shared/models/icheque-printing';
import {IPayrollGroupsWithPaymentDate} from '@shared/models/ipayroll-group';
import {ImportedReport} from '@shared/models/ireport';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TelerikReportViewerComponent} from '@progress/telerik-angular-report-viewer';
import {AuthenticationService} from '@services/index';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {of, Subscription} from 'rxjs';
import {concatMap, take} from 'rxjs/operators';
import {IUserPreference} from 'src/app/shared/models/iuser';
import {EnvService} from '@services/env.service';
import {UserSetupService} from '@harmony-modules/user-setup/services/user-setup.service';

const scope = 'chequePrinting';

// import { MatRadioChange } from '@angular/material';

declare var showMsg: any;
declare var $: any;


@UntilDestroy()
@Component({
  selector: 'app-cheque-printing',
  templateUrl: './cheque-printing.component.html',
  styleUrls: ['./cheque-printing.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'chequePrinting', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class ChequePrintingComponent implements OnInit, OnDestroy {

  chequePaystubParams: IChequePaystubParams;
  chequePaystubParamsUpdate: IChequePaystubParams = {
    a: null,
    b: null,
    c: null,
    d: null,
    e: null,
    f: null,
    g: null,
    h: null,
    p1: null,
    p2: null,
    p3: null,
    p4: null,
    p5: null,
    p6: null,
    p7: null,
    p8: null,
    p9: null,
    p10: null,
    p11: null,
    p12: null,
    p13: null,
    p14: null,
    p15: null,
    c1: null,
    c2: null,
    c3: null,
    c4: null,
    c5: null,
    c6: null,
    al: null,
    at: null,
    bl: null,
    bt: null,
    cl: null,
    ct: null,
    dl: null,
    dt: null,
    el: null,
    et: null,
    fl: null,
    ft: null,
    gl: null,
    gt: null,
    hl: null,
    ht: null,
    ml: null,
    mt: null
  };
  employeeList: IEmployeeChequePrinting[] = [];
  employeeListCopy: IEmployeeChequePrinting[] = [];
  empWithoutSendEmailEnabled: IEmployeeChequePrinting[] = [];
  empWithoutPassword: IEmployeeChequePrinting[] = [];
  empWithoutEmailList: IEmployeeChequePrinting[] = [];
  empWithErrorList: IEmployeeChequePrinting[] = [];
  wizardGroupInfo: any;
  unprocessed_emp_list = '';
  // payrollGroups: IPayrollGroups[] = [{ a: null, b: null }];
  // optionTypeArr: any[] = [
  //  { no: -1, name: 'All' },
  //  { no: 0, name: 'Regular' },
  //  { no: 1, name: 'Special' }
  // ];
  // optionType: number;
  empListSub: Subscription;
  mobile = false;
  sysPref: any;
  rprtSrce: { report: string, parameters: { param: string, leftMargine: string, topMargine: string, culture: string, token: string } } = {
    report: '',
    parameters: {param: '', leftMargine: '', topMargine: '', culture: '', token: ''}
  };
  custom_rprtSrce: { report: string, parameters: { param: string, culture: string, token: string } } = {
    report: '',
    parameters: {param: '', culture: '', token: ''}
  };

  public opened = false;
  reportSource: any;

  viewerContainerStyle = {
    position: 'relative',
    display: 'flex',
    // width: '1000px',
    height: '800px',
    ['font-family']: 'ms sans serif'
  };

  viewerSendEmail = {enabled: true, from: '', to: '', cc: '', subject: '', body: '', format: ''};
  @ViewChild('viewer1') viewer: TelerikReportViewerComponent;
  baseURL: string = this.envService?.glReportUrl;
  parameter = '';
  // leftMargine: string = "";
  // topMargine: string = "";

  parentForm = this.fb.group({
    startingChequeNumber: ['', Validators.required],
    lastChequeNumber: [''],
    paymentDate: ['', Validators.required],
    broadcastMessage: [''],
    chequeNumberH: [''],
    chequeNumberV: [''],
    chequeDateH: [''],
    chequeDateV: [''],
    amountNumericH: [''],
    amountNumericV: [''],
    amountAlphabeticH: [''],
    amountAlphabeticV: [''],
    employeeNameH: [''],
    employeeNameV: [''],
    employeeAddressH: [''],
    employeeAddressV: [''],
    companyNameH: [''],
    companyNameV: [''],
    companyAddressH: [''],
    companyAddressV: [''],
    paperSize: [''],
    width: [''],
    height: [''],
    marginLeft: [''],
    marginRight: [''],
    chqStyle: ['', Validators.required],
    importFile: [''],
    payMethod: ['', Validators.required],
    chqSortOrder: [''],
    dateFormatPayStub: [''],
    dateFormatCheque: [''],
    groupByPaystub: [''],
    empNamePaystub: [''],
    comNamePaystub: [''],
    empNumPaystub: [''],
    comAddrPaystub: [''],
    empAddrPaystub: [''],
    deptPaystub: [''],
    empSINPaystub: [''],
    jobTitlePaystub: [''],
    empBankAccPaystub: [''],
    payGrpPaystub: [''],
    chqNumPaystub: [''],
    payPrdPaystub: [''],
    chqDatePaystub: [''],
    ytdAmntPaystub: [''],
    chqNumCheque: [''],
    comNameCheque: [''],
    letterAmnCheque: [''],
    comAddrCheque: [''],
    dollarSignCheque: [''],
    allbutton: ['']
  });


  paraForemail: number = null;
  noOfSelectedEmps = 0;
  sessionIdList: IEmailPayStub[] = [];
  emailList: IEmail[] = [];
  email_sub = '';
  email_body = '';
  ob_to_pass: IObjList = {
    sess_list: null,
    emp_email_list: null,
    subjct: null,
    body: null,
    left_marg: null,
    top_marg: null,
    flag: null,
    layout: null,
    ctmPaystubId: null,
    culture: null
  };
  email_modal_show = false;

  isAllPGs = true;
  payrollGroups: IPayrollGroupsWithPaymentDate[] = [{a: null, b: null, c: null, y: null, st: null, ed: null}];
  selectedGroups: IPayrollGroupsWithPaymentDate[] = [];
  tempArr: number[] = [];
  count = 0;
  form: NgForm;
  cur_wizad_payroll_group: any;
  wizadmode = false;
  token: string;
  paygrps_with_new_paymentdate: IUpdatePayrollGroupPaymentDate[] = [];


  private updatePrintQueueTableForEmailService: Subscription;
  updatePaystub: Subscription;
  chequePaystubParamService: Subscription;
  updatePrintQueueService: Subscription;
  updatePaymentDateService: Subscription;
  getPaymentDateService: Subscription;
  sendPaystubService: Subscription;
  getEmpService: Subscription;
  checkEmailSettingSetService: Subscription;
  GetFromEmailService: Subscription;

  report_list_original: ImportedReport[] = [];
  report_list: ImportedReport[] = [];
  input_visible = false;
  selected_report: number;

  culture: string = 'en-CA';
  userPref: IUserPreference;

  constructor(
    private _reportService: ReportServiceService,
    private fb: FormBuilder,
    private _sysPrefService: SystemPreferenceService,
    private _wizardService: WizardService,
    private _datePipe: DatePipe,
    private _chequePrintingService: ChequePrintingService,
    private _payrollService: PayrollGroupService,
    private _router: Router,
    private _route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private loading: LoadingSpinnerService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private _userService: UserSetupService,
  private envService: EnvService  ) {
  }

  ngOnInit() {
    this._userService.getUserPreference().pipe(untilDestroyed(this)).subscribe(data => {
      this._userService.getLanguages().subscribe(d => {
        this.userPref = data;
        if (this.userPref != null && this.userPref.l !== null) {
          this.culture = d.find(x => x.i == this.userPref.l).ci;
        }
      });
    });

    this._wizardService.wizardMode$.subscribe(value => this.wizadmode = value);

    this.paraForemail = this._route.snapshot.params.e == 1 ? 1 : null;
    this.authenticationService.currentUser.subscribe(x => this.token = x.token);

    this._sysPrefService.getinitSystemPreferences().subscribe(data => {
      if (data && data.length > 0) {
        this.sysPref = data[0];
      }

      this.GetFromEmailService = this._chequePrintingService.GetFromEmail().pipe(untilDestroyed(this)).subscribe(
        res => {
          const frm = res;
          this.viewerSendEmail = {enabled: true, from: frm, to: '', cc: '', subject: '', body: '', format: ''};
        }
      );

      this.chequePaystubParamService = this._chequePrintingService.getChequePaystubParamsByUserID().pipe(untilDestroyed(this)).subscribe(
        data => {
          this.chequePaystubParams = data;
          if (this.chequePaystubParams !== null || this.chequePaystubParams !== undefined) {

            this.parentForm.setValue({
              startingChequeNumber: this.chequePaystubParams.e + 1,
              lastChequeNumber: this.chequePaystubParams.e,
              paymentDate: this.payrollGroups.length > 0 ? this.fromJsonDate(this.payrollGroups[0].c) : null,
              broadcastMessage: this.chequePaystubParams.b,
              chequeNumberH: this.chequePaystubParams.al,
              chequeNumberV: this.chequePaystubParams.at,
              chequeDateH: this.chequePaystubParams.bl,
              chequeDateV: this.chequePaystubParams.bt,
              amountNumericH: this.chequePaystubParams.cl,
              amountNumericV: this.chequePaystubParams.ct,
              amountAlphabeticH: this.chequePaystubParams.dl,
              amountAlphabeticV: this.chequePaystubParams.dt,
              employeeNameH: this.chequePaystubParams.el,
              employeeNameV: this.chequePaystubParams.et,
              employeeAddressH: this.chequePaystubParams.fl,
              employeeAddressV: this.chequePaystubParams.ft,
              companyNameH: this.chequePaystubParams.gl,
              companyNameV: this.chequePaystubParams.gt,
              companyAddressH: this.chequePaystubParams.hl,
              companyAddressV: this.chequePaystubParams.ht,
              paperSize: 1,
              width: 8,
              height: 11,
              marginLeft: this.chequePaystubParams.ml,
              marginRight: this.chequePaystubParams.mt,
              chqStyle: this.chequePaystubParams.c,
              payMethod: this.chequePaystubParams.h,
              chqSortOrder: this.chequePaystubParams.f,
              dateFormatPayStub: this.chequePaystubParams.p13,
              dateFormatCheque: this.chequePaystubParams.c6,
              groupByPaystub: this.getCheckedValue(this.chequePaystubParams.g),
              empNamePaystub: this.getCheckedValue(this.chequePaystubParams.p1),
              comNamePaystub: this.getCheckedValue(this.chequePaystubParams.p6),
              empNumPaystub: this.getCheckedValue(this.chequePaystubParams.p3),
              comAddrPaystub: this.getCheckedValue(this.chequePaystubParams.p7),
              empAddrPaystub: this.getCheckedValue(this.chequePaystubParams.p2),
              deptPaystub: this.getCheckedValue(this.chequePaystubParams.p14),
              empSINPaystub: this.getCheckedValue(this.chequePaystubParams.p4),
              jobTitlePaystub: this.getCheckedValue(this.chequePaystubParams.p15),
              empBankAccPaystub: this.getCheckedValue(this.chequePaystubParams.p5),
              payGrpPaystub: this.getCheckedValue(this.chequePaystubParams.p11),
              chqNumPaystub: this.getCheckedValue(this.chequePaystubParams.p8),
              payPrdPaystub: this.getCheckedValue(this.chequePaystubParams.p10),
              chqDatePaystub: this.getCheckedValue(this.chequePaystubParams.p9),
              ytdAmntPaystub: this.getCheckedValue(this.chequePaystubParams.p12),
              chqNumCheque: this.getCheckedValue(this.chequePaystubParams.c1),
              comNameCheque: this.getCheckedValue(this.chequePaystubParams.c3),
              letterAmnCheque: this.getCheckedValue(this.chequePaystubParams.c2),
              comAddrCheque: this.getCheckedValue(this.chequePaystubParams.c4),
              dollarSignCheque: this.getCheckedValue(this.chequePaystubParams.c5),
              allbutton: false,
              importFile: this.chequePaystubParams.c == 8 ? this.chequePaystubParams.d : null
            });
          }
        });

      this.getPaymentDateService = this._payrollService.GetPayrollGroupsWithPaymentDateForPaystub().pipe(untilDestroyed(this)).subscribe(
        res => {
          if (res) {
            this.payrollGroups = res;
            if (this.payrollGroups.length > 0) {
              this.parentForm.patchValue({
                paymentDate: this.fromJsonDate(this.payrollGroups[0].c)
              });
            }
            this.loadEmployees();
          }
        });

    });
    this._reportService.GetImportedReportList().pipe(untilDestroyed(this)).subscribe(res => {
      this.report_list_original = res;
      this.report_list_original = this.report_list_original.filter(x => x.REPORT_TYPE == 1);
      this.report_list = this.report_list_original.map(x => {
        return x = {ID: x.ID, NAME: x.NAME.split('.')[0], REPORT_TYPE: x.REPORT_TYPE};
      });
      this.report_list = this.report_list.filter(x => !x.NAME.endsWith('_'));
    });
  }

  loadEmployees() {
    this.employeeList.length = 0;
    this.employeeListCopy.length = 0;
    this.unprocessed_emp_list = '';
    this.empListSub = this._wizardService.wizardMode$.pipe(
      take(1),
      concatMap(val => {
        if (val) {
          this.isAllPGs = false;
          this.wizadmode = true;
          return this._wizardService.currentGroupInfo$.pipe(take(1), untilDestroyed(this));
        } else {
          return of(null);
        }
      }),
      concatMap(val => {
        this.wizardGroupInfo = val;
        if (this.wizardGroupInfo !== null) {
          if (this.wizadmode && this.payrollGroups !== null) {
            this.selectedGroups = this.payrollGroups.filter(x => (x.a == val.gc));
            this.parentForm.patchValue({
              paymentDate: this.fromJsonDate(this.selectedGroups[0].c)
            });
          }
        }
        return this._chequePrintingService.getEmployeeList().pipe(take(1));
      })
    ).subscribe(data => {
      if (data) {
        this.unprocessed_emp_list = '';
        this.employeeList = data;
        // console.log('employeeList==>>');
        // console.log(this.employeeList);
        if (this.wizardGroupInfo) {
          this.employeeList = this.employeeList.filter(x => {
            return x.fd == this.wizardGroupInfo.fd && x.td == this.wizardGroupInfo.td && x.g == this.wizardGroupInfo.gc;
          });
        }

        for (let i = 0; i < this.employeeList.length; i++) {
          this.employeeList[i].flg = 0;
          this.employeeList[i].pf = this.fromJsonDate(this.employeeList[i].fd) + ' - ' + this.fromJsonDate(this.employeeList[i].td);
          if (this.employeeList[i].ex == 1) {
            this.employeeList[i].sp = 'Special';// no need to translate - remove field - we do not have special payroll anymore
          } else {
            this.employeeList[i].sp = 'Regular';// no need to translate - remove field - we do not have special payroll anymore
          }
          if (this.employeeList[i].ps.toLowerCase() != 'c') {
            this.unprocessed_emp_list += this.employeeList[i].i + ' - ' + this.employeeList[i].n + '|';
          }
          this.employeeListCopy.push(this.employeeList[i]);
        }
        this.valueChange(null);
        if (this.unprocessed_emp_list != '') {
          let warningMarkUp = '';
          this.unprocessed_emp_list.split('|').forEach(x => {
            warningMarkUp += '<span class="msg-cell-gl">' + x + '</span>';
          });
          const title = this.transloco.translate('error');
          const buttonOk = this.transloco.translate('ok');
          const message = this.transloco.translate('alerts.pleaseDeleteOrReprocess', {}, 'chequePrinting');
          showMsg({
            type: 'sticky',
            status: 'error',
            title: title,
            buttons: {
              [buttonOk]: {
                action() {
                  return;
                }
              }
            },
            message: message + warningMarkUp,
            delay: '2000'
          });
        }

      } else {
        this.employeeList = [{
          i: null,
          n: null,
          j: null,
          am: null,
          pd: null,
          fd: null,
          td: null,
          ex: null,
          q: null,
          y: null,
          g: null,
          s: null,
          flg: null,
          c: null,
          d: null,
          pf: null,
          sp: null,
          em: null,
          pw: null,
          ma: null,
          pma: null,
          ch: null,
          pe: null,
          pp: null,
          pdf: null,
          ps: null,
          Code: null
        }];
      }
    });
  }

  loadEmployeesAfterUpdates(): void {
    this.employeeList.length = 0;
    this.employeeListCopy.length = 0;
    this.unprocessed_emp_list = '';
    this.getEmpService = this._chequePrintingService.getEmployeeList().pipe(untilDestroyed(this)).subscribe(res => {
      if (res) {
        this.employeeList = res;
        for (let i = 0; i < this.employeeList.length; i++) {
          this.employeeList[i].flg = 0;
          this.employeeList[i].pf = this.fromJsonDate(this.employeeList[i].fd) + ' - ' + this.fromJsonDate(this.employeeList[i].td);
          this.employeeListCopy.push(this.employeeList[i]);
          if (this.employeeList[i].ps.toLowerCase() != 'c') {
            this.unprocessed_emp_list += this.employeeList[i].i + ' - ' + this.employeeList[i].n + '|';
          }
        }
        if (this.unprocessed_emp_list != '') {
          let warningMarkUp = '';
          this.unprocessed_emp_list.split('|').forEach(x => {
            warningMarkUp += '<span class="msg-cell-gl">' + x + '</span>';
          });
          const title = this.transloco.translate('error');
          const buttonOk = this.transloco.translate('ok');
          const message = this.transloco.translate('alerts.pleaseDeleteOrReprocess', {}, 'chequePrinting');
          showMsg({
            type: 'sticky',
            status: 'error',
            title: title,
            buttons: {
              [buttonOk]: {
                action() {
                  return;
                }
              }
            },
            message: message + warningMarkUp,
            delay: '2000'
          });
        }
      }
    });
  }

  onFiltered(filval: number): void {
    if (filval == 1) {
      this.employeeList = this.employeeListCopy.filter(x => (x.ma != null && x.ma != ''));
    } else {
      this.employeeList.length = 0;
      for (const item of this.employeeListCopy) {
        this.employeeList.push(item);
      }
    }
  }

  onSubmit() {
    const formValue = this.parentForm.value;
    if (formValue) {
      this.chequePaystubParamsUpdate.a = this.getStringValue(null);
      this.chequePaystubParamsUpdate.b = this.getStringValue(formValue.broadcastMessage);
      this.chequePaystubParamsUpdate.c = formValue.chqStyle;
      this.chequePaystubParamsUpdate.h = formValue.payMethod;
      this.chequePaystubParamsUpdate.d = formValue.chqStyle == 8 ? formValue.importFile : null;
      this.chequePaystubParamsUpdate.e = formValue.lastChequeNumber;
      this.chequePaystubParamsUpdate.f = formValue.chqSortOrder;
      this.chequePaystubParamsUpdate.g = this.getFieldValue(formValue.groupByPaystub);
      this.chequePaystubParamsUpdate.p1 = this.getFieldValue(formValue.empNamePaystub);
      this.chequePaystubParamsUpdate.p2 = this.getFieldValue(formValue.empAddrPaystub);
      this.chequePaystubParamsUpdate.p3 = this.getFieldValue(formValue.empNumPaystub);
      this.chequePaystubParamsUpdate.p4 = this.getFieldValue(formValue.empSINPaystub);
      this.chequePaystubParamsUpdate.p5 = this.getFieldValue(formValue.empBankAccPaystub);
      this.chequePaystubParamsUpdate.p6 = this.getFieldValue(formValue.comNamePaystub);
      this.chequePaystubParamsUpdate.p7 = this.getFieldValue(formValue.comAddrPaystub);
      this.chequePaystubParamsUpdate.p8 = this.getFieldValue(formValue.chqNumPaystub);
      this.chequePaystubParamsUpdate.p9 = this.getFieldValue(formValue.chqDatePaystub);
      this.chequePaystubParamsUpdate.p10 = this.getFieldValue(formValue.payPrdPaystub);
      this.chequePaystubParamsUpdate.p11 = this.getFieldValue(formValue.payGrpPaystub);
      this.chequePaystubParamsUpdate.p12 = this.getFieldValue(formValue.ytdAmntPaystub);
      this.chequePaystubParamsUpdate.p13 = this.getStringValue(formValue.dateFormatPayStub);
      this.chequePaystubParamsUpdate.p14 = this.getFieldValue(formValue.deptPaystub);
      this.chequePaystubParamsUpdate.p15 = this.getFieldValue(formValue.jobTitlePaystub);
      this.chequePaystubParamsUpdate.c1 = this.getFieldValue(formValue.chqNumCheque);
      this.chequePaystubParamsUpdate.c2 = this.getFieldValue(formValue.letterAmnCheque);
      this.chequePaystubParamsUpdate.c3 = this.getFieldValue(formValue.comNameCheque);
      this.chequePaystubParamsUpdate.c4 = this.getFieldValue(formValue.comAddrCheque);
      this.chequePaystubParamsUpdate.c5 = this.getFieldValue(formValue.dollarSignCheque);
      this.chequePaystubParamsUpdate.c6 = this.getStringValue(formValue.dateFormatCheque);
      this.chequePaystubParamsUpdate.al = formValue.chequeNumberH;
      this.chequePaystubParamsUpdate.at = formValue.chequeNumberV;
      this.chequePaystubParamsUpdate.bl = formValue.chequeDateH;
      this.chequePaystubParamsUpdate.bt = formValue.chequeDateV;
      this.chequePaystubParamsUpdate.cl = formValue.amountNumericH;
      this.chequePaystubParamsUpdate.ct = formValue.amountNumericV;
      this.chequePaystubParamsUpdate.dl = formValue.amountAlphabeticH;
      this.chequePaystubParamsUpdate.dt = formValue.amountAlphabeticV;
      this.chequePaystubParamsUpdate.el = formValue.employeeNameH;
      this.chequePaystubParamsUpdate.et = formValue.employeeNameV;
      this.chequePaystubParamsUpdate.fl = formValue.employeeAddressH;
      this.chequePaystubParamsUpdate.ft = formValue.employeeAddressV;
      this.chequePaystubParamsUpdate.gl = formValue.companyNameH;
      this.chequePaystubParamsUpdate.gt = formValue.companyNameV;
      this.chequePaystubParamsUpdate.hl = formValue.companyAddressH;
      this.chequePaystubParamsUpdate.ht = formValue.companyAddressV;
      this.chequePaystubParamsUpdate.ml = formValue.marginLeft;
      this.chequePaystubParamsUpdate.mt = formValue.marginRight;
    }
    this.updatePaystub = this._chequePrintingService.updateChequePaystubParamsByUserID(this.chequePaystubParamsUpdate).subscribe(
      res => {
        this.parentForm.patchValue({allbutton: false});
        // showMsg({
        //  'type': 'nonsticky',
        //  'status': 'success',
        //  'title': 'Harmony Payroll System',
        //  'buttons': {},
        //  'message': 'Successfully updated.',
        //  'delay': '1000'
        // });
        this.wizadmode ? this.loadEmployees() : this.loadEmployeesAfterUpdates();
      },
      err => {
        const title = this.transloco.translate('error');
        const message = this.transloco.translate('alerts.errorOccurredInFunctionUpdateChequePaystubParamsByUserID', {}, 'chequePrinting');
        showMsg({
          type: 'nonsticky',
          status: 'error',
          title: title,
          buttons: {},
          message: message,
          delay: '1000'
        });
      }
    );
    this.chequePaystubParamService = this._chequePrintingService.getChequePaystubParamsByUserID().pipe(untilDestroyed(this)).subscribe(
      data => {
        this.chequePaystubParams = data;
        if (this.chequePaystubParams !== null || this.chequePaystubParams !== undefined) {

          this.parentForm.patchValue({
            startingChequeNumber: this.chequePaystubParams.e + 1,
            lastChequeNumber: this.chequePaystubParams.e
          });
        }
      });
  }

  onClosePreview() {
    this.chequePaystubParamService = this._chequePrintingService.getChequePaystubParamsByUserID().subscribe(
      data => {
        this.chequePaystubParams = data;

        if (this.chequePaystubParams !== null || this.chequePaystubParams !== undefined) {

          this.parentForm.setValue({
            startingChequeNumber: this.chequePaystubParams.e + 1,
            lastChequeNumber: this.chequePaystubParams.e,
            paymentDate: this.isAllPGs ? this.fromJsonDate(this.payrollGroups[0].c) : this.fromJsonDate(this.selectedGroups[0].c),
            broadcastMessage: this.chequePaystubParams.b,
            chequeNumberH: this.chequePaystubParams.al,
            chequeNumberV: this.chequePaystubParams.at,
            chequeDateH: this.chequePaystubParams.bl,
            chequeDateV: this.chequePaystubParams.bt,
            amountNumericH: this.chequePaystubParams.cl,
            amountNumericV: this.chequePaystubParams.ct,
            amountAlphabeticH: this.chequePaystubParams.dl,
            amountAlphabeticV: this.chequePaystubParams.dt,
            employeeNameH: this.chequePaystubParams.el,
            employeeNameV: this.chequePaystubParams.et,
            employeeAddressH: this.chequePaystubParams.fl,
            employeeAddressV: this.chequePaystubParams.ft,
            companyNameH: this.chequePaystubParams.gl,
            companyNameV: this.chequePaystubParams.gt,
            companyAddressH: this.chequePaystubParams.hl,
            companyAddressV: this.chequePaystubParams.ht,
            paperSize: 1,
            width: 8,
            height: 11,
            marginLeft: this.chequePaystubParams.ml,
            marginRight: this.chequePaystubParams.mt,
            chqStyle: this.chequePaystubParams.c,
            payMethod: this.chequePaystubParams.h,
            chqSortOrder: this.chequePaystubParams.f,
            dateFormatPayStub: this.chequePaystubParams.p13,
            dateFormatCheque: this.chequePaystubParams.c6,
            groupByPaystub: this.getCheckedValue(this.chequePaystubParams.g),
            empNamePaystub: this.getCheckedValue(this.chequePaystubParams.p1),
            comNamePaystub: this.getCheckedValue(this.chequePaystubParams.p6),
            empNumPaystub: this.getCheckedValue(this.chequePaystubParams.p3),
            comAddrPaystub: this.getCheckedValue(this.chequePaystubParams.p7),
            empAddrPaystub: this.getCheckedValue(this.chequePaystubParams.p2),
            deptPaystub: this.getCheckedValue(this.chequePaystubParams.p14),
            empSINPaystub: this.getCheckedValue(this.chequePaystubParams.p4),
            jobTitlePaystub: this.getCheckedValue(this.chequePaystubParams.p15),
            empBankAccPaystub: this.getCheckedValue(this.chequePaystubParams.p5),
            payGrpPaystub: this.getCheckedValue(this.chequePaystubParams.p11),
            chqNumPaystub: this.getCheckedValue(this.chequePaystubParams.p8),
            payPrdPaystub: this.getCheckedValue(this.chequePaystubParams.p10),
            chqDatePaystub: this.getCheckedValue(this.chequePaystubParams.p9),
            ytdAmntPaystub: this.getCheckedValue(this.chequePaystubParams.p12),
            chqNumCheque: this.getCheckedValue(this.chequePaystubParams.c1),
            comNameCheque: this.getCheckedValue(this.chequePaystubParams.c3),
            letterAmnCheque: this.getCheckedValue(this.chequePaystubParams.c2),
            comAddrCheque: this.getCheckedValue(this.chequePaystubParams.c4),
            dollarSignCheque: this.getCheckedValue(this.chequePaystubParams.c5),
            allbutton: false,
            importFile: this.chequePaystubParams.c == 8 ? this.chequePaystubParams.d : null
          });
        }
      });

    for (let i = 0; i < this.employeeList.length; i++) {
      this.employeeList[i].flg = 0;
    }

    // this.loadEmployees();
  }

  get startingChequeNumber() {
    return this.parentForm.get('startingChequeNumber');
  }

  get lastChequeNumber() {
    return this.parentForm.get('lastChequeNumber');
  }

  get paymentDate() {
    return this.parentForm.get('paymentDate');
  }

  get broadcastMessage() {
    return this.broadcastMessage.get('broadcastMessage');
  }

  getEmployeeChequeList(): any {
    if (this.employeeList) {
      return this.employeeList;
    } else {
      return null;
    }
  }

  get employeeSelectedStatus(): boolean {
    return this.employeeList?.some(value => value.flg === 1);
  }


  fromJsonDate(jDate): string {
    if (jDate) {
      const bDate: Date = new Date(jDate.substring(0, 10));
      return bDate.toISOString().substring(0, 10);  // Ignore time
    }
    return null;
  }

  fromJsonDateToString(jDate: any): string {
    const dateStr = jDate.substring(0, 10);
    return dateStr;
  }

  getCheckedValue(value): boolean {
    if (value === 1) {
      return true;
    } else {
      return false;
    }
  }

  getFieldValue(value): number {
    if (value === true) {
      return 1;
    } else {
      return 0;
    }
  }

  getStringValue(value): string {
    if (value === null) {
      return '';
    } else {
      return value;
    }
  }

  onPreviewButtonClicked(): void {
    this.count = 0;
    this.emailList.length = 0;
    const formValue = this.parentForm.value;
    if (formValue) {
      this.chequePaystubParamsUpdate = this.getFormValues(formValue);
    }
    if (this.chequePaystubParamsUpdate && this.chequePaystubParamsUpdate.c == 8 && this.chequePaystubParamsUpdate.d) {
      this.onPreviewCustomReport(formValue.importFile);
    } else {
      this.updatePaystub = this._chequePrintingService.updateChequePaystubParamsByUserID(this.chequePaystubParamsUpdate).subscribe(
        res => {
          //let k = this.startingChequeNumber.value;

          const temp = this.employeeList.filter(x => x.flg == 1);
          for (let i = 0; i < temp.length; i++) {
            //temp[i].c = k;
            temp[i].d = new Date(this.paymentDate.value);
            temp[i].pp = 1;
            //k++;
          }
          this.updatePrintQueueService = this._chequePrintingService.updatePrintQueueTable(temp, this.chequePaystubParamsUpdate.f, this.startingChequeNumber.value).subscribe(
            res => {
              this.parameter = ' and Q.[PROC_SESSION_ID] = \'' + res + '\' and Q.[REPORT_TYPE] = 1 '; // " and Q.[ID_USER] = dbo.getContextUserID() and Q.[PROC_SESSION_ID] = \'" + res + "\' and Q.[REPORT_TYPE] = 1 ";
              this.rprtSrce = {
                report: 'ChequePaystub.trdp',
                parameters: {
                  param: this.parameter,
                  leftMargine: '' + (0.25 + this.chequePaystubParamsUpdate.ml) + 'in',
                  topMargine: '' + (0.25 + this.chequePaystubParamsUpdate.mt) + 'in',
                  culture: this.culture,
                  token: this.token
                }
              };
              const r: JSON = JSON.parse(JSON.stringify(this.rprtSrce));
              this.viewer.setReportSource(r);
              console.log(r);
              this.updatePrintQueueTableForEmailService = this._chequePrintingService.updatePrintQueueTableForEmail(temp, this.chequePaystubParamsUpdate.f, this.startingChequeNumber.value).subscribe(
                res => {
                  this.sessionIdList = Array.from(new Set(res.map(x => x.emp_id))).map(y => {
                    return res.find(x => x.emp_id === y);
                  });
                  let emLst: IEmail = {
                    w_email_ad: null,
                    p_email_ad: null,
                    emp_id: null,
                    paystub_by_email: null,
                    passwrd: null,
                    pg_code: null,
                    frm_date: null,
                    to_date: null,
                    pd: null,
                    q: null,
                    empNumber: null,
                    procYear: null
                  };
                  if (this.sessionIdList !== null && this.sessionIdList.length > 0) {
                    const templength = this.sessionIdList.length;
                    for (const item of this.sessionIdList) {
                      const indx = temp.findIndex(x => x.j == item.emp_id);
                      emLst = {
                        w_email_ad: temp[indx].ma,
                        p_email_ad: temp[indx].pma,
                        emp_id: temp[indx].j,
                        paystub_by_email: temp[indx].em ? 1 : 0,
                        passwrd: temp[indx].pw,
                        pg_code: temp[indx].g,
                        frm_date: temp[indx].fd,
                        to_date: temp[indx].td,
                        pd: temp[indx].pd,
                        q: temp[indx].q,
                        empNumber: temp[indx].i,
                        procYear: temp[indx].y
                      };
                      this.emailList.push(emLst);
                      this.count++;
                    }
                    if (templength == this.count) {
                      this.sendEmailOnModalClick(this.form, 2);
                    }
                  }
                }
              );
            },
            err => {
              const title = this.transloco.translate('error');
              const message = this.transloco.translate('alerts.errorOccurredInFunctionUpdatePrintQueueTable', {}, 'chequePrinting');
              showMsg({
                type: 'nonsticky',
                status: 'error',
                title: title,
                buttons: {},
                message: message,
                delay: '1000'
              });
            }
          );

          // update payment date of payroll groups
          this.paygrps_with_new_paymentdate = this.getPayrollGroupListToUpdatePaymentDate();
          if (this.paygrps_with_new_paymentdate) {
            this.updatePaymentDateService = this._chequePrintingService.UpdatePayrollGroupPaymentDate(this.paygrps_with_new_paymentdate).subscribe(res => {
              this.getPaymentDateService = this._payrollService.GetPayrollGroupsWithPaymentDateForPaystub().subscribe(
                res => {
                  this.payrollGroups = res;
                  if (!this.isAllPGs && this.selectedGroups.length > 0) {
                    this.selectedGroups.forEach(x => x.c = this.paymentDate.value);
                  }
                  this.parentForm.patchValue({
                    paymentdate: this.isAllPGs ? this.fromJsonDate(this.payrollGroups[0].c) : this.fromJsonDate(this.selectedGroups[0].c)
                  });
                  this.wizadmode ? this.loadEmployees() : this.loadEmployeesAfterUpdates();
                });
            });
          }

        },
        err => {
          const title = this.transloco.translate('error');
          const message = this.transloco.translate('alerts.errorOccurredInFunctionUpdateChequePaystubParamsByUserID', {}, 'chequePrinting');
          showMsg({
            type: 'nonsticky',
            status: 'error',
            title: title,
            buttons: {},
            message: message,
            delay: '1000'
          });

        }
      );
    }
    this.opened = true;
  }

  public close() {
    this.opened = false;
    const formValue = this.parentForm.value;
    if (formValue) {
      this.chequePaystubParamsUpdate.a = this.getStringValue(null);
      this.chequePaystubParamsUpdate.b = this.getStringValue(formValue.broadcastMessage);
      this.chequePaystubParamsUpdate.c = formValue.chqStyle;
      this.chequePaystubParamsUpdate.h = formValue.payMethod;
      this.chequePaystubParamsUpdate.d = formValue.chqStyle == 8 ? formValue.importFile : null;
      this.chequePaystubParamsUpdate.e = formValue.lastChequeNumber;
      this.chequePaystubParamsUpdate.f = formValue.chqSortOrder;
      this.chequePaystubParamsUpdate.g = this.getFieldValue(formValue.groupByPaystub);
      this.chequePaystubParamsUpdate.p1 = this.getFieldValue(formValue.empNamePaystub);
      this.chequePaystubParamsUpdate.p2 = this.getFieldValue(formValue.empAddrPaystub);
      this.chequePaystubParamsUpdate.p3 = this.getFieldValue(formValue.empNumPaystub);
      this.chequePaystubParamsUpdate.p4 = this.getFieldValue(formValue.empSINPaystub);
      this.chequePaystubParamsUpdate.p5 = this.getFieldValue(formValue.empBankAccPaystub);
      this.chequePaystubParamsUpdate.p6 = this.getFieldValue(formValue.comNamePaystub);
      this.chequePaystubParamsUpdate.p7 = this.getFieldValue(formValue.comAddrPaystub);
      this.chequePaystubParamsUpdate.p8 = this.getFieldValue(formValue.chqNumPaystub);
      this.chequePaystubParamsUpdate.p9 = this.getFieldValue(formValue.chqDatePaystub);
      this.chequePaystubParamsUpdate.p10 = this.getFieldValue(formValue.payPrdPaystub);
      this.chequePaystubParamsUpdate.p11 = this.getFieldValue(formValue.payGrpPaystub);
      this.chequePaystubParamsUpdate.p12 = this.getFieldValue(formValue.ytdAmntPaystub);
      this.chequePaystubParamsUpdate.p13 = this.getStringValue(formValue.dateFormatPayStub);
      this.chequePaystubParamsUpdate.p14 = this.getFieldValue(formValue.deptPaystub);
      this.chequePaystubParamsUpdate.p15 = this.getFieldValue(formValue.jobTitlePaystub);
      this.chequePaystubParamsUpdate.c1 = this.getFieldValue(formValue.chqNumCheque);
      this.chequePaystubParamsUpdate.c2 = this.getFieldValue(formValue.letterAmnCheque);
      this.chequePaystubParamsUpdate.c3 = this.getFieldValue(formValue.comNameCheque);
      this.chequePaystubParamsUpdate.c4 = this.getFieldValue(formValue.comAddrCheque);
      this.chequePaystubParamsUpdate.c5 = this.getFieldValue(formValue.dollarSignCheque);
      this.chequePaystubParamsUpdate.c6 = this.getStringValue(formValue.dateFormatCheque);
      this.chequePaystubParamsUpdate.al = formValue.chequeNumberH;
      this.chequePaystubParamsUpdate.at = formValue.chequeNumberV;
      this.chequePaystubParamsUpdate.bl = formValue.chequeDateH;
      this.chequePaystubParamsUpdate.bt = formValue.chequeDateV;
      this.chequePaystubParamsUpdate.cl = formValue.amountNumericH;
      this.chequePaystubParamsUpdate.ct = formValue.amountNumericV;
      this.chequePaystubParamsUpdate.dl = formValue.amountAlphabeticH;
      this.chequePaystubParamsUpdate.dt = formValue.amountAlphabeticV;
      this.chequePaystubParamsUpdate.el = formValue.employeeNameH;
      this.chequePaystubParamsUpdate.et = formValue.employeeNameV;
      this.chequePaystubParamsUpdate.fl = formValue.employeeAddressH;
      this.chequePaystubParamsUpdate.ft = formValue.employeeAddressV;
      this.chequePaystubParamsUpdate.gl = formValue.companyNameH;
      this.chequePaystubParamsUpdate.gt = formValue.companyNameV;
      this.chequePaystubParamsUpdate.hl = formValue.companyAddressH;
      this.chequePaystubParamsUpdate.ht = formValue.companyAddressV;
      this.chequePaystubParamsUpdate.ml = formValue.marginLeft;
      this.chequePaystubParamsUpdate.mt = formValue.marginRight;
    }
    this.updatePaystub = this._chequePrintingService.updateChequePaystubParamsByUserID(this.chequePaystubParamsUpdate).subscribe(
      res => {
        this.parentForm.patchValue({ allbutton: false });
        // showMsg({
        //  'type': 'nonsticky',
        //  'status': 'success',
        //  'title': 'Harmony Payroll System',
        //  'buttons': {},
        //  'message': 'Successfully updated.',
        //  'delay': '1000'
        // });
        this.wizadmode ? this.loadEmployees() : this.loadEmployeesAfterUpdates();
      },
      err => {
        const title = this.transloco.translate('error');
        const message = this.transloco.translate('alerts.errorOccurredInFunctionUpdateChequePaystubParamsByUserID', {}, 'chequePrinting');
        showMsg({
          type: 'nonsticky',
          status: 'error',
          title: title,
          buttons: {},
          message: message,
          delay: '1000'
        });
      }
    );
  }

  onPreviewCustomReport(filename: any): void {
    this.updatePaystub = this._chequePrintingService.updateChequePaystubParamsByUserID(this.chequePaystubParamsUpdate).subscribe(
      res => {
        //let k = this.startingChequeNumber.value;

        const temp = this.employeeList.filter(x => x.flg == 1);
        for (let i = 0; i < temp.length; i++) {
          //temp[i].c = k;
          temp[i].d = new Date(this.paymentDate.value);
          temp[i].pp = 1;
          // k++;
        }
        this.updatePrintQueueService = this._chequePrintingService.updatePrintQueueTable(temp, this.chequePaystubParamsUpdate.f, this.startingChequeNumber.value).subscribe(
          res => {
            this.parameter = res;
            this.custom_rprtSrce = {report: filename, parameters: {param: this.parameter, culture: this.culture, token: this.token}};
            const r: JSON = JSON.parse(JSON.stringify(this.custom_rprtSrce));
            this.viewer.setReportSource(r);
            console.log(r);
            this.updatePrintQueueTableForEmailService = this._chequePrintingService.updatePrintQueueTableForEmail(temp, this.chequePaystubParamsUpdate.f, this.startingChequeNumber.value).subscribe(
              res => {
                this.sessionIdList = Array.from(new Set(res.map(x => x.emp_id))).map(y => {
                  return res.find(x => x.emp_id === y);
                });
                let emLst: IEmail = {
                  w_email_ad: null,
                  p_email_ad: null,
                  emp_id: null,
                  paystub_by_email: null,
                  passwrd: null,
                  pg_code: null,
                  frm_date: null,
                  to_date: null,
                  pd: null,
                  q: null,
                  empNumber: null,
                  procYear: null
                };
                if (this.sessionIdList !== null && this.sessionIdList.length > 0) {
                  let templength = this.sessionIdList.length;
                  for (let item of this.sessionIdList) {
                    let indx = temp.findIndex(x => x.j == item.emp_id);
                    emLst = {
                      w_email_ad: temp[indx].ma,
                      p_email_ad: temp[indx].pma,
                      emp_id: temp[indx].j,
                      paystub_by_email: temp[indx].em ? 1 : 0,
                      passwrd: temp[indx].pw,
                      pg_code: temp[indx].g,
                      frm_date: temp[indx].fd,
                      to_date: temp[indx].td,
                      pd: temp[indx].pd,
                      q: temp[indx].q,
                      empNumber: temp[indx].i,
                      procYear: temp[indx].y
                    };
                    this.emailList.push(emLst);
                    this.count++;
                  }
                  if (templength == this.count) {
                    this.sendEmailOnModalClick(this.form, 2);
                  }
                }
              }
            );
          },
          err => {
            const title = this.transloco.translate('error');
            const message = this.transloco.translate('alerts.errorOccurredInFunctionUpdatePrintQueueTable', {}, 'chequePrinting');
            showMsg({
              type: 'nonsticky',
              status: 'err',
              title: title,
              buttons: {},
              message: message,
              delay: '1000'
            });
          }
        );

        // update payment date of payroll groups
        this.paygrps_with_new_paymentdate = this.getPayrollGroupListToUpdatePaymentDate();
        if (this.paygrps_with_new_paymentdate) {
          this.updatePaymentDateService = this._chequePrintingService.UpdatePayrollGroupPaymentDate(this.paygrps_with_new_paymentdate).subscribe(res => {
            this.getPaymentDateService = this._payrollService.GetPayrollGroupsWithPaymentDateForPaystub().subscribe(
              res => {
                this.payrollGroups = res;
                if (!this.isAllPGs && this.selectedGroups.length > 0) {
                  this.selectedGroups.forEach(x => x.c = new Date(this.paymentDate.value));
                }
                this.parentForm.patchValue({
                  paymentdate: this.isAllPGs ? this.fromJsonDate(this.payrollGroups[0].c) : this.fromJsonDate(this.selectedGroups[0].c)
                });
                this.wizadmode ? this.loadEmployees() : this.loadEmployeesAfterUpdates();
              });
          });
        }
      },
      err => {
        const title = this.transloco.translate('error');
        const message = this.transloco.translate('alerts.errorOccurredInFunctionUpdateChequePaystubParamsByUserID', {}, 'chequePrinting');
        showMsg({
          type: 'nonsticky',
          status: 'error',
          title: title,
          buttons: {},
          message: message,
          delay: '1000'
        });

      }
    );
    this.opened = true;
  }

  getPayrollGroupListToUpdatePaymentDate(): IUpdatePayrollGroupPaymentDate[] {
    const list: IUpdatePayrollGroupPaymentDate[] = [];
    if (this.isAllPGs) {
      for (const item of this.payrollGroups) {
        list.push({
          PRLG_CODE: item.a,
          PFREQ_SCH_YEAR: item.y,
          PFREQ_SCH_ST_DATE: item.st,
          PFREQ_SCH_END_DATE: item.ed,
          PFREQ_SCH_CUTOFF_DATE: new Date(this.paymentDate.value)
        });
      }
    } else {
      if (this.selectedGroups.length > 0) {
        for (const item of this.selectedGroups) {
          list.push({
            PRLG_CODE: item.a,
            PFREQ_SCH_YEAR: item.y,
            PFREQ_SCH_ST_DATE: item.st,
            PFREQ_SCH_END_DATE: item.ed,
            PFREQ_SCH_CUTOFF_DATE: new Date(this.paymentDate.value)
          });
        }
      }
    }
    return list;
  }

  getFormValues(frm: any): IChequePaystubParams {
    const ob: IChequePaystubParams = {
      a: null,
      b: null,
      c: null,
      d: null,
      e: null,
      f: null,
      g: null,
      h: null,
      p1: null,
      p2: null,
      p3: null,
      p4: null,
      p5: null,
      p6: null,
      p7: null,
      p8: null,
      p9: null,
      p10: null,
      p11: null,
      p12: null,
      p13: null,
      p14: null,
      p15: null,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      c5: null,
      c6: null,
      al: null,
      at: null,
      bl: null,
      bt: null,
      cl: null,
      ct: null,
      dl: null,
      dt: null,
      el: null,
      et: null,
      fl: null,
      ft: null,
      gl: null,
      gt: null,
      hl: null,
      ht: null,
      ml: null,
      mt: null
    };
    if (frm) {
      ob.a = this.getStringValue(null);
      ob.b = this.getStringValue(frm.broadcastMessage);
      ob.c = frm.chqStyle;
      ob.d = frm.chqStyle == 8 ? frm.importFile : null;
      ob.h = frm.payMethod;
      ob.f = frm.chqSortOrder;
      ob.g = this.getFieldValue(frm.groupByPaystub);
      ob.p1 = this.getFieldValue(frm.empNamePaystub);
      ob.p2 = this.getFieldValue(frm.empAddrPaystub);
      ob.p3 = this.getFieldValue(frm.empNumPaystub);
      ob.p4 = this.getFieldValue(frm.empSINPaystub);
      ob.p5 = this.getFieldValue(frm.empBankAccPaystub);
      ob.p6 = this.getFieldValue(frm.comNamePaystub);
      ob.p7 = this.getFieldValue(frm.comAddrPaystub);
      ob.p8 = this.getFieldValue(frm.chqNumPaystub);
      ob.p9 = this.getFieldValue(frm.chqDatePaystub);
      ob.p10 = this.getFieldValue(frm.payPrdPaystub);
      ob.p11 = this.getFieldValue(frm.payGrpPaystub);
      ob.p12 = this.getFieldValue(frm.ytdAmntPaystub);
      ob.p13 = this.getStringValue(frm.dateFormatPayStub);
      ob.p14 = this.getFieldValue(frm.deptPaystub);
      ob.p15 = this.getFieldValue(frm.jobTitlePaystub);
      ob.c1 = this.getFieldValue(frm.chqNumCheque);
      ob.c2 = this.getFieldValue(frm.letterAmnCheque);
      ob.c3 = this.getFieldValue(frm.comNameCheque);
      ob.c4 = this.getFieldValue(frm.comAddrCheque);
      ob.c5 = this.getFieldValue(frm.dollarSignCheque);
      ob.c6 = this.getStringValue(frm.dateFormatCheque);
      ob.al = frm.chequeNumberH;
      ob.at = frm.chequeNumberV;
      ob.bl = frm.chequeDateH;
      ob.bt = frm.chequeDateV;
      ob.cl = frm.amountNumericH;
      ob.ct = frm.amountNumericV;
      ob.dl = frm.amountAlphabeticH;
      ob.dt = frm.amountAlphabeticV;
      ob.el = frm.employeeNameH;
      ob.et = frm.employeeNameV;
      ob.fl = frm.employeeAddressH;
      ob.ft = frm.employeeAddressV;
      ob.gl = frm.companyNameH;
      ob.gt = frm.companyNameV;
      ob.hl = frm.companyAddressH;
      ob.ht = frm.companyAddressV;
      ob.ml = frm.marginLeft;
      ob.mt = frm.marginRight;
    }

    return ob;
  }

  onEmailSendClicked(): void {
    this.checkEmailSettingSetService = this._chequePrintingService.checkEmailSettingSet().subscribe(
      res => {
        let temp = null;
        temp = res;
        if (temp == 0) {
          const title = this.transloco.translate('error');
          const buttonOk = this.transloco.translate('ok');
          const message = this.transloco.translate('alerts.bc.configureEmailSettings');
          showMsg({
            type: 'sticky',
            status: 'error',
            title: title,
            buttons: {
              [buttonOk]: {
                action() {
                  return;
                }
              }
            },
            message: message
          });
        } else if (temp == 1) {
          // $('#emailSendModal').modal({
          //  'toggle': 'true'
          // });
          $('#emailSendModal').modal('show');
          this.emailList.length = 0;
          let temp: IEmployeeChequePrinting[] = [];
          temp = this.employeeList.filter(x => x.flg == 1);
          temp !== null ? this.noOfSelectedEmps = temp.length : this.noOfSelectedEmps = 0;
          const formValue = this.parentForm.value;
          if (formValue) {
            this.chequePaystubParamsUpdate = this.getFormValues(formValue);
          }

          this.updatePaystub = this._chequePrintingService.updateChequePaystubParamsByUserID(this.chequePaystubParamsUpdate).subscribe(
            res => {
              //let k = this.startingChequeNumber.value;

              for (let i = 0; i < temp.length; i++) {
                //temp[i].c = k;
                temp[i].d = new Date(this.paymentDate.value);
                //k++;
              }

              this.updatePrintQueueTableForEmailService = this._chequePrintingService.updatePrintQueueTableForEmail(temp, this.chequePaystubParamsUpdate.f, this.startingChequeNumber.value).subscribe( //this.employeeList
                res => {
                  this.sessionIdList = Array.from(new Set(res.map(x => x.emp_id))).map(y => {
                    return res.find(x => x.emp_id === y);
                  });
                  let emLst: IEmail = {
                    w_email_ad: null,
                    p_email_ad: null,
                    emp_id: null,
                    paystub_by_email: null,
                    passwrd: null,
                    pg_code: null,
                    frm_date: null,
                    to_date: null,
                    pd: null,
                    q: null,
                    empNumber: null,
                    procYear: null
                  };
                  if (this.sessionIdList !== null && this.sessionIdList.length > 0) {
                    for (const item of this.sessionIdList) {
                      const indx = temp.findIndex(x => x.j == item.emp_id); // this.employeeList
                      emLst = {
                        w_email_ad: temp[indx].ma,
                        p_email_ad: temp[indx].pma,
                        emp_id: temp[indx].j,
                        paystub_by_email: temp[indx].em ? 1 : 0,
                        passwrd: temp[indx].pw,
                        pg_code: temp[indx].g,
                        frm_date: temp[indx].fd,
                        to_date: temp[indx].td,
                        pd: temp[indx].pd,
                        q: temp[indx].q,
                        empNumber: temp[indx].i,
                        procYear: temp[indx].y
                      };
                      this.emailList.push(emLst);
                    }
                  }
                },
                err => {
                  err => {
                    const title = this.transloco.translate('error');
                    const message = this.transloco.translate('errorOccured');
                    showMsg({
                      type: 'nonsticky',
                      status: 'error',
                      title: title,
                      buttons: {},
                      message: message,
                      delay: '1000'
                    });

                  };
                }
              );
            },
            err => {
              err => {
                const title = this.transloco.translate('error');
                const message = this.transloco.translate('errorOccured');
                showMsg({
                  type: 'nonsticky',
                  status: 'error',
                  title: title,
                  buttons: {},
                  message: message,
                  delay: '1000'
                });
              };
            }
          );
          this.email_modal_show = true;
        }
      },
      err => {
        const title = this.transloco.translate('error');
        const message = this.transloco.translate('errorOccured');
        showMsg({
          type: 'nonsticky',
          status: 'error',
          title: title,
          buttons: {},
          message: message,
          delay: '1000'
        });
      }
    );
  }

  sendEmailOnModalClick(frm: NgForm, flg: number): void {
    this.empWithoutSendEmailEnabled.length = 0;
    this.empWithoutPassword.length = 0;
    this.empWithoutEmailList.length = 0;
    this.ob_to_pass = {
      sess_list: this.sessionIdList,
      emp_email_list: this.emailList,
      subjct: this.email_sub,
      body: this.email_body,
      left_marg: (0.25 + this.chequePaystubParamsUpdate.ml),
      top_marg: (0.25 + this.chequePaystubParamsUpdate.mt),
      flag: flg,
      layout: this.chequePaystubParamsUpdate.c,
      ctmPaystubId: this.chequePaystubParamsUpdate.d,
      culture: this.culture
    };
    this.loading.startSpinner({spinnerName: 'emailSpinner', isFullScreen: true});
    this.sendPaystubService = this._chequePrintingService.sendPaystubToEmps(this.ob_to_pass).subscribe(
      res => {
        if (flg == 1) {
          if (res.emp_without_paystub_send_by_email_enabled.length > 0) {
            for (const item of res.emp_without_paystub_send_by_email_enabled) {
              const tempEmp = this.employeeList.find(x => x.j == item);
              this.empWithoutSendEmailEnabled.push(tempEmp);
            }
          }
          if (res.emp_without_password.length > 0) {
            for (const item of res.emp_without_password) {
              const tempEmp = this.employeeList.find(x => x.j == item);
              this.empWithoutPassword.push(tempEmp);
            }
          }
          if (res.emp_without_email.length > 0) {
            for (const item of res.emp_without_email) {
              const tempEmp = this.employeeList.find(x => x.j == item);
              this.empWithoutEmailList.push(tempEmp);
            }
          }
          if (res.emp_with_error.length > 0) {
            for (const item of res.emp_with_error) {
              const tempEmp = this.employeeList.find(x => x.j == item);
              this.empWithErrorList.push(tempEmp);
            }
          }
          this.wizadmode ? this.loadEmployees() : this.loadEmployeesAfterUpdates();
          this.loading.stopSpinner();
          if (this.empWithoutSendEmailEnabled.length > 0 || this.empWithoutPassword.length > 0 || this.empWithoutEmailList.length > 0 || this.empWithErrorList.length > 0) {
            const t = this;
            const title = this.transloco.translate('warning');
            const buttonDetail = this.transloco.translate('detail');
            const buttonCancel = this.transloco.translate('cancel');
            const message = this.transloco.translate('alerts.emailSendingsFailed', {}, 'chequePrinting');
            showMsg({
              type: 'sticky',
              status: 'warning',
              title: title,
              buttons: {
                [buttonDetail]: {
                  action() {
                    t.modalToggle();
                    return;
                  }
                },
                [buttonCancel]: {
                  action() {
                    return;
                  }
                }
              },
              message: message
            });
          } else {
            const title = this.transloco.translate('success');
            const message = this.transloco.translate('alerts.successfullySent', {}, 'chequePrinting');
            showMsg({
              type: 'nonsticky',
              status: 'success',
              title: title,
              buttons: {},
              message: message,
              delay: '1000'
            });
            // this.wizadmode ? this.loadEmployees() : this.loadEmployeesAfterUpdates();
            // this.loadEmployeesAfterUpdates();
          }
        } else {
          // this.wizadmode ? this.loadEmployees() : this.loadEmployeesAfterUpdates();
          //  this.loadEmployeesAfterUpdates();
        }
      },
      err => {
        console.log('error occurred');
        this.loading.stopSpinner();
      }
    );
    if (flg == 1) {
      frm.reset();
      this.email_modal_show = false;
    }

  }


  modalToggle(): void {
    $('#empListModal').modal({
      toggle: 'true'
    });
    $('#empListModal').modal('show');
  }

  onAllpayrollGrpSwitchClick(): void {
    if (this.isAllPGs == true) {
      this.employeeList.length = 0;
      this.selectedGroups = [];
      this.tempArr.length = 0;
      this.parentForm.patchValue({
        paymentDate: this.fromJsonDate(this.payrollGroups[0].c)
      });
      for (const item of this.employeeListCopy) {
        this.employeeList.push(item);
      }
    }
  }

  valueChange(event: any): void {
    this.tempArr.length = 0;
    this.employeeList.length = 0;
    if (this.selectedGroups.length > 0) {
      this.parentForm.patchValue({
        paymentDate: this.fromJsonDate(this.selectedGroups[0].c)
      });
      for (const item of this.selectedGroups) {
        const temp = this.employeeListCopy.filter(x => x.g == item.a);
        if (temp && temp.length > 0) {
          for (const e of temp) {
            this.employeeList.push(e);
          }
        }
      }
    } else {
      this.tempArr.length = 0;
      for (const item of this.employeeListCopy) {
        this.employeeList.push(item);
      }
    }
  }


  ngOnDestroy() {

    if (this.updatePrintQueueTableForEmailService) {
      this.updatePrintQueueTableForEmailService.unsubscribe();
    }
    if (this.empListSub) {
      this.empListSub.unsubscribe();
    }
    if (this.updatePaystub) {
      this.updatePaystub.unsubscribe();
    }
    if (this.chequePaystubParamService) {
      this.chequePaystubParamService.unsubscribe();
    }
    if (this.updatePrintQueueService) {
      this.updatePrintQueueService.unsubscribe();
    }
    if (this.updatePaymentDateService) {
      this.updatePaymentDateService.unsubscribe();
    }
    if (this.getPaymentDateService) {
      this.getPaymentDateService.unsubscribe();
    }
    if (this.sendPaystubService) {
      this.sendPaystubService.unsubscribe();
    }
    if (this.getEmpService) {
      this.getEmpService.unsubscribe();
    }
    if (this.checkEmailSettingSetService) {
      this.checkEmailSettingSetService.unsubscribe();
    }
    if (this.GetFromEmailService) {
      this.GetFromEmailService.unsubscribe();
    }
  }

}
