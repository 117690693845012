<ng-container *transloco="let translate; read: 'footer'">
  <footer class="footer">
    <div class="container-fluid">
      <div class="row justify-content-between">
        <div class="col-sm-4 d-flex align-items-end">
          <p class="mb-1">v{{version}} / © {{year}}, Paymate Software.</p>
        </div>
        <div class="col-sm-4 d-flex align-items-end">
          <p class="mb-1">
            <span class="text-warning" tooltip="New tax tables are available, it is recommended to upgrade." container="body" *ngIf="newTaxTable">
              <i class="fas fa-exclamation-circle"></i>&nbsp;
            </span>
            <span>{{translate('taxVersion')}}&nbsp;{{taxVersion}}</span>
          </p>
        </div>
        <div class="col-sm-4 d-flex align-items-end justify-content-end">
          <p class="mb-1">
            <span>{{translate('payrollYear')}}&nbsp;{{payrollYear}}</span><br>
            <span>{{translate('company')}}&nbsp;{{currentCompany}}</span><br>
          </p>
        </div>
      </div>
    </div>
  </footer>
</ng-container>
