import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PayrollProcessWizardModule} from '@harmony-modules/payroll/sections/process/wizard/payroll-process-wizard.module';
import {EnvService} from '@services/env.service';
import {AlertModule} from '@shared/alert';
import {TranslocoRootModule} from '@shared/i18n/transloco-root.module';
import {LayoutsModule} from '@shared/layouts/layouts.module';
import {AuthenticationService} from '@shared/services';
import {EnvInterceptor} from '@shared/utils/env.interceptor';
import {ErrorHandlerInterceptor} from '@shared/utils/error-handler.interceptor';
import {JwtInterceptor} from '@shared/utils/JwtInterceptor';

import * as bootstrap from 'bootstrap';
import {SignalRConfiguration, SignalRModule} from 'ng2-signalr';
import {CookieService} from 'ngx-cookie-service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AppComponent} from './app.component';
import {RoutingModule} from './app.routing';
import {AccessControlDirectiveModule} from './app/account/access-control/access-control-directive/access-control-directive.module';

import {AuthGuard} from './app/account/auth.guard';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {HttpLoaderStateInterceptor} from '@shared/utils/http-loader-state.interceptor';

export const bs = bootstrap;


export function createConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  c.hubName = 'salaryProcessHub';
  c.url = '';
  c.logging = true;

  // >= v5.0.0
  c.executeEventsInZone = true; // optional, default is true
  c.executeErrorsInZone = false; // optional, default is false
  c.executeStatusChangeInZone = true; // optional, default is true
  return c;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // AccountModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    AlertModule,
    RoutingModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    AccessControlDirectiveModule,
    LayoutsModule,
    TranslocoRootModule,
    PayrollProcessWizardModule,
    SignalRModule.forRoot(createConfig),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    LoadingBarModule
  ],
  providers: [
    CookieService,
    DatePipe, DecimalPipe, AuthenticationService, AuthGuard,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.getConfig(),
      deps: [EnvService],
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: EnvInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpLoaderStateInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true}
    // {provide: HttpClientModule, useValue: undefined},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
