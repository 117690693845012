import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvService} from '@services/env.service';
import {Observable} from 'rxjs';

@Injectable()
export class EnvInterceptor implements HttpInterceptor {

  constructor(private envService: EnvService) {
  }

  private isTNAUrl(url: string): boolean {
    return url.includes(this.envService.tnaApiUrl);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isTNAUrl(request.url)) {
      return next.handle(request);
    }
    return next.handle(request.clone({url: this.envService.baseApiUrl + request.url}));
  }
}
