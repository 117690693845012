<ng-container *transloco="let translate; read: 'chequePrintingOptions'">
  <ng-container [formGroup]="parentForm">
    <div class="row">
      <div class="col">
        <accordion [isAnimated]="true">
          <accordion-group [isOpen]="true">
            <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" accordion-heading type="button">{{translate('chequeAdjustment')}}</button>
            <div class="row">
              <div class="col-12">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{translate('horizontal')}}</th>
                      <th>{{translate('vertical')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('chequeNumber')}}</label>
                      </td>
                      <td>
                        <input type="text" class="form-control form-control-sm" id="ChqNumH" name="ChqNumH" formControlName="chequeNumberH" />
                      </td>
                      <td>
                        <input type="text" id="ChqNumV" name="ChqNumV" formControlName="chequeNumberV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('chequeDate')}}</label>
                      </td>
                      <td>
                        <input type="text" id="ChqDateH" name="ChqDateH" formControlName="chequeDateH" class="form-control form-control-sm" />
                      </td>
                      <td>
                        <input type="text" id="ChqDateV" name="ChqDateV" formControlName="chequeDateV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('amountNumeric')}}</label>
                      </td>
                      <td>
                        <input type="text" id="amntNumH" name="amntNumH" formControlName="amountNumericH" class="form-control form-control-sm" />
                      </td>
                      <td>
                        <input type="text" id="amntNumV" name="amntNumV" formControlName="amountNumericV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('amountAlphabetic')}}</label>
                      </td>
                      <td>
                        <input type="text" id="amntAlH" name="amntAlH" formControlName="amountAlphabeticH" class="form-control form-control-sm" />
                      </td>
                      <td>
                        <input type="text" id="amntAlV" name="amntAlV" formControlName="amountAlphabeticV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('employeeName')}}</label>
                      </td>
                      <td>
                        <input type="text" id="empNameH" name="empNameH" formControlName="employeeNameH" class="form-control form-control-sm" />
                      </td>
                      <td>
                        <input type="text" id="empNameV" name="empNameV" formControlName="employeeNameV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('employeeAddress')}}</label>
                      </td>
                      <td>
                        <input type="text" id="empAddH" name="empAddH" formControlName="employeeAddressH" class="form-control form-control-sm" />
                      </td>
                      <td>
                        <input type="text" id="empAddV" name="empAddV" formControlName="employeeAddressV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('companyName')}}</label>
                      </td>
                      <td>
                        <input type="text" id="cmpNameH" name="cmpNameH" formControlName="companyNameH" class="form-control form-control-sm" />
                      </td>
                      <td>
                        <input type="text" id="cmpNameV" name="cmpNameV" formControlName="companyNameV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('companyAddress')}}</label>
                      </td>
                      <td>
                        <input type="text" id="cmpAddH" name="cmpAddH" formControlName="companyAddressH" class="form-control form-control-sm" />
                      </td>
                      <td>
                        <input type="text" id="cmpAddV" name="cmpAddV" formControlName="companyAddressV" class="form-control form-control-sm" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </accordion-group>
          <accordion-group [isOpen]="true">
            <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" accordion-heading type="button">
              {{translate('printerAdjustment')}}
            </button>
            <div class="row">
              <div class="col-12">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{translate('left')}}</th>
                      <th>{{translate('top')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label for="ChqNumH" class="form-label">{{translate('margins')}}</label>
                      </td>
                      <td>
                        <input type="text" class="form-control form-control-sm" id="marginL" name="marginL" formControlName="marginLeft" />
                      </td>
                      <td>
                        <input type="text" class="form-control form-control-sm" id="marginR" name="marginR" formControlName="marginRight" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </div>
  </ng-container>
</ng-container>
