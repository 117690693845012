<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <ng-container *ngFor="let item of menuItems">
        <ng-container *ngIf="!item.hide">
          <ng-container *appAccessControl="{componentName: item?.accessControlKey, accessType: 'view', module: item?.module}">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | transloco }}</li>
          </ng-container>
          <li *ngIf="!item.isTitle && !item.isLayout" [ngClass]="{'mm-active': item?.isActive}">
            <ng-container *appAccessControl="{componentName: item?.accessControlKey, accessType: 'view', module: item?.module}">
              <a *ngIf="item?.subItems?.length" class="is-parent has-arrow" [ngClass]="{'mm-collapsed': !item?.isActive}" (click)="onClickMenu(item)">
                <i class="{{item.icon}}" *ngIf="item.icon"></i>
                <span *ngIf="!item?.skipTranslation"> {{ item.label | transloco }}</span>
                <span *ngIf="item?.skipTranslation">{{ item?.label }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
              </a>
              <a [routerLink]="item.link"
                 *ngIf="!item?.subItems?.length"
                 class="side-nav-link-ref"
                 routerLinkActive="mm-active"
                 [routerLinkActiveOptions]="{exact: true}"
              >
                <i class="{{ item.icon }}" *ngIf="item.icon"></i>
                <span *ngIf="!item?.skipTranslation"> {{ item.label | transloco }}</span>
                <span *ngIf="item?.skipTranslation">{{ item?.label }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text}}</span>
              </a>
              <ul *ngIf="item?.subItems?.length && item.isActive" class="sub-menu" [ngClass]="{'mm-show': item?.isActive}" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems" [ngClass]="{'mm-active': subitem?.isActive}">
                  <ng-container *appAccessControl="{componentName: subitem?.accessControlKey, accessType: 'view', module: subitem?.module}">
                    <ng-container *ngIf="!subitem?.isLayout && subitem?.label; else separator">
                      <a [routerLink]="subitem.link"
                         *ngIf="!subitem?.subItems?.length && !subitem.hide"
                         class="side-nav-link-ref"
                         [attr.data-parent]="subitem.parentId"
                         [ngClass]="{'condensed': isCondensed}"
                         routerLinkActive="active"
                         [routerLinkActiveOptions]="{exact: true}"
                      >
                        <span *ngIf="!subitem?.skipTranslation"> {{ subitem.label | transloco }}</span>
                        <span *ngIf="subitem?.skipTranslation">{{ subitem?.label }}</span>
                      </a>
                      <a *ngIf="subitem?.subItems?.length && !subitem.hide"
                         class="side-nav-link-a-ref has-arrow"
                         [attr.data-parent]="subitem.parentId"
                         (click)="onClickMenu(subitem)"
                      >
                        <span *ngIf="!subitem?.skipTranslation"> {{ subitem.label | transloco }}</span>
                        <span *ngIf="subitem?.skipTranslation">{{ subitem?.label }}</span>
                        <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end" *ngIf="subitem.badge">{{subitem.badge.text}}</span>
                      </a>
                      <ul *ngIf="subitem?.subItems?.length && !subitem.hide && subitem.isActive" [ngClass]="{'mm-show': subitem?.isActive}"
                          class="sub-menu mm-collapse" aria-expanded="false">
                        <li *ngFor="let subSubitem of subitem.subItems">
                          <ng-container *appAccessControl="{componentName: subSubitem?.accessControlKey, accessType: 'view', module: subSubitem?.module}">
                            <a [attr.data-parent]="subSubitem.parentId"
                               [routerLink]="subSubitem.link"
                               routerLinkActive="active"
                               [routerLinkActiveOptions]="{exact: true}"
                               class="side-nav-link-ref"
                               [ngClass]="{'condensed': isCondensed}"
                               *ngIf="!subSubitem.hide"
                            >
                              <span *ngIf="!subSubitem?.skipTranslation"> {{ subSubitem.label | transloco }}</span>
                              <span *ngIf="subSubitem?.skipTranslation">{{ subSubitem?.label }}</span>
                              <span class="badge rounded-pill bg-{{subSubitem.badge.variant}} float-end"
                                    *ngIf="subSubitem.badge">{{subSubitem.badge.text}}</span>
                            </a>
                          </ng-container>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>
                  <ng-template #separator>
                    <a class="side-nav-link-ref py-1 ps-5">
                      <hr class="my-0 w-100">
                    </a>
                  </ng-template>
                </li>
              </ul>
            </ng-container>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
<ng-template let-label="label" #notTranslations>
  <span> {{ label }}</span>
</ng-template>
