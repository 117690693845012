import {DatePipe} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {PayrollCategoryEntitlementService} from '@harmony-modules/payroll/services/payroll-setup/payroll-category-entitlement.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {IEmpWithoutPay_Grp_With_ctl_code, IPayrollGroups, IPayrollProcessPeriod} from '@shared/models/ipayroll-group';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DeleteConfirmationService} from '@services/delete-confirmation.service';
import {ShowMessageService} from '@services/show-message.service';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import * as moment from 'moment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject, Subscription} from 'rxjs';
import {concatMap, take, takeUntil} from 'rxjs/operators';


declare var showMsg: any;
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-timesheet-grid',
  templateUrl: './timesheet-grid.component.html',
  styleUrls: ['./timesheet-grid.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'timesheetGrid', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})

export class TimesheetGridComponent implements OnInit, OnDestroy {
  @ViewChild('categorySelection') categorySelectionModal: TemplateRef<any>;

  /**
   * t(timesheetGrid.employeeName, timesheetGrid.hourlyRate,, timesheetGrid.addOther)
   * t(mon, tue, wed, thu, fri, sat, sun)
   */
  headers: string[] = ['', 'employeeName', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'hourlyRate', 'addOther'];
  selectAll = false;
  disableSelAll = false;
  groupCode: any;
  payrollGroups: IPayrollGroups[];
  payrollPeriod = '';    // for displaying
  payrollPeriodBase: IPayrollProcessPeriod;
  sysPref: any;
  payfrequency = '';   // for displaying
  unSignedEmpList: IEmpWithoutPay_Grp_With_ctl_code[] = [];
  gridData: any;
  summaryData: any;
  summaryActive = true;
  modalCateSelectList: any = [];
  modalSelectedCate: any;
  modalTitle = '';
  wizardMode = false;
  sortOrder = 'eno';
  sortdirec = false;
  numDaysInPayperiod: number;
  empId: number;
  catDtlCode: number;
  latest_ei: number;
  latest_en: string;
  latest_c: number;
  public updateDone = false;
  // getTimeSheetGridDataBulkViewService: Subscription;
  private subscrption: Subscription;
  private onDestroy$: Subject<void> = new Subject<void>();

  timesheetModalRef: BsModalRef;
  categorySelectionModalRef: BsModalRef;

  payCardImportForm: NgForm;

  constructor(
    private _payrollService: PayrollGroupService,
    private _deleteConfirmation: DeleteConfirmationService,
    private _sysPrefService: SystemPreferenceService,
    private _datePipe: DatePipe,
    private _payrollCategoryService: PayrollCategoryEntitlementService,
    private _wizardService: WizardService,
    private modalService: BsModalService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private showMessage: ShowMessageService
  ) {
  }


  ngOnInit() {

    // this.transloco.selectTranslate(null, {}, this.scope).pipe(untilDestroyed(this)).subscribe(() => {
    //  const header1 = ['', this.transloco.translate('employeeName', {}, 'timesheetGrid')];
    //  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(x => this.transloco.translate(x));
    //  const header2 = [
    //    this.transloco.translate('hourlyRate', {}, 'timesheetGrid'),
    //    this.transloco.translate('addOther', {}, 'timesheetGrid')
    //  ];

    //  this.headers = [...header1, ...days, ...header2];
    // });
    this._sysPrefService.getinitSystemPreferences().pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.sysPref = data[0];
        if (!this.sysPref.df) {
          this.sysPref.df = 'yyyy-MM-dd';
        }

      }
      this._wizardService.wizardMode$
        .pipe(
          concatMap(mode => {
            this.wizardMode = mode;
            return this._payrollService.getPayrollAllGroupsForTimesheet();
          }),
          untilDestroyed(this)).subscribe(data => {
        if (data && data.length) {
          this.payrollGroups = data;
          this.groupCode = this.payrollGroups[0];
          this.onPGDropDownChange(this.payrollGroups[0]);

        }
      });
    });
  }

  toggleViewTabs() {
    this.summaryActive = !this.summaryActive;
    if (this.groupCode) {
      this.onPGDropDownChange(this.groupCode);
    }
  }

  sortOptionChange(header: string) {
    if (this.sortOrder == header) {
      this.sortdirec = !this.sortdirec;
    } else {
      this.sortOrder = header;
      this.sortdirec = false;
    }
  }

  getWeekends() {
    const date1 = new Date(this.payrollPeriodBase.b);
    const date2 = new Date(this.payrollPeriodBase.c);
    let count = 0;
    while (date1 < date2) {
      const dayNo = date1.getDay();
      date1.setDate(date1.getDate() + 1);
      if (!dayNo || dayNo == 6) {
        count++;
      }
    }
    return count;
  }

  selectAllOnChange() {
    this.selectAll = !this.selectAll;
    if (!this.summaryActive) {
      this.gridData.forEach(x => {
        x.checked = this.selectAll;
      });
    } else {
      this.summaryData.forEach(x => {
        x.checked = this.selectAll;
      });
    }
  }

  loadBulkViewData(gc: number, fd: any, td: any) {
    this._payrollService.getTimeSheetGridDataBulkView(gc, fd, td).pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.summaryData = data;
      }
      this.summaryData.forEach(x => {
        // console.log(this.summaryData);
        if (x.seq) {
          this.disableSelAll = true;
        }
        x.checked = false;
        x.r = x.r.toFixed(this.sysPref.g || 2);
        x.u = x.u.toFixed(this.sysPref.h || 2);
        x.total = x.r * x.u;
      });
    });
  }

  loadTimesheetGrid(gc: number, fd: any, td: any) {
    this._payrollService.getTimeSheetGridData(this.empId, this.catDtlCode, gc, fd, td).pipe(untilDestroyed(this)).subscribe(data => {
      this.selectAll = false;
      let count = 0;
      const temp = data;
      temp.forEach(x => {
        x.checked = false;
        x.cates.forEach(y => {
          y.r = y.r.toFixed(this.sysPref.g || 2);
          y.workdays.forEach(z => {
            z.weekday = (new Date(z.wd)).getDay();
            z.u = z.u.toFixed(this.sysPref.h || 2);
          });

          const startingWeekDay = (new Date(this.payrollPeriodBase.b)).getDay();
          let temp: any = this.payrollPeriodBase.b;
          const tempWdList = [];
          const foo = Object.assign({}, y.workdays[0]);
          foo.u = 0;
          foo.u = foo.u.toFixed(this.sysPref.h || 2);


          for (let i = 0; i < this.numDaysInPayperiod; i++) {
            const tempWd = Object.assign({}, foo);
            tempWd.wd = temp;
            tempWd.weekday = (new Date(tempWd.wd)).getDay();
            let dateToAdd = y.workdays.find(x => {
              const a = new Date(x.wd.toString());
              const b = new Date(temp);
              const d = moment(a).startOf('day').diff(moment(b).startOf('day'), 'days');
              return !d;
            });
            dateToAdd = dateToAdd ? dateToAdd : tempWd;
            tempWdList.push(dateToAdd);

            temp = moment(temp).add(1, 'days').toDate();
          }
          y.workdays = tempWdList;

          // for (let i = 0; i < y.workdays.length; i++) {
          //  if (i != y.workdays.length - 1) {
          //    let current = moment(y.workdays[i].wd.split('T')[0], 'YYYY-MM-DD');

          //    let next = moment(y.workdays[i + 1].wd.split('T')[0], 'YYYY-MM-DD');
          //    let diff = Math.abs(moment.duration(current.diff(next)).asDays());
          //    //let diff = next.diff(current, 'days') + 1 - this.getWeekends()
          //    console.log(diff);
          //    if (diff != 1) {
          //      let obj = Object.assign({}, y.workdays[i]);
          //      obj.u = 0;
          //      obj.u = obj.u.toFixed(this.sysPref.h || 2)
          //      obj.weekday = (obj.weekday + 1) % 7
          //      obj.wd = moment(current).add(1,'days').toDate().toISOString();
          //      y.workdays.splice(i+1,0,obj)
          //      console.log(obj)
          //    }
          //  }
          // }

          y.mfs = y.workdays.filter(u => u.weekday == 1);
          y.tfs = y.workdays.filter(u => u.weekday == 2);
          y.wfs = y.workdays.filter(u => u.weekday == 3);
          y.thfs = y.workdays.filter(u => u.weekday == 4);
          y.ffs = y.workdays.filter(u => u.weekday == 5);
          y.sfs = y.workdays.filter(u => u.weekday == 6);
          y.sufs = y.workdays.filter(u => u.weekday == 0);
          if (startingWeekDay == 2) {
            y.mfs.unshift({placeholder: true});
          }
          if (startingWeekDay == 3) {
            y.mfs.unshift({placeholder: true});
            y.tfs.unshift({placeholder: true});
          }
          if (startingWeekDay == 4) {
            y.mfs.unshift({placeholder: true});
            y.tfs.unshift({placeholder: true});
            y.wfs.unshift({placeholder: true});
          }
          if (startingWeekDay == 5) {
            y.mfs.unshift({placeholder: true});
            y.tfs.unshift({placeholder: true});
            y.wfs.unshift({placeholder: true});
            y.thfs.unshift({placeholder: true});
          }
          if (startingWeekDay == 6) {
            y.mfs.unshift({placeholder: true});
            y.tfs.unshift({placeholder: true});
            y.wfs.unshift({placeholder: true});
            y.thfs.unshift({placeholder: true});
            y.ffs.unshift({placeholder: true});
          }
          if (startingWeekDay == 0) {
            y.mfs.unshift({placeholder: true});
            y.tfs.unshift({placeholder: true});
            y.wfs.unshift({placeholder: true});
            y.thfs.unshift({placeholder: true});
            y.ffs.unshift({placeholder: true});
            y.sfs.unshift({placeholder: true});
          }

          // add tabindex to each workday input field
          const weekdayBuff = ['mfs', 'tfs', 'wfs', 'thfs', 'ffs', 'sfs', 'sufs'];
          let numberOfdays = 0;
          let tempcount = count;
          weekdayBuff.forEach(z => {
            if (y[z].length) {
              tempcount += 1;
            }
            for (let i = 0; i < y[z].length; i++) {
              if (!y[z][i].placeholder) {
                y[z][i].tabindex = tempcount + i * 7;
                numberOfdays += 1;
              }
            }
          });

          count += numberOfdays;
        });
      });

      this.gridData = temp;
    });
  }

  updateRateForEmp(ei: number, c: number, r: number) {
    const obj = this.gridData.find(x => x.ei == ei).cates.find(y => y.c == c);
    obj.workdays.forEach(z => {
      z.r = r;
      z.touched = true;
    });
    obj.r = r;
  }

  loadAllUnsignedEmps(gc: number, fd: Date, td: Date) {
    this._payrollService.getUnsignedEmpGridData(gc, fd, td).pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length) {
        const from = moment(this.payrollPeriodBase.b.toString().split('T')[0], 'YYYY-MM-DD');
        const to = moment(this.payrollPeriodBase.c.toString().split('T')[0], 'YYYY-MM-DD');
        const diff = to.diff(from, 'days') + 1 - this.getWeekends();
        const tempUnit = data[0].u;
        this.unSignedEmpList = data;
        this.unSignedEmpList.forEach(x => {

          x.u = this.summaryActive ? x.u : x.u / diff;
        });
        if (!this.summaryActive) {
          const total = this.unSignedEmpList[0].u * diff;
          if (total !== tempUnit) {
            this.unSignedEmpList[this.unSignedEmpList.length - 1].u += (tempUnit - total);
          }
        }
      } else {
        this.unSignedEmpList = null;
      }
    });
  }

  onTimeSheetSubmit(form: NgForm) {
    this.updateDone = false;
    const toUpdate: any = [];
    let nocheck = true;
    if (!this.summaryActive) {
      this.gridData.forEach(x => {
        x.cates.forEach(y => {
          // if (x.checked) {
          nocheck = false;
          y.workdays.forEach(z => {
            if (z.touched) {
              toUpdate.push(z);
            }
          });

          // }
        });
      });

    } else {
      this.summaryData.forEach(x => {
        if (x.checked) {
          nocheck = false;
          if (x.touched) {
            toUpdate.push(x);
          }
        } else if (!x.checked && x.touched) {
          nocheck = false;
          toUpdate.push(x);
        }
      });
    }

    if (nocheck) {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.cannotUpdate', {}, 'timesheetGrid');
      showMsg({
        type: 'sticky',
        status: 'error',
        title: title,
        buttons: {
          [buttonOk]: {
            action() {
              return;
            }
          }
        },
        message: message
      });
      return;
    }
    if (toUpdate.length == 0) {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.noChanges', {}, 'timesheetGrid');
      showMsg({
        type: 'sticky',
        status: 'error',
        title: title,
        buttons: {
          [buttonOk]: {
            action() {
              return;
            }
          }
        },
        message: message
      });
      return;
    }

    this._payrollService.updateGridData(toUpdate, this.summaryActive).pipe(untilDestroyed(this)).subscribe(invalidEmps => {
      if (invalidEmps) {
        const that = this;
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        const message = this.transloco.translate('alerts.cannotUpdateBecauseEmployeeHasPaycard', {value: invalidEmps}, 'timesheetGrid');
        showMsg({
          type: 'sticky',
          status: 'error',
          title: title,
          buttons: {
            [buttonOk]: {
              action() {
                if (!that.summaryActive) {
                  that.loadTimesheetGrid(that.groupCode.a, that.payrollPeriodBase.b, that.payrollPeriodBase.c);
                }
                that.loadBulkViewData(that.groupCode.a, that.payrollPeriodBase.b, that.payrollPeriodBase.c);

                that.loadAllUnsignedEmps(that.groupCode.a, that.payrollPeriodBase.b, that.payrollPeriodBase.c);

                if (invalidEmps.length != toUpdate.length) {
                  this._wizardService.updateFlag(2).pipe(untilDestroyed(this)).subscribe(data => {
                    if (data) {
                      this._wizardService.updateWizard(data);
                    }
                  });
                }
              }
            }
          },
          message: message
        });
      } else {
        if (!this.summaryActive) {
          this.loadTimesheetGrid(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        }
        this.loadBulkViewData(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);

        this.loadAllUnsignedEmps(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);

        this._wizardService.updateFlag(2).pipe(untilDestroyed(this)).subscribe(data => {
          if (data) {
            this._wizardService.updateWizard(data);
          }
        });
        this._wizardService.updateDirtyStep(false);
        const title = this.transloco.translate('success');
        const message = this.transloco.translate('recordSuccessfullyUpdated');
        showMsg({
          type: 'nonsticky',
          status: 'success',
          title: title,
          buttons: {},
          message: message,
          delay: '1000'
        });
        this.updateDone = true;
        return;
      }

    }, err => {
      const buttonOk = this.transloco.translate('ok');
      const title = this.transloco.translate('error');
      const message = this.showMessage.translateErrorMessage(err);
      showMsg({
        type: 'sticky',
        status: 'error',
        title: title,
        buttons: {
          [buttonOk]: {
            action() {
              return;
            }
          }
        },
        message: message
      });
      return;
    });

  }


  onPGDropDownChange(evt: any) {
    let _evt = evt;
    if (!evt.a) {
      return;
    } else {
      this._wizardService.currentGroupInfo$.pipe(take(1), untilDestroyed(this)).subscribe(group => {

        if (group) {
          const index = this.payrollGroups.indexOf(this.payrollGroups.find(x => x.a == group.gc));
          this.groupCode = this.payrollGroups[index];
          _evt = this.groupCode;
        }

        this.unSignedEmpList = [];

        this.subscrption =
          this._payrollService.getPayrollProcessPeriod(_evt.a)
            .pipe(take(1), takeUntil(this.onDestroy$), untilDestroyed(this))
            .subscribe(data => {
              this.payrollPeriodBase = data[0];
              const x = this._datePipe.transform(this.payrollPeriodBase.b, this.sysPref.df);
              const y = this._datePipe.transform(this.payrollPeriodBase.c, this.sysPref.df);
              this.payrollPeriod = x + ' - ' + y;
              this.payfrequency = this.payrollPeriodBase.d;
              const from = moment(this.payrollPeriodBase.b.toString().split('T')[0], 'YYYY-MM-DD');
              const to = moment(this.payrollPeriodBase.c.toString().split('T')[0], 'YYYY-MM-DD');
              this.numDaysInPayperiod = to.diff(from, 'days') + 1;
              // if (!this.summaryActive)
              // {
              //  this.loadTimesheetGrid(_evt.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c)
              // }
              this.loadBulkViewData(_evt.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
              this.loadAllUnsignedEmps(_evt.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
            });
      });
    }
  }

  deleteConfirmation(ei: number, c: number, deleteall) {

    if (deleteall) {
      this._deleteConfirmation.deleteConfirmation(this.deleteRows.bind(this), this.transloco.translate('alerts.confirmDelete', {}, 'timesheetGrid'));
    } else {
      if (!this.summaryActive) {
        this._deleteConfirmation.deleteConfirmation(this.deleteRow.bind(this, ei, c), this.transloco.translate('alerts.confirmDeleteRecord', {}, 'timesheetGrid'));
      } else {
        this._deleteConfirmation.deleteConfirmation(this.deleteRow.bind(this, ei, c), this.transloco.translate('alerts.confirmDeleteSelectedRecords', {}, 'timesheetGrid'));
      }
    }
  }

  checkUnsavedBeforeDeleteRow(ei: number, c: number, deleteall) {
    let unsaved = false;
    let selected = false;
    if (deleteall) {
      if (!this.summaryActive) {
        this.gridData.forEach(x => {
          if (x.checked) {
            selected = true;
          }
          x.cates.forEach(y => {
            y.workdays.forEach(z => {
              // checking if there is any other record being modified that are not the one trying to be deleted
              if (z.touched && !x.checked) {
                unsaved = true;
              }
            });

          });
        });
      } else {
        this.summaryData.forEach(x => {
          if (x.checked) {
            selected = true;
          }
          if (x.touched && !x.checked) {
            unsaved = true;
          }
        });
      }
    } else {
      if (!this.summaryActive) {
        this.gridData.forEach(x => {
          if (x.checked) {
            selected = true;
          }
          x.cates.forEach(y => {
            y.workdays.forEach(z => {
              // checking if there is any other record being modified that are not the one trying to be deleted
              if (z.touched && (z.ei != ei || y.c != c)) {
                unsaved = true;
              }
            });

          });
        });
      } else {
        this.summaryData.forEach(z => {
          if (z.checked) {
            selected = true;
          }
          // checking if there is any other record being modified that are not the one trying to be deleted
          if (z.touched && (z.ei != ei || z.c != c)) {
            unsaved = true;
          }
        });
      }
    }

    if (unsaved) {
      const that = this;
      let result = null;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.pleaseSaveBeforeDelete', {}, 'timesheetGrid');
      const buttonDiscard = this.transloco.translate('discard', {}, 'timesheetGrid');

      showMsg({    // prompt user to save unsaved changes in the grid
        type: 'sticky',
        status: 'warning',
        title: title,
        buttons: {
          [buttonOk]: {
            async action() {
              if (!that.selectAll) {
                that.selectAllOnChange();
              }
              result = await that.onTimeSheetSubmit(null);
            }
          },
          [buttonDiscard]: {
            async action() {
              setTimeout(
                that.deleteConfirmation.bind(that, ei, c, deleteall), 200
              );
            }
          }
        },
        message: message
      });
    } else if (!selected && deleteall==1) {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.pleaseSelectEmployeeTimesheetToDelete', {}, 'timesheetGrid');
      showMsg({
        type: 'sticky',
        status: 'error',
        title: title,
        buttons: {
          [buttonOk]: {
            action() {
            }
          }
        },
        message: message,
        delay: '1500'
      });
      return;
    } else {
      this.deleteConfirmation(ei, c, deleteall);
    }
  }

  deleteRows() {
    const toDelete = [];
    if (!this.summaryActive) {
      this.gridData.forEach(emp => {
        if (emp.checked) {
          emp.cates.forEach(x => {
            toDelete.push({ei: emp.ei, c: x.c, gc: this.groupCode.a, fd: this.payrollPeriodBase.b, td: this.payrollPeriodBase.c});
          });
        }
      });
    } else {
      this.summaryData.forEach(emp => {
        if (emp.checked) {
          toDelete.push({ei: emp.ei, c: emp.c, gc: this.groupCode.a, fd: this.payrollPeriodBase.b, td: this.payrollPeriodBase.c});
        }
      });
    }
    this._payrollService.deleteRows(toDelete).pipe(untilDestroyed(this)).subscribe(data => {
      if (data == []) {
        const title = this.transloco.translate('success');
        const message = this.transloco.translate('recordSuccessfullyDeleted');
        showMsg({
          type: 'nonsticky',
          status: 'success',
          title: title,
          buttons: {},
          message: message,
          delay: '1000'
        });
      }

      this._wizardService.updateFlag(2).pipe(untilDestroyed(this)).subscribe(data => {
        if (data) {
          this._wizardService.updateWizard(data);
        }
      });

      if (!this.summaryActive) {
        this.loadTimesheetGrid(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
      }
      this.loadBulkViewData(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
      this.loadAllUnsignedEmps(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
    });
  }

  deleteRow(ei: number, c: number) {

    this._payrollService.deleteRow(ei, c, this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c).pipe(untilDestroyed(this)).subscribe(invalidEmps => {
      if (!invalidEmps) {
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        const message = this.transloco.translate('alerts.cannotDeleteBecauseEmployeeHasPaycard', {}, 'timesheetGrid');
        showMsg({
          type: 'sticky',
          status: 'error',
          title: title,
          buttons: {
            [buttonOk]: {
              action() {
                return;
              }
            }
          },
          message: message
        });
        if (!this.summaryActive) {
          this.loadTimesheetGrid(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        }
        this.loadBulkViewData(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        this.loadAllUnsignedEmps(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
      } else {
        if (!this.summaryActive) {
          this.loadTimesheetGrid(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        }
        this.loadBulkViewData(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        this.loadAllUnsignedEmps(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        this._wizardService.updateFlag(2).pipe(untilDestroyed(this)).subscribe(data => {
          if (data) {
            this._wizardService.updateWizard(data);
          }
        });
        const title = this.transloco.translate('success');
        const message = this.transloco.translate('recordSuccessfullyDeleted');
        showMsg({
          type: 'nonsticky',
          status: 'success',
          title: title,
          buttons: {},
          message: message,
          delay: '1000'
        });
      }
      this.timesheetModalRef?.hide();
    }, err => {
      const title = this.transloco.translate('error');
      const buttonOk = this.transloco.translate('ok');
      const message = this.showMessage.translateErrorMessage(err);
      showMsg({
        type: 'sticky',
        status: 'error',
        title: title,
        buttons: {
          [buttonOk]: {
            action() {
              return;
            }
          }
        },
        message: message
      });
    });
  }

  addCateToEmp(ei: number, en: string, c: number) {
    this.modalTitle = en;
    this.modalSelectedCate = undefined;
    this.modalCateSelectList = null;
    this.latest_ei = ei;
    this.latest_en = en;
    this.latest_c = c;
    let unsaved = false;
    if (!this.summaryActive) {
      this.gridData.forEach(x => {
        x.cates.forEach(y => {
          y.workdays.forEach(z => {
            if (z.touched) {
              unsaved = true;
            }
          });

        });
      });
    } else {
      if (this.summaryData.find(x => x.touched)) {
        unsaved = true;
      }

    }
    if (unsaved) {
      const that = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonDiscard = this.transloco.translate('discard', {}, 'timesheetGrid');
      const message = this.transloco.translate('alerts.continue', {}, 'timesheetGrid');
      showMsg({    // prompt user to save unsaved changes in the grid
        type: 'sticky',
        status: 'warning',
        title: title,
        buttons: {
          [buttonOk]: {
            action() {
              if (!that.selectAll) {
                that.selectAllOnChange();
              }
              that.onTimeSheetSubmit(null);
              that.updateDone = true;
              that.modalSelectedCate = null;
              that.modalCateSelectList = null;
            }
          },
          [buttonDiscard]: {
            action() {
              that.modalSelectedCate = null;
              that.modalCateSelectList = null;
              that.getCats(ei, en, c);
            }
          }
        },
        message: message
      });
    } else {
      this.getCats(ei, en, c);
    }
  }

  getCats(ei: number, en: string, c: number): void {
    this._payrollCategoryService.getTimeSheetCatesForEmp(ei, c, this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c).pipe(untilDestroyed(this)).subscribe(res => {
      if (res == -1) {
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        const message = this.transloco.translate('alerts.cannotAddBecauseEmployeeHasPaycard', {}, 'timesheetGrid');
        showMsg({
          type: 'sticky',
          status: 'error',
          title: title,
          buttons: {
            [buttonOk]: {
              action() {
              }
            }
          },
          message: message,
          delay: '1500'
        });
        return;
      }
      if (res) {
        this.modalCateSelectList = res;
        this.modalCateSelectList.forEach(x => {
          x.ei = ei;
        });
        this.modalSelectedCate = undefined;
        // $('#categorySelection').modal('show');
        this.categorySelectionModalRef = this.modalService.show(this.categorySelectionModal, {backdrop: 'static'});
      }
    });
  }

  addDailyTimesheet(empId: number, en: string, c: number, modalTemplate: TemplateRef<any>) {
    let unsaved = false;
    if (this.summaryData.find(x => x.touched)) {
      unsaved = true;
    }
    if (unsaved) {
      const that = this;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const buttonDiscard = this.transloco.translate('discard', {}, 'timesheetGrid');
      const message = this.transloco.translate('alerts.continue', {}, 'timesheetGrid');
      showMsg({    // prompt user to save unsaved changes in the grid
        type: 'sticky',
        status: 'warning',
        title: title,
        buttons: {
          [buttonOk]: {
            action() {
              if (!that.selectAll) {
                that.selectAllOnChange();
              }
              that.onTimeSheetSubmit(null);
              that.updateDone = true;
            }
          },
          [buttonDiscard]: {
            action() {
              that.modalTitle = en;
              that.gridData = null;
              that.empId = empId;
              that.catDtlCode = c;
              that.summaryActive = false;
              that.loadTimesheetGrid(that.groupCode.a, that.payrollPeriodBase.b, that.payrollPeriodBase.c);
              that.timesheetModalRef = that.modalService.show(modalTemplate, {class: 'modal-xl', backdrop: 'static'});
            }
          }
        },
        message: message
      });
    } else {
      this.modalTitle = en;
      this.gridData = null;
      this.empId = empId;
      this.catDtlCode = c;
      this.summaryActive = false;
      this.loadTimesheetGrid(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
      this.timesheetModalRef = this.modalService.show(modalTemplate, {class: 'modal-xl', backdrop: 'static'});
    }
  }

  closeDailyTimesheet() {
    let unsaved = false;
    this.gridData.forEach(x => {
      x.cates.forEach(y => {
        y.workdays.forEach(z => {
          // checking if there is any other record being modified
          if (z.touched) {
            unsaved = true;
          }
        });

      });
    });

    if (unsaved) {
      const that = this;
      let result = null;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.pleaseSaveBeforeClose', {}, 'timesheetGrid');
      const buttonDiscard = this.transloco.translate('discard', {}, 'timesheetGrid');
      showMsg({    // prompt user to save unsaved changes in the grid
        type: 'sticky',
        status: 'warning',
        title: title,
        buttons: {
          [buttonOk]: {
            async action() {
              // if(!that.selectAll)
              // that.selectAllOnChange()
              result = await that.onTimeSheetSubmit(null);
            }
          },
          [buttonDiscard]: {
            async action() {
              setTimeout(
                () => that.timesheetModalRef.hide(), 200
              );
              this.summaryActive = true;
            }
          }
        },
        message: message
      });
    } else {
      // $('#mdlDailyTimesheet').modal('hide');
      this.timesheetModalRef.hide();
      this.summaryActive = true;
    }

  }

  onAddingNewCategory() {
    console.log(this.summaryData);
    let unsaved = false;
    if (!this.summaryActive) {
      this.gridData.forEach(x => {
        x.cates.forEach(y => {
          y.workdays.forEach(z => {
            if (z.touched) {
              unsaved = true;
            }
          });

        });
      });
    } else {
      if (this.summaryData.find(x => x.touched)) {
        unsaved = true;
      }
    }
  }

  onModelSelection(c: any) {

    this.modalSelectedCate = undefined;
    this.modalCateSelectList = null;

    if (!this.selectAll) {
      // this.selectAllOnChange();
      if (c) {
        this.insertRow(this.groupCode.a, c.ei, c.c, 0, c.ea, []);
      }
    } else {
      if (c) {
        this.insertRow(this.groupCode.a, c.ei, c.c, 0, c.ea, []);
      }
    }

    c = undefined;
    this.categorySelectionModalRef.hide();
  }

  // onModelSelection(c: any) {
  //  $('#categorySelection').modal('hide')
  //  console.log(this.summaryData);
  //  let unsaved = false
  //  if (!this.summaryActive) {
  //    this.gridData.forEach(x => {
  //      x.cates.forEach(y => {
  //        y.workdays.forEach(z => {
  //          if (z.touched)
  //            unsaved = true;
  //        })

  //      })
  //    })
  //  }
  //  else {
  //    if (this.summaryData.find(x => x.touched))
  //      unsaved = true;
  //  }

  //  if (unsaved) {
  //    var that = this
  //    showMsg({    //prompt user to save unsaved changes in the grid
  //      'type': 'sticky',
  //      'status': 'warning',
  //      'title': 'Harmony Payroll System',
  //      'buttons': {
  //        'OK': {
  //          action:  function () {
  //            if (!that.selectAll)
  //              that.selectAllOnChange()
  //            //that.onTimeSheetSubmit(null);
  //            that.modalSelectedCate = null;
  //            that.modalCateSelectList = null;
  //            if (c)
  //            that.insertRow(that.groupCode.a, c.ei, c.c, 0, c.ea, [])
  //          }
  //        },
  //        'Discard Changes': {
  //          action:  function () {
  //            that.modalSelectedCate = null;
  //            that.modalCateSelectList = null;

  //            if (c)
  //            that.insertRow(that.groupCode.a, c.ei, c.c, 0, c.ea, [])
  //          }
  //        }
  //      },
  //      'message': 'you have unsaved changes in this grid, would you like to save the changes before moving to next step?',
  //    });
  //  } else {
  //    this.modalSelectedCate = null;
  //    this.modalCateSelectList = null;
  //    if (c)
  //      this.insertRow(this.groupCode.a, c.ei, c.c, 0, c.ea, [])
  //  }

  // }
  checkUnsaveBeforeInsertingRow(gc: number, ei: number, c: number, u: number, r: number, toAdd: any[]) {
    console.log('checkUnsaveBeforeInsertingRow: ' + gc + ' ei: ' + ei + 'c: ' + c + ' u: ' + u + ' r: ' + r);
    let unsaved = false;
    this.unSignedEmpList.forEach(x => {
      if (x.i == ei) {
        x.disable = true;
      }
    });

    if (!this.summaryActive) {
      this.gridData.forEach(x => {
        x.cates.forEach(y => {
          y.workdays.forEach(z => {
            // checking if there is any other record being modified that are not the one trying to be deleted
            if (z.touched && (z.ei != ei || y.c != c)) {
              unsaved = true;
            }
          });

        });
      });
    } else {
      this.summaryData.forEach(z => {
        // checking if there is any other record being modified that are not the one trying to be deleted
        if (z.touched && (z.ei != ei || z.c != c)) {
          unsaved = true;
        }
      });
    }

    if (unsaved) {
      const that = this;
      let result = null;
      const title = this.transloco.translate('warning');
      const buttonOk = this.transloco.translate('ok');
      const message = this.transloco.translate('alerts.pleaseSaveBeforeDelete', {}, 'timesheetGrid');
      const buttonDiscard = this.transloco.translate('discard', {}, 'timesheetGrid');
      showMsg({    // prompt user to save unsaved changes in the grid
        type: 'sticky',
        status: 'warning',
        title: title,
        buttons: {
          [buttonOk]: {
            async action() {
              if (!that.selectAll) {
                that.selectAllOnChange();
              }
              result = await that.onTimeSheetSubmit(null);
            }
          },
          [buttonDiscard]: {
            async action() {
              setTimeout(
                that.insertRow.bind(that, gc, ei, c, u, r, toAdd), 200
              );
            }
          }
        },
        message: message
      });
    } else {
      this.insertRow(gc, ei, c, u, r, toAdd);
    }
  }

  insertRow(gc: number, ei: number, c: number, u: number, r: number, toAdd: any[]) {
    console.log('insertRow: ' + gc + ' ei: ' + ei + 'c: ' + c + ' u: ' + u + ' r: ' + r);
    const from = moment(this.payrollPeriodBase.b.toString().split('T')[0], 'YYYY-MM-DD');
    const to = moment(this.payrollPeriodBase.c.toString().split('T')[0], 'YYYY-MM-DD');
    const diff = to.diff(from, 'days') + 1;
    let summaryViewFirstWorkingDateSet = false;
    for (let i = 0; i < diff; i++) {
      const workingDate = new Date(this.payrollPeriodBase.b);
      workingDate.setDate(workingDate.getDate() + i);
      // Saturday and Sunday hours should default to zero
      if (workingDate.getDay() == 0 || workingDate.getDay() == 6) {
        toAdd.push({
          seq: 0,
          c: c,
          ei: ei,
          fd: this.payrollPeriodBase.b,
          td: this.payrollPeriodBase.c,
          gc: gc,
          wd: workingDate,
          u: 0,
          r: r,
          y: this.payrollPeriodBase.e
        });
      } else {

        toAdd.push({
          seq: 0,
          c: c,
          ei: ei,
          fd: this.payrollPeriodBase.b,
          td: this.payrollPeriodBase.c,
          gc: gc,
          wd: workingDate,
          u: this.summaryActive && summaryViewFirstWorkingDateSet ? 0 : u,
          r: r,
          y: this.payrollPeriodBase.e
        });
        summaryViewFirstWorkingDateSet = true;
      }
    }

    this._payrollService.insertTimeSheetEmpData(toAdd).pipe(untilDestroyed(this)).subscribe((invalidEmps) => {

        if (!this.summaryActive) {
          this.loadTimesheetGrid(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        }
        this.loadBulkViewData(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);

        this.loadAllUnsignedEmps(this.groupCode.a, this.payrollPeriodBase.b, this.payrollPeriodBase.c);
        if (!invalidEmps) {
          this._wizardService.updateFlag(2).pipe(untilDestroyed(this)).subscribe(data => {
            if (data) {
              this._wizardService.updateWizard(data);
            }
          });
          const title = this.transloco.translate('success');
          const message = this.transloco.translate('recordSuccessfullyAdded');
          showMsg({
            type: 'nonsticky',
            status: 'success',
            title: title,
            buttons: {},
            message: message,
            delay: '1000'
          });
        } else {
          const that = this;
          const title = this.transloco.translate('error');
          const buttonOk = this.transloco.translate('ok');
          const message = this.transloco.translate('alerts.cannotInsertBecauseEmployeeHasPaycard', {value: invalidEmps}, 'timesheetGrid');
          showMsg({
            type: 'sticky',
            status: 'error',
            title: title,
            buttons: {
              [buttonOk]: {
                action() {
                  if (toAdd.length != invalidEmps.length) {
                    that._wizardService.updateFlag(2).pipe(untilDestroyed(that)).subscribe(data => {
                      if (data) {
                        that._wizardService.updateWizard(data);
                      }
                    });
                  }
                }
              }
            },
            message: message,
            delay: '1500'
          });

        }
      },
      err => {
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        const message = this.transloco.translate('alerts.cannotAdd', {}, 'timesheetGrid');
        showMsg({
          type: 'sticky',
          status: 'error',
          title: title,
          buttons: {
            [buttonOk]: {
              action() {
                return;
              }
            }
          },
          message: message,
          delay: '1500'
        });
      });
  }

  unitsChange(event: any, emp: any) {
    emp.u = event;
    this._wizardService.updateDirtyStep(true);
  }

  rateChange(event: any, emp: any) {
    emp.r = event;
    this._wizardService.updateDirtyStep(true);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    // if (this.getTimeSheetGridDataBulkViewService) { this.getTimeSheetGridDataBulkViewService.unsubscribe(); }
    if (this.subscrption) {
      this.subscrption.unsubscribe();
    }
  }

  onPayCardFormLoaded($event: NgForm) {
    this.payCardImportForm = $event;
  }

  onClickImportPayCard() {
    this.payCardImportForm?.control.get('filePath')?.reset();
  }
}
