import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {RouterModule} from '@angular/router';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslocoModule} from '@ngneat/transloco';
import {DatatypePipe} from '@pipes/datatype.pipe';
import {FilterPipePipe} from '@pipes/filter-pipe.pipe';
import {LayoutFilterPipe} from '@pipes/layout-filter.pipe';
import {PayrollCategoryClassPipe} from '@pipes/payroll-category-class.pipe';
import {PayrollCategoryIconPipe} from '@pipes/payroll-category-icon.pipe';
import {ReplaceStringPipe} from '@pipes/replace-string.pipe';
import {SearchfilterPipe} from '@pipes/searchfilter.pipe';
import {BsCustomModalModule} from '@shared/bs-custom-modal/bs-custom-modal.module';
import {BackdropModule} from '@shared/components/backdrop/backdrop.module';
import {ModalCloseDirective} from '@shared/directives/modal-close.directive';
import {ChartsModule} from 'ng2-charts';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgxPipeFunctionModule} from 'ngx-pipe-function';
import {NgxSpinnerModule} from 'ngx-spinner';
import {UiSwitchModule} from 'ngx-ui-switch';
import {BackRoutingBtnComponent} from './components/back-routing-btn/back-routing-btn.component';
import {BackupComponent} from './components/backup/backup.component';
import {PaginateComponent} from './components/paginate/paginate.component';
import {ProcessOutputWindowComponent} from './components/process-output-window/process-output-window.component';
import {SearchBoxComponent} from './components/search-box/search-box.component';
import {SearchNavigationComponent} from './components/search-navigation/search-navigation.component';
import {SortingListComponent} from './components/sorting-list/sorting-list.component';
import {CustomMaxLengthDirective} from './directives/custom-max-length.directive';
import {CustomUsercodeValidationDirective} from './directives/custom-usercode-validation.directive';
import {DuplicateOrderDirective} from './directives/duplicate-order.directive';
import {PercentageCustomValidatorDirective} from './directives/percentage-custom-validator.directive';
import {AlertModalComponent} from './components/alert-modal/alert-modal.component';
import {NotificationBarComponent} from './components/notification-bar/notification-bar.component';
import {PasswordValidatorDirective} from '@shared/custom-validators/password-validator';


@NgModule({
  declarations: [
    BackRoutingBtnComponent,
    ModalCloseDirective,
    ReplaceStringPipe,
    CustomMaxLengthDirective,
    DuplicateOrderDirective,
    PercentageCustomValidatorDirective,
    SortingListComponent,
    FilterPipePipe,
    SearchfilterPipe,
    CustomUsercodeValidationDirective,
    DatatypePipe,
    LayoutFilterPipe,
    BackupComponent,
    PayrollCategoryIconPipe,
    SearchBoxComponent,
    SearchNavigationComponent,
    ProcessOutputWindowComponent,
    PayrollCategoryClassPipe,
    PaginateComponent,
    AlertModalComponent,
    NotificationBarComponent,
    PasswordValidatorDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MatRadioModule,
    BsDropdownModule,
    NgbNavModule,
    ProgressbarModule,
    AlertModule,
    ChartsModule,
    PopoverModule,
    TooltipModule,
    AccordionModule,
    ModalModule.forRoot(),
    TranslocoModule
  ],
    exports: [
        SortingListComponent,
        BackRoutingBtnComponent,
        FilterPipePipe,
        ReplaceStringPipe,
        CustomMaxLengthDirective,
        CustomUsercodeValidationDirective,
        DuplicateOrderDirective,
        PercentageCustomValidatorDirective,
        SearchfilterPipe,
        LayoutFilterPipe,
        BackupComponent,
        DatatypePipe,
        ModalCloseDirective,
        BsDropdownModule,
        NgbNavModule,
        UiSwitchModule,
        PayrollCategoryIconPipe,
        AlertModule,
        SearchBoxComponent,
        PopoverModule,
        TooltipModule,
        SearchNavigationComponent,
        AccordionModule,
        ModalModule,
        BackdropModule,
        ProcessOutputWindowComponent,
        PayrollCategoryClassPipe,
        TranslocoModule,
        BsCustomModalModule,
        NgxPipeFunctionModule,
        NotificationBarComponent,
        PasswordValidatorDirective
    ]
})
export class SharedModule {
}
