<ng-container *transloco="let translate; read: 'importLabor'">
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ImportLaborModal" aria-hidden="true" id="ImportLaborModal" data-bs-backdrop="static">
      <div class=" modal-dialog custom-modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{translate('title')}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <label for="interface" class="col-form-label">{{translate('companyInterface')}}</label>
                <select id="interface" name="interface" class="form-select" #interface="ngModel" [(ngModel)]="selectedInterface"
                        (change)="onInterfaceChange($event)" required>
                  <option *ngFor="let item of interfaceList" [value]="item.a">{{item.b}} - {{item.i}}</option>
                </select>
                <div *ngIf="(interface.dirty || interface.touched) && interface.invalid" class="invalid-feedback">
                  <span *ngIf="interface.errors.required">{{translate('validations.companyInterfaceIsRequired')}}</span>
                </div>
              </div>
            </div>
            <br />
            <ng-container *ngIf="!is_direct">
              <div class="row">
                <div class="col-12 wrap-item-center">
                  <input type="file" class="form-control" (click)="$event.target.value=null" (change)="onFileSelected($event)" name="filePath"
                         accept=".csv,.txt" id="file_path" #importFilePath>
                </div>
              </div>
            </ng-container>
            <br />
            <ng-container *ngIf="is_direct">
              <ng-container *ngIf="is_Harmony_tna">
                <div class="row">
                  <div class="col-12 wrap-item-center mb-3">
                    <div class="form-check">
                      <input type="checkbox" id="approvedTimesheet" name="approvedTimesheet" class="form-check-input" [checked]="onlyApprovedTimesheet === true"
                             [(ngModel)]="onlyApprovedTimesheet" [ngModelOptions]="{standalone: true}" />
                      <label for="approvedTimesheet" class="form-check-label">{{translate('onlyApprovedTimesheets')}}</label>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col-sm-6 d-flex align-items-center mb-3">
                  <label class="form-label">{{translate('importRecordsFrom')}}</label>
                </div>
                <div class="col-sm-6 d-flex align-items-center mb-3">
                  <select name="importFromPeriod" [(ngModel)]="importFromPeriod_" class="form-select">
                    <option value="0">{{translate('currentPayPeriod')}}</option>
                    <option value="1">{{translate('1payPeriodAgo')}}</option>
                    <option value="2">{{translate('2payPeriodsAgo')}}</option>
                    <option value="3">{{translate('3payPeriodsAgo')}}</option>
                    <option value="4">{{translate('4payPeriodsAgo')}}</option>
                  </select>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!is_direct && (uploadedFile == null || selectedInterface == null)">
              <i class="fas fa-hourglass-half"></i>&nbsp;<span transloco="process"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="errorModal" aria-hidden="true" id="errorModal">
    <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{translate('errorMessage')}}</th>
                <ng-container *ngIf="resultNumber == -1">
                  <th><span transloco="employeeNumber"></span></th>
                  <th>{{translate('categoryCode')}}</th>
                  <th>{{translate('workDate')}}</th>
                  <th><span transloco="units"></span></th>
                  <th><span transloco="rate"></span></th>
                  <th><span transloco="amount"></span></th>
                  <th>{{translate('dimensionCode1')}}</th>
                  <th>{{translate('dimensionCode2')}}</th>
                  <th>{{translate('dimensionCode3')}}</th>
                  <th>{{translate('dimensionCode4')}}</th>
                  <th>{{translate('dimensionCode5')}}</th>
                  <th>{{translate('dimensionCode6')}}</th>
                  <th>{{translate('departmentCode1')}}</th>
                  <th>{{translate('locationCode1')}}</th>
                </ng-container>
                <ng-container *ngIf="resultNumber == -2">
                  <th>{{translate('dimensionCaption')}}</th>
                  <th>{{translate('dimensionCode')}}</th>
                  <th>{{translate('dimensionName')}}</th>
                  <th>{{translate('dimensionRate')}}</th>
                  <th>{{translate('dimensionParentCode')}}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="errors">
                <tr *ngFor="let error of errors">
                  <td>
                    {{error.translatedError}}
                  </td>
                  <td>
                    {{error.b}}
                  </td>
                  <td>
                    {{error.c}}
                  </td>
                  <td>
                    {{error.w}}
                  </td>
                  <td>
                    {{error.u}}
                  </td>
                  <td>
                    {{error.r}}
                  </td>
                  <ng-container *ngIf="resultNumber == -1">
                    <td>
                      {{error.a}}
                    </td>
                    <td>
                      {{error.d1}}
                    </td>
                    <td>
                      {{error.d2}}
                    </td>
                    <td>
                      {{error.d3}}
                    </td>
                    <td>
                      {{error.d4}}
                    </td>
                    <td>
                      {{error.d5}}
                    </td>
                    <td>
                      {{error.d6}}
                    </td>
                    <td>
                      {{error.d}}
                    </td>
                    <td>
                      {{error.l}}
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"><span transloco="cancel"></span></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
