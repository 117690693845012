import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchfilter'
})
export class SearchfilterPipe implements PipeTransform {

  transform(value: any, args?: any, keys?: any): any {
    if (args === undefined || args === null || args == '' || value === undefined || value === null) {
      return value;
    }
    else if (keys != null) {
      return value.filter(item => {
        for (let k in keys) {
          if ((typeof item[keys[k]] === 'string' || item[keys[k]] instanceof String) && (item[keys[k]].toLowerCase().indexOf(args.toLowerCase()) > -1)/*(item[key.indexOf[args[0]]] !== -1)*/) {
            return true;
          }
        }
      })
    }
    else {
      return value.filter(item => {
        for (const key in item) {
          if ((typeof item[key] === 'string' || item[key] instanceof String) && (item[key].toLowerCase().indexOf(args.toLowerCase()) > -1)
            /*(item[key.indexOf[args[0]]] !== -1)*/) {
            return true;
          }
        }
      });
    }

  }
}
