import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PaycardServiceService} from '@harmony-modules/payroll/services/admin-setup/paycard-service.service';
import {LaborService} from '@harmony-modules/payroll/services/payroll-setup/labor.service';
import {ILaborImportError, ILaborImportParam, ILaborImportResult, IlaborOutputWithSession} from '@shared/models/ilabor';
import {ILocString} from '@shared/models/iLocalization';
import {IPaycard} from '@shared/models/ipaycard';
import {IPayrollProcessPeriod} from '@shared/models/ipayroll-group';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {ShowMessageService} from '@services/show-message.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BsCustomModalService} from '@shared/bs-custom-modal/bs-custom-modal.service';
import {filter} from 'rxjs/operators';

declare var $: any;
declare var showMsg: any;

@UntilDestroy()
@Component({
  selector: 'app-import-labor',
  templateUrl: './import-labor.component.html',
  styleUrls: ['./import-labor.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'importLabor', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class ImportLaborComponent implements OnInit {
  @ViewChild('importFilePath') importFilePath: ElementRef<HTMLInputElement>;
  is_direct = false;
  is_xml_type = false;
  is_Harmony_tna = false;
  onlyApprovedTimesheet = false;
  uploadedFile: File = null;
  fileName = '';
  fileContent = '';
  interfaceList: IPaycard[] = [];
  selectedInterface: number;
  laborImportResult: ILaborImportResult;
  resultNumber: number;
  errors: ILaborImportError[];
  fileContentWithParams: ILaborImportParam = {
    fileContent: null,
    interfaceID: null,
    seqNo: null,
    from_date: null,
    to_date: null,
    year: null,
    prlgID: null,
    approved: null,
    interfaceCode: null,
    ImportFromPeriod: 0
  };
  importFromPeriod_ = 0;

  @Input() payrollPeriodBase: IPayrollProcessPeriod;
  @Input() prlg_code: number;
  @Output() output = new EventEmitter<IlaborOutputWithSession>();

  constructor(
    private _paycardService: PaycardServiceService,
    private _laborService: LaborService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private showMessage: ShowMessageService,
    private modalService: BsCustomModalService
  ) {
  }

  ngOnInit() {
    this._paycardService.getPaycardInterfaceList_V1().pipe(untilDestroyed(this)).subscribe(
      data => {
        if (data != undefined && data != null) {
          this.interfaceList = data;
          this.interfaceList = this.interfaceList.filter(i => i.c == 2);
          this.selectedInterface = typeof (this.interfaceList.find(i => i.h == 1)) !== 'undefined' ? this.interfaceList.find(i => i.h == 1).a : null;
          this.setDirectFlag();
          this.setXMLflag();
          this.setHarmonyTnaflag();
        }

      });

    this.modalService.modalClosed$.pipe(filter((res) => res === 'ImportLaborModal'), untilDestroyed(this))
      .subscribe(() => this.resetFile());
  }

  setXMLflag() {
    if (this.selectedInterface != null) {
      if (this.interfaceList.find(i => i.a == this.selectedInterface).d == 3) {
        this.is_xml_type = true;
      } else {
        this.is_xml_type = false;
      }
    }
  }

  setDirectFlag() {
    if (this.selectedInterface != null) {
      if (this.interfaceList.find(i => i.a == this.selectedInterface).j == 1) {
        this.is_direct = true;
      } else {
        this.is_direct = false;
      }
    }

  }

  setHarmonyTnaflag() {
    if (this.selectedInterface != null) {
      if (this.interfaceList.find(i => i.a == this.selectedInterface).b == 'L_HRM_TNA') {
        this.is_Harmony_tna = true;
      } else {
        this.is_Harmony_tna = false;
      }
    }
  }

  onInterfaceChange(event: any): void {
    this.setDirectFlag();
    this.setXMLflag();
    this.setHarmonyTnaflag();
  }

  private resetFile() {
    if (this.importFilePath?.nativeElement) {
      this.importFilePath.nativeElement.value = '';
    }
    this.fileContent = null;
    this.uploadedFile = null;
    this.fileName = null;
  }

  onFileSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.fileContent = '';
      this.uploadedFile = event.target.files[0];
      this.fileName = this.uploadedFile.name;
      const reader = new FileReader();
      reader.onloadend = () => {
        if (this.is_xml_type) {
          this.fileContent = reader.result.toString();
        } else {
          const lines = reader.result.toString().split('\n');
          lines.forEach(item => {
            this.fileContent = this.fileContent + item + '@@';
          });
        }
      };
      reader.readAsText(this.uploadedFile);
    }
  }

  processFile() {
    let p = 0;
    if (this.onlyApprovedTimesheet) {
      p = 1;
    }

    let interfaceCode = '';
    if (this.selectedInterface != null) {
      interfaceCode = this.interfaceList.find(i => i.a == this.selectedInterface).b;
    }

    if (this.is_xml_type) {

      this._laborService.processImportLaborXML(this.fileContent, this.selectedInterface, this.payrollPeriodBase.f, this.payrollPeriodBase.b, this.payrollPeriodBase.c, this.payrollPeriodBase.e, this.prlg_code, p, interfaceCode).pipe(untilDestroyed(this)).subscribe(
        data => {
          if (data != undefined && data != null) {
            this.handleImportResult(data);
          }
        },
        err => {
          const title = this.transloco.translate('error');
          const buttonOk = this.transloco.translate('ok');
          const message = this.transloco.translate('alerts.errorOccurred', { value: err }, 'importLabor');
          showMsg({
            type: 'sticky',
            status: 'error',
            title,
            buttons: {
              [buttonOk]: {
                action() {
                  return;
                }
              }
            },
            message
          });
        }
      );
    } else {
      this.fileContentWithParams.fileContent = this.fileContent;
      this.fileContentWithParams.interfaceID = this.selectedInterface;
      this.fileContentWithParams.seqNo = this.payrollPeriodBase.f;
      this.fileContentWithParams.from_date = this.payrollPeriodBase.b;
      this.fileContentWithParams.to_date = this.payrollPeriodBase.c;
      this.fileContentWithParams.year = this.payrollPeriodBase.e;
      this.fileContentWithParams.prlgID = this.prlg_code;
      this.fileContentWithParams.approved = p;
      this.fileContentWithParams.interfaceCode = interfaceCode;
      this.fileContentWithParams.ImportFromPeriod = this.importFromPeriod_;

      if (this.is_direct && !this.is_Harmony_tna) {
        this._laborService.processImportLaborSeamless(this.fileContentWithParams).pipe(untilDestroyed(this)).subscribe(
          data => {
            if (data.ConnectionResult.WasSuccessful) {
              if (data != undefined && data != null) {
                this.handleImportResult(data);
              }
            } else {
              const title = this.transloco.translate('error');
              const buttonOk = this.transloco.translate('ok');
              let message = data?.ConnectionResult?.Message;

              if (message == undefined) {
                message = this.showMessage.translateErrorMessage(data.ConnectionResult.ErrorMessage);
              }

              showMsg({
                type: 'sticky',
                status: 'error',
                title,
                buttons: {
                  [buttonOk]: {
                    action() {
                      return;
                    }
                  }
                },
                message
              });
            }
          },
          err => {
            const title = this.transloco.translate('error');
            const buttonOk = this.transloco.translate('ok');
            const message = this.transloco.translate('alerts.errorOccurred', { value: err }, 'importLabor');
            showMsg({
              type: 'sticky',
              status: 'error',
              title,
              buttons: {
                [buttonOk]: {
                  action() {
                    return;
                  }
                }
              },
              message
            });
          }
        );
      } else {
        this._laborService.processImportLabor(this.fileContentWithParams).pipe(untilDestroyed(this)).subscribe(
          data => {
            if (data != undefined && data != null) {
              this.handleImportResult(data);
            }
          },
          err => {
            const title = this.transloco.translate('error');
            const buttonOk = this.transloco.translate('ok');
            const message = this.transloco.translate('alerts.errorOccurred', {value: err}, 'importLabor');
            showMsg({
              type: 'sticky',
              status: 'error',
              title,
              buttons: {
                [buttonOk]: {
                  action() {
                    return;
                  }
                }
              },
              message
            });
          }
        );
      }
    }
  }

  handleImportResult(data: any) {
    this.laborImportResult = data;

    this.resultNumber = this.laborImportResult.result;
    this.errors = this.laborImportResult.errors;  // ???? errors are ILaborImportError[] and ILaborImportError.e is ILocString and should translate  ????
    this.errors.forEach((element) => {
      element.translatedError = this.showMessage.translateErrorMessage(element.e as unknown as ILocString);
    });
    if (this.resultNumber == -1 || this.resultNumber == -2) { // there are errors
      this.modalToggle();
    } else { // No error
      if (this.resultNumber > 0) {
        this.resetFile();
        this.modalService.closeByTarget('ImportLaborModal');
        this.output.emit(this.laborImportResult.output);
        const title = this.transloco.translate('success');
        const buttonOk = this.transloco.translate('ok');
        const message = this.transloco.translate('alerts.successfullyImported', {}, 'importLabor');
        showMsg({
          type: 'sticky',
          status: 'success',
          title,
          buttons: {
            [buttonOk]: {
              action() {
                return;
              }
            }
          },
          message
        });
      } else { // No process
        const title = this.transloco.translate('error');
        const buttonOk = this.transloco.translate('ok');
        let message = this.transloco.translate('alerts.nothingProcessed', {}, 'importLabor');
        message += '\n' + this.showMessage.translateErrorMessage(data[0]?.e);
        showMsg({
          type: 'sticky',
          status: 'error',
          title,
          buttons: {
            [buttonOk]: {
              action() {
                return;
              }
            }
          },
          message
        });
      }
    }

    this.modalService.closeByTarget('ImportLaborModal');
  }

  modalToggle(): void {
    // $('#errorModal').modal({
    //  'toggle': 'true'
    // });
    $('#errorModal').modal('show');
  }

  onSubmit(form: NgForm) {
    this.processFile();
  }
}
