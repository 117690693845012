import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {
    CompanyData,
    ForgotPasswordModel,
    GetTokenResponseModel,
    JwtDecodedModel,
    RequestedUserModel,
    TokenRequestModel,
    TwoFaPhoneNumberModel,
    TwoFaVerifyPhoneNumberModel
} from '@shared/models/auth.models';
import {User} from '@shared/models/user';
import {LanguageService} from '@services/language.service';
import {CookieService} from 'ngx-cookie-service';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AccessControlService} from 'src/app/account/access-control/services/access-control.service';
import {SignalRService} from '@services/singalR/signal-r.service';
import {EmployeeListService} from '@harmony-modules/payroll/services/employee/employee-list.service';
import {PayrollCategoryService} from '@harmony-modules/payroll/services/payroll-setup/payroll-category.service';
import {SalaryProcessMessageService} from '@services/salary-process-message.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {EnvService} from '@services/env.service';
import {v4 as uuidv4} from 'uuid';

declare var $: any;

export interface AuthBroadcastMessage {
    sessionId: string;
    message: 'login' | 'logout' | 'hmacLogout';
}

@Injectable()
export class AuthenticationService implements OnDestroy {

    public sessionId = uuidv4();
    public readonly authBroadcast: BroadcastChannel = null;

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private loggedIn = new BehaviorSubject<boolean>(false);
    private laborEnable = new BehaviorSubject<boolean>(false);
    private unionEnable = new BehaviorSubject<boolean>(false);
    private basicLaborEnable = new BehaviorSubject<boolean>(false);
    private certifiedPayrollEnable = new BehaviorSubject<boolean>(false);

    showLabor = this.laborEnable.asObservable();
    showUnion = this.unionEnable.asObservable();
    showBasicLabor = this.basicLaborEnable.asObservable();
    showCertifiedPayroll = this.certifiedPayrollEnable.asObservable();

    private readonly httpBackend: HttpClient;

    get isLoggedIn() {
        return this.loggedIn.asObservable(); // {2}
    }

    // private headers = new HttpHeaders({'content-type': 'application/json', 'Accept': 'application/json'});

    constructor(
        private http: HttpClient,
        private httpBackendInstance: HttpBackend,
        private accessControl: AccessControlService,
        private router: Router,
        private cookieService: CookieService,
        private languageService: LanguageService,
        private signalRService: SignalRService,
        private injector: Injector,
        private envService: EnvService,
        private salaryProcessMessageService: SalaryProcessMessageService
    ) {
        this.httpBackend = new HttpClient(this.httpBackendInstance);
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.authBroadcast = new BroadcastChannel('auth');
    }

    public get currentUserValue(): User {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        return this.currentUserSubject.value;
    }

    verifyLogin(email, password) {
        const isRemember2Fa = this.cookieService.check('2fa');
        return this.httpBackend.post<RequestedUserModel>(`${this.envService.baseApiUrl}account/verifylogin`, {email, password, isRemember2Fa})
            .pipe(
                tap(response => {
                    const user: User = {id: response?.user.userId, token: '', email, password: '', selectedCompany: null};
                    this.currentUserSubject.next(user);
                })
            );
    }

    updateLabor(s: boolean) {
        this.laborEnable.next(s);
    }

    updateUnion(s: boolean) {
        this.unionEnable.next(s);
    }

    updateBasicLabor(s: boolean) {
        this.basicLaborEnable.next(s);
    }

    updateCertifiedPayroll(s: boolean) {
        this.certifiedPayrollEnable.next(s);
    }

    getToken(selectedCompany: CompanyData, payRollYear: number, userPassword: string, twoFaCode: string) {
        const current = this.currentUserSubject.value;
        const isRemember2Fa = this.cookieService.check('2fa');
        const params: TokenRequestModel = {
            payRollYear,
            email: current.email,
            userId: current.id,
            company: selectedCompany,
            password: userPassword,
            storage: 0
        };
        return this.httpBackend.post<GetTokenResponseModel>(`${this.envService.baseApiUrl}account/token`, params)
            .pipe(tap(tokenResponse => !tokenResponse?.otpRequired && this.setUser(tokenResponse)));
    }

    setUser(tokenResponse: GetTokenResponseModel, externalLogin = false) {
        const decodedToken = this.decodeToken(tokenResponse.token);
        const user: User = {
            id: decodedToken.sid,
            email: decodedToken.unique_name,
            token: tokenResponse.token,
            selectedCompany: {
                id: decodedToken.CompanyId,
                modules: decodedToken?.modules,
                subModules: decodedToken?.subModules,
                companyName: decodedToken.CompanyName,
                clientId: decodedToken.ClientCode,
                clientCode: decodedToken.ClientCode
            }
        };
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('permissions', JSON.stringify(tokenResponse?.permission));
        this.currentUserSubject.next(user);
        this.loggedIn.next(true);
        this.languageService.setLanguage(tokenResponse?.languageCode);
        if (this.authBroadcast && !externalLogin) {
            const broadcast: AuthBroadcastMessage = {sessionId: this.sessionId, message: 'login'};
            this.authBroadcast.postMessage(broadcast);
        }
    }

    private decodeToken(token: string): JwtDecodedModel {
        const jwtHelper = new JwtHelperService();
        const tokenDecoded = jwtHelper.decodeToken(token);
        let modules = tokenDecoded['http://schemas.xmlsoap.org/ws/2009/09/identity/claims/modules'];
        if (!(modules instanceof Array)) {
            modules = [modules];
            modules = modules?.map(m => +m);
        }
        let subModules = tokenDecoded['http://schemas.xmlsoap.org/ws/2009/09/identity/claims/submodules'];
        if (!(subModules instanceof Array)) {
            subModules = [subModules];
        }

        return ({
            ...tokenDecoded,
            sid: tokenDecoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'],
            modules,
            subModules
        });
    }

    logout() {
        if (this.loggedIn.getValue()) {
            const broadcast: AuthBroadcastMessage = {sessionId: this.sessionId, message: 'logout'};
            this.authBroadcast.postMessage(broadcast);
        }
        this.accessControl.resetPermissions();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('permissions');
        this.currentUserSubject.next(null);
        this.loggedIn.next(false);
        this.signalRService.disconnect();
        this.salaryProcessMessageService.destroy();
        this.router.navigate(['/account/login']).then(() => this.clearData());
    }

    hmacLogout() {
        this.accessControl.resetPermissions();
        this.currentUserSubject.next(null);
        this.loggedIn.next(false);
        this.signalRService.disconnect();
        this.salaryProcessMessageService.destroy();
        this.router.navigate(['/account/login']).then(() => this.clearData());
    }

    clearData() {
        this.injector.get(EmployeeListService).resetData();
        this.injector.get(PayrollCategoryService).resetData();
    }

    forgotPassword(email) {
        const passwordReset: ForgotPasswordModel = {email: email?.email, baseUrl: null};
        return this.http.post(`account/forgetpassword`, passwordReset);
    }

    resetPassword(payload) {
        return this.http.post(`account/resetpassword`, payload);
    }

    // 2FA
    add2FaPhoneNumber(payload: TwoFaPhoneNumberModel) {
        return this.http.post(`account/addphonenumber`, payload);
    }

    remove2FaPhoneNumber(payload: TwoFaPhoneNumberModel) {
        return this.http.post(`account/removephonenumber`, payload)
            .pipe(tap(() => this.cookieService.check('2fa') && this.cookieService.delete('2fa')));
    }

    add2FaEmail() {
        return this.http.get<boolean>(`account/AddEmail`);
    }

    remove2FaEmail() {
        return this.http.get<boolean>(`account/DeactivateEmail`);
    }

    verify2FaEmail(payload: TwoFaVerifyPhoneNumberModel) {
        return this.http.post(`account/VerifyEmail`, payload);
    }

    externalAuthentication(token: string) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
        return this.httpBackend.post<GetTokenResponseModel>(`${this.envService.baseApiUrl}account/external`, {}, {headers})
            .pipe(
                tap({
                    next: user => {
                        if (!user?.otpRequired) {
                            this.setUser(user, true);
                        }
                    }
                })
            );
    }

    ngOnDestroy() {
        if (this.authBroadcast) {
            this.authBroadcast.close();
        }
    }
}
