<ng-container *transloco="let translate; read: 'costModal'">
  <div class="modal fade" id="costModal" tabindex="-1" role="dialog" aria-labelledby="costModalLabel" aria-hidden="true" data-bs-backdrop="static">
    <form [formGroup]="costForm" (ngSubmit)="onCostFormSubmit()">
      <div class="modal-dialog modal-xl  modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{translate('title')}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center mb-2">
              <div class="col-3">
                <img class="img-fluid avatar-xl float-end border rounded-circle" src="/assets/images/avatar/Male-Avatar.png"/>
              </div>
              <div class="col-9">
                <p><b>{{empNo}} - {{empN}}</b></p>
                <p><b><i class="fas fa-calendar"></i> {{sdate}} - {{edate}}</b></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="table table-scroll">
                  <thead>
                    <tr>
                      <th class="text-center" style="width:3%;"></th>
                      <th class="text-center">{{translate('labor')}}</th>
                      <th class="text-center" *ngIf="is_working_date"><span transloco="date"></span></th>
                      <th class="text-center">{{translate('category')}}</th>
                      <th class="text-center"><span transloco="units"></span></th>
                      <th class="text-center"><span transloco="rate"></span></th>
                      <th class="text-center"><span transloco="amount"></span></th>
                      <ng-container *ngFor="let dim of laborSetupList">
                        <th class="text-center" *ngIf="dim?.LABOR_DIM_IS_ACTIVE_FLG==1">
                          {{dim.LABOR_DIM_CAPTION}}
                        </th>
                      </ng-container>
                      <th class="text-center" *ngIf="sys_Pref?.u == 1">{{translate('agrDate')}}</th>
                      <th class="text-center"><span transloco="overwrite"></span></th>
                    </tr>
                  </thead>
                  <tbody formArrayName="cost_grid">
                    <tr *ngFor="let s of cost_grid.controls; let i = index" [formGroupName]="i">
                      <td class="text-center" style="width: 3%;">
                        <div>
                          <ng-container *ngIf="s.value.status == -2; else other_content">
                            <i class="fas fa-edit"></i>
                          </ng-container>
                          <ng-template #other_content>
                            <i class="fa fa-check" [ngClass]="getClassesForCostModalIcon(s.value.status)"></i>
                          </ng-template>
                        </div>
                      </td>
                      <td class="text-center">
                        <select formControlName="timesheet" class="w-100" (change)="onValueEdit(i)">
                          <option [value]="null"></option>
                          <option *ngFor="let item of laborTimesheetList" [ngValue]="item.ID_TIMESHEET">{{item?.laborRec}}</option>
                        </select>
                      </td>
                      <td class="text-center" *ngIf="is_working_date">
                        <input type="date" formControlName="date" [attr.data-date]="testdate[i] | date: (sys_Pref?.df || 'yyyy-MM-dd')" class="w-100"
                               (change)="onDateValueEdit(i, s.value.date)" style="overflow-x: auto;" />
                        <!--[attr.data-date]="s.value.date | date: (sys_Pref?.df || 'yyyy-MM-dd')"-->
                      </td>
                      <td class="text-center">
                        <select formControlName="category" class="w-100" (change)="onCategoryEdit(i)">
                          <option *ngFor="let cat of payrollCategories_copy" [ngValue]="cat.CAT_DTL_CODE">{{cat.CAT_DTL_USER_CODE}} {{cat.CAT_DTL_NAME}}</option>
                        </select>
                      </td>
                      <td class="text-center">
                        <input type="number" formControlName="units" class="form-control form-control-sm" (input)="onValueEdit(i)" />
                      </td>
                      <td class="text-center">
                        <input type="number" formControlName="rate" class="form-control form-control-sm" (input)="onValueEdit(i)" />
                      </td>
                      <td class="text-center">
                        <input type="number" formControlName="amount" class="form-control form-control-sm" (input)="onValueEdit(i)" />
                      </td>
                      <td *ngIf="laborSetupList[0]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="dim1" class="form-select form-select-sm" (change)="onDimensionCahnge($event, i, laborSetupList[0])">
                          <option [value]="null"></option>
                          <option *ngFor="let d1 of array_of_dimension[i].list1" [value]="d1.ID_DIMENSION"
                                  [ngClass]="{'inactive-dim': d1.DIM_IS_ACTIVE_FLG == 0}">
                            {{d1.DIM_NAME}}  {{d1.DIM_DESCRIPTION}}
                          </option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[1]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="dim2" class="form-select form-select-sm" (change)="onDimensionCahnge($event, i, laborSetupList[1])">
                          <option [value]="null"></option>
                          <option *ngFor="let d2 of array_of_dimension[i].list2" [value]="d2.ID_DIMENSION"
                                  [ngClass]="{'inactive-dim': d2.DIM_IS_ACTIVE_FLG == 0}">
                            {{d2.DIM_NAME}}  {{d2.DIM_DESCRIPTION}}
                          </option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[2]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="dim3" class="form-select form-select-sm" (change)="onDimensionCahnge($event, i, laborSetupList[2])">
                          <option [value]="null"></option>
                          <option *ngFor="let d3 of array_of_dimension[i].list3" [value]="d3.ID_DIMENSION"
                                  [ngClass]="{'inactive-dim': d3.DIM_IS_ACTIVE_FLG == 0}">
                            {{d3.DIM_NAME}}  {{d3.DIM_DESCRIPTION}}
                          </option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[3]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="dim4" class="form-select form-select-sm" (change)="onDimensionCahnge($event, i, laborSetupList[3])">
                          <option [value]="null"></option>
                          <option *ngFor="let d4 of array_of_dimension[i].list4" [value]="d4.ID_DIMENSION"
                                  [ngClass]="{'inactive-dim': d4.DIM_IS_ACTIVE_FLG == 0}">
                            {{d4.DIM_NAME}}  {{d4.DIM_DESCRIPTION}}
                          </option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[4]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="dim5" class="form-select form-select-sm" (change)="onDimensionCahnge($event, i, laborSetupList[4])">
                          <option [value]="null"></option>
                          <option *ngFor="let d5 of array_of_dimension[i].list5" [value]="d5.ID_DIMENSION"
                                  [ngClass]="{'inactive-dim': d5.DIM_IS_ACTIVE_FLG == 0}">
                            {{d5.DIM_NAME}} {{d5.DIM_DESCRIPTION}}
                          </option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[5]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="dim6" class="form-select form-select-sm" (change)="onDimensionCahnge($event, i, laborSetupList[5])">
                          <option [value]="null"></option>
                          <option *ngFor="let d6 of array_of_dimension[i].list6" [value]="d6.ID_DIMENSION"
                                  [ngClass]="{'inactive-dim': d6.DIM_IS_ACTIVE_FLG == 0}">
                            {{d6.DIM_NAME}} {{d6.DIM_DESCRIPTION}}
                          </option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[6]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="dep" class="form-select form-select-sm" (change)="onValueEdit(i)">
                          <option [value]="null"></option>
                          <option *ngFor="let dep of departmentList" [value]="dep.i">{{dep.n}}</option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[7]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <select formControlName="loc" class="form-select form-select-sm" (change)="onValueEdit(i)">
                          <option [value]="null"></option>
                          <option *ngFor="let loc of locationList" [value]="loc.ID">{{loc.Code}} {{loc.Name}}</option>
                        </select>
                      </td>
                      <td *ngIf="laborSetupList[8]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <input type="text" formControlName="txt1" class="form-control form-control-sm" (change)="onValueEdit(i)" />
                      </td>
                      <td *ngIf="laborSetupList[9]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <input type="text" formControlName="txt2" class="form-control form-control-sm" (change)="onValueEdit(i)" />
                      </td>
                      <td *ngIf="laborSetupList[10]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <input type="text" formControlName="txt3" class="form-control form-control-sm" (change)="onValueEdit(i)" />
                      </td>
                      <td *ngIf="laborSetupList[11]?.LABOR_DIM_IS_ACTIVE_FLG == 1" class="text-center">
                        <input type="text" formControlName="txt4" class="form-control form-control-sm" (change)="onValueEdit(i)" />
                      </td>
                      <td *ngIf="sys_Pref?.u == 1" class="text-center">
                        <select formControlName="id_union_agg" class="form-select form-select-sm" (change)="onValueEdit(i)">
                          <option [value]="null"></option>
                          <option *ngFor="let item of unionAgrmntAry[i]" [ngValue]="item.ID_AGREEMENT"
                                  [ngClass]="{'inactive-agr': !item.AGREEMENT_IS_ACTIVE_FLG}">
                            {{item?.AGREEMENT_DATE | date: (sys_Pref?.df || 'yyyy-MM-dd')}}
                          </option>
                        </select>
                      </td>
                      <td class="text-center">
                        <div class="form-check">
                          <input type="checkbox" id="import{{i}}" class="custom-checkbox" formControlName="overwrite" (change)="onOverwriteCheck($event, i)" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit"><i class="fas fa-hourglass-half"></i>&nbsp;<span transloco="process"></span></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
