<header id="page-topbar" *transloco="let translate; read: 'header'">
  <app-notification-bar></app-notification-bar>
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box px-3 px-md-2 px-lg-1">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/harmony/harmony-logo.svg" alt="" height="45">
          </span>
          <span class="logo-lg">
            <img src="assets/images/harmony/harmony-logo-flat.svg" alt="" height="45">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/harmony/harmony-logo.svg" alt="" height="45">
          </span>
          <span class="logo-lg">
            <img src="assets/images/harmony/harmony-logo-flat.svg" alt="" height="45">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <!--      <form class="app-search d-none d-xl-block">-->
      <!--        <div class="position-relative">-->
      <!--          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | transloco}}">-->
      <!--          <span class="bx bx-search-alt"></span>-->
      <!--        </div>-->
      <!--      </form>-->

      <!--      <div class="dropdown-mega d-none d-lg-block ms-2" ngbDropdown>-->
      <!--        <button type="button" class="btn header-item" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="false"-->
      <!--                aria-expanded="false">-->
      <!--          {{ 'HEADER.MEGA_MENU' | transloco}}-->
      <!--          <i class="mdi mdi-chevron-down"></i>-->
      <!--        </button>-->
      <!--        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>-->
      <!--          <div class="row">-->
      <!--            <div class="col-sm-8">-->

      <!--              <div class="row">-->
      <!--                <div class="col-md-4">-->
      <!--                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | transloco}}</h5>-->
      <!--                  <ul class="list-unstyled megamenu-list">-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                  </ul>-->
      <!--                </div>-->

      <!--                <div class="col-md-4">-->
      <!--                  <h5 class="font-size-14 mt-0">{{ 'HEADER.APPLICATIONS.TITLE' | transloco}}</h5>-->
      <!--                  <ul class="list-unstyled megamenu-list">-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.ECOMMERCE' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CALENDAR' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.EMAIL' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.PROJECTS' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.TASKS' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CONTACTS' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                  </ul>-->
      <!--                </div>-->

      <!--                <div class="col-md-4">-->
      <!--                  <h5 class="font-size-14 mt-0">{{ 'HEADER.EXTRA_PAGES.TITLE' | transloco}}</h5>-->
      <!--                  <ul class="list-unstyled megamenu-list">-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.LIGHT_SIDEBAR' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMPACT_SIDEBAR' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.MAINTENANCE' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMING SOON' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.TIMELINE' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.FAQ' | transloco}}</a>-->
      <!--                    </li>-->

      <!--                  </ul>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="col-sm-4">-->
      <!--              <div class="row">-->
      <!--                <div class="col-sm-6">-->
      <!--                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | transloco}}</h5>-->
      <!--                  <ul class="list-unstyled megamenu-list">-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | transloco}}</a>-->
      <!--                    </li>-->
      <!--                  </ul>-->
      <!--                </div>-->

      <!--                <div class="col-sm-5">-->
      <!--                  <div>-->
      <!--                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
                aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
             aria-labelledby="page-header-search-dropdown">

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!--      <div class="dropdown d-inline-block" ngbDropdown>-->
      <!--        &lt;!&ndash;suppress XmlDuplicatedId &ndash;&gt;-->
      <!--        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>-->
      <!--          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span-->
      <!--          class="ms-1">{{countryName}}</span>-->
      <!--          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">-->
      <!--          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>-->
      <!--        </button>-->
      <!--        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>-->
      <!--          &lt;!&ndash; item&ndash;&gt;-->
      <!--          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"-->
      <!--             (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">-->
      <!--            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span-->
      <!--            class="align-middle">{{item.text}}</span>-->
      <!--          </a>-->
      <!--          &lt;!&ndash; item&ndash;&gt;-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>-->
      <!--        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>-->
      <!--          <i class="bx bx-customize"></i>-->
      <!--        </button>-->
      <!--        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>-->
      <!--          <div class="px-lg-2">-->
      <!--            <div class="row no-gutters">-->
      <!--              <div class="col">-->
      <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
      <!--                  <img src="assets/images/brands/github.png" alt="Github">-->
      <!--                  <span>GitHub</span>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--              <div class="col">-->
      <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
      <!--                  <img src="assets/images/brands/bitbucket.png" alt="bitbucket">-->
      <!--                  <span>Bitbucket</span>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--              <div class="col">-->
      <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
      <!--                  <img src="assets/images/brands/dribbble.png" alt="dribbble">-->
      <!--                  <span>Dribbble</span>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--            <div class="row no-gutters">-->
      <!--              <div class="col">-->
      <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
      <!--                  <img src="assets/images/brands/dropbox.png" alt="dropbox">-->
      <!--                  <span>Dropbox</span>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--              <div class="col">-->
      <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
      <!--                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">-->
      <!--                  <span>Mail Chimp</span>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--              <div class="col">-->
      <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
      <!--                  <img src="assets/images/brands/slack.png" alt="slack">-->
      <!--                  <span>Slack</span>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <!--      <div class="dropdown d-inline-block" ngbDropdown>-->
      <!--        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"-->
      <!--                ngbDropdownToggle>-->
      <!--          <i class="bx bx-bell"></i>-->
      <!--          <span class="badge bg-danger rounded-pill">3</span>-->
      <!--        </button>-->
      <!--        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"-->
      <!--             aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>-->
      <!--          <div class="p-3">-->
      <!--            <div class="row align-items-center">-->
      <!--              <div class="col">-->
      <!--                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | transloco}} </h6>-->
      <!--              </div>-->
      <!--              <div class="col-auto">-->
      <!--                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | transloco}}</a>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <ngx-simplebar style="position: relative; height: 230px;">-->
      <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <div class="avatar-xs me-3">-->
      <!--                  <span class="avatar-title bg-primary rounded-circle font-size-16">-->
      <!--                    <i class="bx bx-cart"></i>-->
      <!--                  </span>-->
      <!--                </div>-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | transloco}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | transloco}}</p>-->
      <!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>-->
      <!--                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | transloco}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | transloco}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | transloco}}</p>-->
      <!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |-->
      <!--                      translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <div class="avatar-xs me-3">-->
      <!--                  <span class="avatar-title bg-success rounded-circle font-size-16">-->
      <!--                    <i class="bx bx-badge-check"></i>-->
      <!--                  </span>-->
      <!--                </div>-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | transloco}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | transloco}}</p>-->
      <!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |-->
      <!--                      translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | transloco}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | transloco}}</p>-->
      <!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |-->
      <!--                      translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--          </ngx-simplebar>-->
      <!--          <div class="p-2 border-top d-grid">-->
      <!--            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">-->
      <!--              <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{{-->
      <!--              'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>-->
      <!--            </a>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="dropdown d-inline-block" ngbDropdown (openChange)="onProcessQueueDropDownClose($event)">
        <button type="button" class="btn header-item noti-icon" id="page-header-background-process-dropdown" ngbDropdownToggle (click)="onOpenProcessQueue()">
          <i class='bx bx-loader-alt' [ngClass]="{'bx-spin': payrollProcessProgress?.status === 'InProgress'}"></i>
          <span
            class="badge rounded-pill"
            [ngClass]="{
              'bg-danger': payrollProcessProgress?.status === 'Failed',
              'bg-success': payrollProcessProgress?.status === 'Completed',
              'bg-warning': payrollProcessProgress?.status === 'InProgress',
              'bg-primary': payrollProcessProgress?.status === 'Queued'
            }"
            *ngIf="showPayrollProgressBadge"
          >&nbsp;</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <ng-container *ngIf="backgroundProcessPopDownShow">
            <app-process-in-progress-pop></app-process-in-progress-pop>
          </ng-container>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <!--suppress XmlDuplicatedId -->
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <span class="d-none d-xl-inline-block me-2">{{userName}}</span>
          <img class="rounded-circle header-profile-user" src="assets/images/avatar/Male-Avatar.png" alt="Header Avatar">
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <!--          <a class="dropdown-item" routerLink="/contacts/profile">-->
          <!--            <i class="bx bx-user font-size-16 align-middle me-1"></i>{{ translate('user.profile') }}-->
          <!--          </a>-->
          <!--          <a class="dropdown-item">-->
          <!--            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ translate('user.wallet') }}-->
          <!--          </a>-->
          <a class="dropdown-item d-block" routerLink="/usersetup/UserSettings">
            <!--            <span class="badge bg-success float-end">11</span>-->
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{translate('user.settings') }}
          </a>
          <!--          <a class="dropdown-item">-->
          <!--            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>{{ translate('user.lockScreen') }}-->
          <!--          </a>-->
          <a class="dropdown-item py-2" href="https://docs.paymatesoftware.com/payroll" target="_blank">
            <i class="bx bx-help-circle font-size-16 align-middle"></i>&nbsp;<span transloco="help"></span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" (click)="logout()" role="button">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{{ translate('user.logout') }}
          </a>
        </div>
      </div>

      <!--      <div class="dropdown d-inline-block">-->
      <!--        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">-->
      <!--          <i class="bx bx-cog"></i>-->
      <!--        </button>-->
      <!--      </div>-->

    </div>
  </div>
</header>
<ng-container *transloco="let translate; read: 'payrollProcess'">
  <app-process-output-window [title]="translate('outputCurrentProcessPeriod') + ' ['+ processSummary?.processPeriod + ']'" *ngIf="processSummary"
                             (close)="processSummary = null">
    <div class="row mb-1">
      <div class="col-auto">
        <span class="badge bg-success rounded-pill"><i class="fas fa-check"></i> {{processSummary?.output?.success}}</span>&nbsp;<span
        transloco="success"></span>
      </div>
      <div class="col-auto">
        <span class="badge bg-danger rounded-pill"><i class="fas fa-exclamation"></i> {{processSummary?.output?.error}}</span>&nbsp;<span
        transloco="error"></span>
      </div>
      <div class="col-auto">
        <span class="badge bg-warning rounded-pill"><i class="fas fa-exclamation-triangle"></i> {{processSummary?.output?.warning}}</span>&nbsp;<span
        transloco="warning"></span>
      </div>
      <div class="col-auto">
        <span class="badge bg-info rounded-pill"><i class="fas fa-info"></i> {{processSummary?.output?.message}}</span>&nbsp;{{translate('message')}}
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngFor="let item of processSummary?.messages">
        <ng-container [ngSwitch]="item.a">
          <span class="badge bg-success rounded-pill" *ngSwitchCase="1"><i class="fas fa-check"></i></span>
          <span class="badge bg-warning rounded-pill" *ngSwitchCase="2"><i class="fas fa-exclamation-triangle"></i></span>
          <span class="badge bg-danger rounded-pill" *ngSwitchCase="3"><i class="fas fa-exclamation"></i></span>
          <span class="badge bg-info rounded-pill" *ngSwitchCase="4"><i class="fas fa-info"></i></span>
        </ng-container>
        {{item.b}} - {{item.d}}
      </div>
    </div>
  </app-process-output-window>
</ng-container>
