import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {BankFileComponent} from '@harmony-modules/payroll/sections/admin-setup/interface/bank/bank-file/bank-file.component';
import {
  DataExportListComponent
} from '@harmony-modules/payroll/sections/admin-setup/interface/bank/data-export-list/data-export-list.component';
import {
  PayrollCategoryEntitlmentEmployeeWiseComponent
} from '@harmony-modules/payroll/sections/employee/payroll-category-assign/payroll-category-entitlment-employee-wise.component';
import {PayrollWizardComponent} from '@harmony-modules/payroll/sections/process/wizard/payroll-wizard/payroll-wizard.component';
import {WizardModeResolver} from '@harmony-modules/payroll/sections/process/wizard/wizard-mode.resolver';
import {ReportNavigatorComponent} from '@harmony-modules/payroll/sections/report/reports/report-navigator/report-navigator.component';
import {SecurityKeys} from '@shared/models/ISecurity';
import {SalaryProcessMessageService} from '@services/salary-process-message.service';
import {BackupComponent} from '@shared/components/backup/backup.component';
import {VisaFinalizeComponent} from './finalise/visa-finalize.component';
import {LaborComponent} from './labor/labor/labor.component';
import {PayrollProcessComponent} from './payroll-process/payroll-process/payroll-process.component';
import {ChequePrintingComponent} from './print-paystub/cheque-printing/cheque-printing.component';
import {PayrollGroupSelectionComponent} from './select-pay-period/payroll-group-selection.component';
import {TimesheetGridComponent} from './timesheet/timesheet-grid/timesheet-grid.component';

const processRoutes: Route[] = [
  {path: 'payPeriod', component: PayrollGroupSelectionComponent, data: {code: SecurityKeys.payrollProcessing.selectPayPeriod}},
  {path: 'payPeriod/:i', component: PayrollGroupSelectionComponent, data: {code: SecurityKeys.payrollProcessing.selectPayPeriod}},
  {path: 'Labor', component: LaborComponent, data: {code: SecurityKeys.payrollProcessing.payrollLabor}},
  {
    path: 'PayrollProcess/:a',
    component: PayrollProcessComponent,
    data: {code: SecurityKeys.payrollProcessing.processPayroll},
    resolve: {connection: SalaryProcessMessageService}
  },
  {path: 'TimeSheet', component: TimesheetGridComponent, data: {code: SecurityKeys.payrollProcessing.payrollTimesheets}},
  {path: 'PrintChequeAndPaystub', component: ChequePrintingComponent, data: {code: SecurityKeys.payrollProcessing.printEmailPayStub}},
  {path: 'PrintChequeAndPaystub/:e', component: ChequePrintingComponent, data: {code: SecurityKeys.payrollProcessing.printEmailPayStub}},
  {
    path: 'PayrollCategoryEntitlementEmployeeWise/:i/:f/:e',
    component: PayrollCategoryEntitlmentEmployeeWiseComponent,
    data: {code: SecurityKeys.payrollEmployees.payrollCategoryEntitlement}
  },
  {path: 'VisaFinalize', component: VisaFinalizeComponent, data: {code: SecurityKeys.payrollProcessing.payrollWizard}},
  {path: 'DataExportList', component: DataExportListComponent, data: {code: SecurityKeys.payrollAdminSettings.bankInterface}},
  {path: 'DataExportList/:d', component: DataExportListComponent, data: {code: SecurityKeys.payrollAdminSettings.bankInterface}},
  {path: 'BankFile', component: BankFileComponent, data: {code: SecurityKeys.payrollProcessing.createBankFile}},
  {path: 'BankFile/:id', component: BankFileComponent, data: {code: SecurityKeys.payrollProcessing.createBankFile}},
  {path: 'Reports/:module', component: ReportNavigatorComponent, data: {code: SecurityKeys.payrollReportsMain.payrollReports}},
  {path: 'BackUp', component: BackupComponent, data: {payrollWizardMode: true}}
];

const nonWizardModeRoutes = processRoutes.map(route => ({
  ...route,
  data: {...route.data, wizardMode: false},
  resolve: {...route.resolve, wizardMode: WizardModeResolver}
}));

const routes: Routes = [
  {
    path: 'wizard',
    component: PayrollWizardComponent,
    data: {wizardMode: true, code: SecurityKeys.payrollProcessing.payrollWizard},
    resolve: {wizardMode: WizardModeResolver},
    children: [
      ...processRoutes,
      {path: '', redirectTo: 'payPeriod', pathMatch: 'full'}
    ]
  },
  ...nonWizardModeRoutes,
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'wizard'
  },
  {
    path: 'Wizard',
    pathMatch: 'full',
    redirectTo: 'wizard'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayrollProcessWizardRoutingModule {
}
