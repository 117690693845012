import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-report-navigator',
  templateUrl: './report-navigator.component.html',
  styleUrls: ['./report-navigator.component.scss']
})
export class ReportNavigatorComponent implements OnInit {
  openreportparent: boolean = false;
  module: string;

  constructor(private _route: ActivatedRoute) {
  }

  ngOnInit() {
  }
}
