import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from '@shared/layouts/layout.component';
import {AuthGuard} from './app/account/auth.guard';
import {AccessControlGuard} from './app/account/access-control/guards/access-control.guard';


const hhrmSroutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AccessControlGuard],
    children: [
      {
        path: '',
        data: {
          code: 'payroll',
          module: 'payroll'
        },
        loadChildren: () => import('./app/modules/features/payroll/payroll.module').then(m => m.PayrollModule)
      },
      {
        path: 'configuration',
        data: {
          code: 'configuration',
          module: 'core'
        },
        loadChildren: () => import('./app/modules/features/configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'usersetup',
        loadChildren: () => import('./app/modules/features/user-setup/user-setup.module').then(m => m.UserSetupModule)
      },
      {path: 'access', loadChildren: () => import('./app/account/access-control/access-control.module').then(m => m.AccessControlModule)},
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'payroll'
      }
    ]
  },
  {path: 'account', loadChildren: () => import('./app/account/account.module').then(m => m.AccountModule)},
  {path: 'external', loadChildren: () => import('./app/external/external.module').then(m => m.ExternalModule)},
  {path: 'access', loadChildren: () => import('./app/account/access-control/access-control.module').then(m => m.AccessControlModule)},
  {path: 'reset', pathMatch: 'full', redirectTo: 'account/reset'},
  {path: 'login', pathMatch: 'full', redirectTo: 'account/login'},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(hhrmSroutes, {
      relativeLinkResolution: 'corrected'
    }),
    HttpClientModule
  ],
  exports: [RouterModule]
})

export class RoutingModule {
}

