/* tslint:disable */
import {DatePipe} from '@angular/common';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemPreferenceService} from '@harmony-modules/payroll/services/admin-setup/system-preference.service';
import {LaborService} from '@harmony-modules/payroll/services/payroll-setup/labor.service';
import {PayrollGroupService} from '@harmony-modules/payroll/services/payroll-setup/payroll-group.service';
import {PayrollSpecialProcessingService} from '@harmony-modules/payroll/services/process/payroll-special-processing.service';
import {ICostData, ILaborTimesheeInCost} from '@shared/models/ilabor';
import {
  ICheckForSpecialErrors,
  ICheckProcessQueue,
  IChecksforLocking,
  IEditPaycardProcess,
  IGetAdjstmntPaycrd,
  IGetPayStub,
  IPaycardEdit,
  IPayCatEmployee,
  IPayProcessUpdate,
  IPayrollActiveEmp,
  IPayrollActiveEmpList,
  IPayrollCatType,
  IPayrollGroups,
  IPayrollProcessPeriod,
  IPayrollProcessStatusReturn,
  IPayrollProcessUpdate,
  IPayStubReturn,
  IProcessedListClear,
  IProcessedListOutout,
  IProcessPayroll,
  ITaxProvinces,
  IUSEmpTaxProvinces,
  ProcessSummary
} from '@shared/models/ipayroll-group';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TelerikReportViewerComponent} from '@progress/telerik-angular-report-viewer';
import {AuthenticationService} from '@services/index';
import {WizardService} from '@services/wizard.service';
import {scopeLoader} from '@shared/i18n/transloco-scoped-loader.helper.';
import {IReportFilter} from '@shared/models/ireport';
import {SignalRConnection} from 'ng2-signalr';
import {Observable, Subscription} from 'rxjs';
import {filter, mergeMap, take} from 'rxjs/operators';
import {EnvService} from '@services/env.service';
import {SalaryProcessMessageService} from '@services/salary-process-message.service';
import {ISystemPreferenceInit} from '@shared/models/isystem-preference';


declare var showMsg: any;
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-payroll-process',
  templateUrl: './payroll-process.component.html',
  styleUrls: ['./payroll-process.component.scss'],
  providers:
    [{
      provide: TRANSLOCO_SCOPE,
      useValue: {scope: 'payrollProcess', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
    }]
})
export class PayrollProcessComponent implements OnInit {

  payrollGroups: IPayrollGroups[] = [{a: null, b: null}];
  payrollProcessPeriod: IPayrollProcessPeriod;
  payrollActiveEmployees: IPayrollActiveEmp[];
  filteredPRActiveEmployees: IPayrollActiveEmp[] = [];
  filteredPRActiveEmployeesList: IPayrollActiveEmpList[] = [];

  payrollGrpId: number;
  prProcessYear: number;
  prProcessStartdate: Date;
  prProcessEndtdate: Date;
  employeeIDs: number[] = [];
  employeeIDstring: string;
  flag: number;
  sessionId: string;
  jobId: string;
  selectedEmployee: IPayrollActiveEmpList;

  processPR: IProcessPayroll = {
    f: null,
    c: null,
    y: null,
    sd: null,
    ed: null,
    eid: null,
    exflg: null,
    cat: null,
    seq_no: null,
    connectionId: null
  };
  catList: string = '';
  public opened = false;
  reportSource: any;

  max: number = 0;
  // count: number = 0;
  value: number = 0;
  cssClasses: string[] = [];
  colorClass: string;
  processing: boolean = false;
  processingDone: boolean = false;
  cssClassesWarn: string[] = [];
  colorClassWarn: string;
  searchText;

  payfrequency: string = '';
  processPeriod: string = '';
  // processedList: IPayrollProcessStatusReturn[] = [];
  // processedListOutout: IProcessedListOutout[] = [];
  processSummary: ProcessSummary = {} as ProcessSummary;
  ipl: IProcessedListClear = {sess_id: null, st_date: null, ed_date: null, prg_code: null, proc_year: null, seq_no: null};

  getPayStub: IGetPayStub = {sd: null, ed: null, en: null, pg: null, py: null, SequenceNo: null};
  editPaycard: IGetPayStub = {sd: null, ed: null, en: null, pg: null, py: null, SequenceNo: null};
  paystub: IPayStubReturn[];
  isPayStubActive: boolean = false;
  employeeId: number;
  empNo: string;
  empN: string;
  errs: number;
  warnings: number;
  messages: number;
  success: number;
  sdate: string;
  edate: string;
  empIdClicked: number;
  laborTimesheetList: ILaborTimesheeInCost;

  processQueue: ICheckProcessQueue;
  prlgCode: number;
  selectedPRcode: IPayrollGroups;
  external_para: number = null;
  public payrollCategoryTypes: Array<IPayrollCatType> = [];
  public placeHolder: IPayrollCatType = {a: null, b: 'Select categories...'};
  paycatEmployees: IPayCatEmployee[];
  filteredPRActiveEmployeesExternal: IPayrollActiveEmp[] = [];
  paycattypecode: number;
  paycatArr: IPayrollCatType[] = [];
  paycatArr2: IPayrollCatType[] = [];
  isExternalFlg: number = 0;
  clearIcon: boolean = false;
  sysPref: ISystemPreferenceInit;
  selectedTypes: any = null;
  IsChecked: any = null;
  empWithSpecialSalaryErrs: ICheckForSpecialErrors[] = [];
  paycardEditList: IPaycardEdit[] = [];
  paycardEditListCopy: IPaycardEdit[] = [];
  paycard_process_update: IPayProcessUpdate;
  getTaxProvince: IChecksforLocking = {fromDate: null, toDate: null, prlgCode: null, year: null, proc_seq_no: null};
  taxprovinces: ITaxProvinces[] = [];
  taxprovincesUS: IUSEmpTaxProvinces[] = [];
  province: string = '';
  overwriteEnabled: boolean = false;
  editPaycardProcessList: IEditPaycardProcess[] = [];
  seq_id: number;
  wizardMode: boolean = false;
  wizardMode$: Subscription;
  proc_seq_number: number;
  regex1: any;
  regex2: any;
  regex: any;
  //province_label: string = 'Tax province';
  memo_to_employee: string = '';
  sectionid_of_memo_to_employee = 15;
  adj_paystub: IGetAdjstmntPaycrd;

  token: string;
  @ViewChild('viewer1') viewer: TelerikReportViewerComponent;
  companyCode: string;
  userId: string;
  para_ytd: number;
  reportFilters: IReportFilter[] = [];
  rprtSrce: { report: string, parameters: { param: string, parFilter: string, dateFormat: string, timeFormat: string, procYear: number, YTD: number, token: string } } = {
    report: '',
    parameters: {param: '', parFilter: '', dateFormat: '', timeFormat: '', procYear: null, YTD: 0, token: ''}
  };
  viewerContainerStyle = {
    position: 'relative',
    display: 'flex',
    //width: '1000px',
    height: '100%',
    ['font-family']: 'ms sans serif'
  };
  baseURL: string = this.envService?.glReportUrl;
  report: string = 'PayrollJournal.trdp';
  date_format: string;
  time_format: string;
  proc_year: number;
  payroll_grp_name: string;
  is_cost: boolean = false;
  costData: ICostData[] = [];
  connection: SignalRConnection;

  private getPayrollGroupService: Subscription;
  private getPayrollProcessPeriodService: Subscription;
  private getAllPayrollActiveEmployees: Subscription;
  private updatePayrollProcessService: Subscription;
  private payrollProcesUpdateStatusService: Subscription;
  private payrollProcesUpdateStatusServicePaycard: Subscription;
  private obsrvble: Observable<any>;
  private multipleService: Subscription;
  private flushPayrollProcessQueueService: Subscription;
  private getPayStubService: Subscription;
  private getServerStatusService: Subscription;
  private getPayrollCategoryTypesforExternalService: Subscription;
  private getpaycatEmployeesService: Subscription;
  private checkErrorsInspecialPayProcessService: Subscription;
  private getPaycardForEditService: Subscription;
  private getTaxProvincesForEmployeesService: Subscription;
  private getAuthenticationTokenService: Subscription;


  constructor(
    private _payrollService: PayrollGroupService,
    private _wizardService: WizardService,
    private route: ActivatedRoute,
    private _sysPrefService: SystemPreferenceService,
    private _specialProcessService: PayrollSpecialProcessingService,
    private router: Router,
    private _datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private _laborService: LaborService,
    private hubService: SalaryProcessMessageService,
    @Inject(TRANSLOCO_SCOPE) private scope,
    private transloco: TranslocoService,
    private envService: EnvService) {
  }

  async ngOnInit() {
    this.connection = this.hubService.connection;
    this.subscribePayrollHandler();
    this.getAuthenticationTokenService = this.authenticationService.currentUser.pipe(untilDestroyed(this)).subscribe(x => {
      this.token = x.token;
    });
    if (this.route.snapshot.params.a != 0) {
      this._specialProcessService.cateTypes.pipe(untilDestroyed(this)).subscribe(res => {
        this.selectedTypes = res;
      });
    } else {
      this.selectedTypes = null;
    }

    this.wizardMode$ = this._wizardService.wizardMode$.pipe(untilDestroyed(this)).subscribe(mode => {
      this.wizardMode = mode;
      //console.log(this.wizardMode);
    });
    this._sysPrefService.getinitSystemPreferences().pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.sysPref = data[0];
        this.proc_year = this.sysPref.d;
        //console.log(this.proc_year);
        this.date_format = this.sysPref.df;
        this.time_format = this.sysPref.tf;
        this.regex1 = '^\\d+(\\.\\d{0,' + this.sysPref.g + '})?$';
        this.regex2 = '^\\d+(\\.\\d{0,' + this.sysPref.h + '})?$';
        this.regex = '^\\d+(\\.\\d{0,2})?$';
        if ((this.sysPref.y != null) && (this.sysPref.y == 82)) {
          //this.province_label = 'States';
        }
      }
    });
    this.filteredPRActiveEmployees.length = 0;
    let temp = await this._wizardService.currentGroupInfo$.pipe(untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(group => {
      if (group) {
        this.prlgCode = group.gc;
      }
    });

    if (!this.prlgCode) {
      this.prlgCode = +this.route.snapshot.params.a;
    }

    this.external_para = +this.route.snapshot.params.ex;

    if (this.external_para == 1) {
      this.isExternalFlg = 1;
      this.getPayrollCategoryTypesforExternalService = this._payrollService.getPayrollCategoryTypesforExternal().pipe(untilDestroyed(this)).subscribe(
        res => {
          this.payrollCategoryTypes = res;
          this.getpaycatEmployeesService = this._payrollService.getpaycatEmployees().pipe(untilDestroyed(this)).subscribe(
            res => {
              this.paycatEmployees = res;
              this.getPayrollGroupService = this._payrollService.getPayrollAllGroups().switchMap(
                res => {
                  this.payrollGroups = res;
                  if (this.payrollGroups !== null) {
                    if (this.prlgCode !== 0 && !isNaN(this.prlgCode)) {
                      this.onDropDownChange(this.prlgCode);
                      let tem = this.payrollGroups.findIndex(x => x.a == this.prlgCode);
                      this.selectedPRcode = this.payrollGroups[tem];
                      this.payrollGroups[tem] = this.payrollGroups[0];
                      this.payrollGroups[0] = this.selectedPRcode;
                      let prc = this.prlgCode;
                      this.processQueue = {pc: prc};
                      let obs = this._payrollService.clearUnprocessedEmployeesFromtheQueue(this.processQueue);
                      return obs;

                    }
                    if (this.prlgCode === 0 || isNaN(this.prlgCode)) {
                      this.onDropDownChange(this.payrollGroups[0].a);
                      let prc = this.payrollGroups[0].a;
                      this.processQueue = {pc: prc};
                      let obs = this._payrollService.clearUnprocessedEmployeesFromtheQueue(this.processQueue);
                      return obs;
                    }
                  }
                }).pipe(untilDestroyed(this)).subscribe(
                res => {
                  //console.log("cleared payroll queue");
                },
                err => {
                  console.log('error clearing pr queue');
                }
              );
            },
            err => {
              console.log('Error occurred');
            }
          );
        },
        err => {
          console.log('Error occurred');
        }
      );
    } else {
      this.getPayrollGroupService = this._payrollService.getPayrollAllGroups().switchMap(
        res => {
          this.payrollGroups = res;
          if (this.payrollGroups !== null) {
            if (this.prlgCode !== 0 && !isNaN(this.prlgCode)) {
              this.onDropDownChange(this.prlgCode);
              let tem = this.payrollGroups.findIndex(x => x.a == this.prlgCode);
              this.selectedPRcode = this.payrollGroups[tem];
              this.payrollGroups[tem] = this.payrollGroups[0];
              this.payrollGroups[0] = this.selectedPRcode;
              let prc = this.prlgCode;
              this.processQueue = {pc: prc};
              let obs = this._payrollService.clearUnprocessedEmployeesFromtheQueue(this.processQueue);
              return obs;
            }
            if (this.prlgCode === 0 || isNaN(this.prlgCode)) {
              this.onDropDownChange(this.payrollGroups[0].a);
              let prc = this.payrollGroups[0].a;
              this.processQueue = {pc: prc};
              let obs = this._payrollService.clearUnprocessedEmployeesFromtheQueue(this.processQueue);
              return obs;
            }
          }
        }).pipe(untilDestroyed(this)).subscribe(
        res => {
          //console.log("cleared payroll queue");
        },
        err => {
          console.log('error clearing pr queue');
        }
      );
    }


    let tempex;
    this.external_para == 1 ? tempex = 1 : tempex = 0;

  }

  onDropDownChange(value): void {
    this.payfrequency = '';
    this.processPeriod = '';
    this.employeeIDs.length = 0;
    this.prProcessYear = null;
    this.prProcessStartdate = null;
    this.prProcessEndtdate = null;
    this.filteredPRActiveEmployees.length = 0;
    this.filteredPRActiveEmployeesExternal.length = 0;
    this.isPayStubActive = false;
    this.value = 0;
    this.success = 0;
    this.errs = 0;
    this.warnings = 0;
    this.messages = 0;
    let id = +value;
    this.payrollGrpId = id;
    this.payroll_grp_name = this.payrollGroups.find(x => x.a == this.payrollGrpId).b.split(' - ')[0];

    this.getPayrollProcessPeriodService = this._payrollService.getPayrollProcessPeriod(id).switchMap(
      res => {
        this.payrollProcessPeriod = res[0];
        if (this.payrollProcessPeriod) {
          //console.log(this.payrollProcessPeriod);
          this.payfrequency = this.payrollProcessPeriod.d;
          this.prProcessYear = this.payrollProcessPeriod.e;
          this.prProcessStartdate = this.payrollProcessPeriod.b;
          this.prProcessEndtdate = this.payrollProcessPeriod.c;
          this.proc_seq_number = this.payrollProcessPeriod.f;
          let x = this.fromJsonDate(this.payrollProcessPeriod.b);//this._datePipe.transform(this.payrollProcessPeriod.b, this.sysPref.df)
          let y = this.fromJsonDate(this.payrollProcessPeriod.c);
          this.processPeriod = x + ' - ' + y;
        }
        if (this.external_para !== 1) {
          return this._payrollService.getAllPayrollActiveEmployees(value, this.prProcessStartdate, this.prProcessEndtdate);
        } else {
          return this._payrollService.getSpecialPayrollActiveEmployees(value, this.prProcessStartdate, this.prProcessEndtdate);
        }
      }
    ).pipe(untilDestroyed(this)).subscribe(data => {
      this.filteredPRActiveEmployeesList = data;
      for (let item of this.filteredPRActiveEmployeesList) {
        this.filteredPRActiveEmployees.push({
          a: item.a,
          b: item.b,
          c: item.c,
          d: item.d,
          e: item.e,
          f: item.f,
          with_labor: item.with_labor,
          flg: null,
          Note: item.Note
        });
      }
      this.filteredPRActiveEmployees.sort(function(a, b) {
        if (a.e < b.e) {
          return -1;
        }
        if (a.e > b.e) {
          return 1;
        }
        return 0;
      });
      if (this.selectedTypes && this.selectedTypes.length) {
        this.onPayCatDropDownChange(this.selectedTypes);
      }
      this.getTaxProvincesForProcessedEmployees();
    });

  }

  getTaxProvincesForProcessedEmployees(): void {
    if ((this.sysPref?.y != null) && (this.sysPref?.y == 82)) {
      this._payrollService.GetTaxProvincesForUSEmployees().pipe(take(1), untilDestroyed(this)).subscribe(res => {
        if (res) {
          this.taxprovincesUS = res;
        }
      });
    } else {
      this.getTaxProvince = {
        fromDate: this.prProcessStartdate,
        toDate: this.prProcessEndtdate,
        prlgCode: this.payrollGrpId,
        year: this.prProcessYear,
        proc_seq_no: this.proc_seq_number
      };
      this.getTaxProvincesForEmployeesService = this._payrollService.getTaxProvincesForEmployees(this.getTaxProvince)
        .pipe(untilDestroyed(this)).subscribe(
          res => {
            this.taxprovinces = res;
          }
        );
    }
  }

  onPayCatDropDownChange(value: any): void {
    this._specialProcessService.changeGoal(value);
    let temp: IPayrollCatType = {a: null, b: null};
    let tempemp: IPayCatEmployee[] = [];
    temp = value[0];
    this.paycatArr.length = 0;
    this.catList = '';
    let tempArr: string[] = [];
    if (value.length == 0 || value == undefined) {
      this.filteredPRActiveEmployeesExternal.length = 0;
    } else {
      this.filteredPRActiveEmployeesExternal.length = 0;
      for (let j of value) {
        if (this.paycatArr.includes(j) == false) {
          this.paycatArr.push(j);
          tempArr.push(j.a);
        }
      }
      this.catList = tempArr.toString();
      for (let y of this.paycatArr) {
        tempemp = this.paycatEmployees.filter(x => x.a == y.a);

        if (tempemp.length > 0) {
          for (let k of tempemp) {
            let emp: IPayrollActiveEmp;
            let empt: IPayrollActiveEmpList;
            empt = this.filteredPRActiveEmployeesList.find(x => (x.b == k.b));
            if (empt !== undefined) {
              if (this.filteredPRActiveEmployeesExternal.find(y => y.b == empt.b) == undefined) {
                emp = {a: empt.a, b: empt.b, c: empt.c, d: empt.d, e: empt.e, f: emp.f, with_labor: empt.with_labor, flg: null};
                this.filteredPRActiveEmployeesExternal.push(emp);
              }

            }

          }
        }
      }

    }
  }

  /*..format dates..*/
  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate.substring(0, 10));
    return this._datePipe.transform(bDate.toISOString().substring(0, 10), this.sysPref && this.sysPref.df ? this.sysPref.df : 'yyyy-MM-dd');
  }

  checkBoxClicked(event: any, emp_id: number): void {
    this.value = 0;
    if (!event.target.checked) {
      this.employeeIDs.splice(this.employeeIDs.indexOf(emp_id), 1);
      this.filteredPRActiveEmployees.find(x => x.b == emp_id).flg = 0;
      event.target.checked = false;
    } else if (event.target.checked) {

      if (this.external_para !== 1) {
        let tempEmp = this.empWithSpecialSalaryErrs.find(x => x.i == emp_id);
        if (tempEmp == null) {
          this.employeeIDs.push(emp_id);
          event.target.checked = true;
          this.filteredPRActiveEmployees.find(x => x.b == emp_id).flg = 1;
        } else {
          event.target.checked = false;
          this.filteredPRActiveEmployees.find(x => x.b == emp_id).flg = 0;
          const title = this.transloco.translate('error');
          const buttonOk = this.transloco.translate('ok');
          const message = this.transloco.translate('alerts.salaryProcessingError', {value: tempEmp.m}, 'payrollProcess');
          showMsg({
            'type': 'sticky',
            'status': 'error',
            'title': title,
            'buttons': {
              [buttonOk]: {
                action: function() {
                }
              }
            },
            'message': message,
            'delay': '1500'
          });

        }
      } else {
        event.target.checked = true;
        this.employeeIDs.push(emp_id);
      }
    }

  }

  subscribePayrollHandler(): void {
    this.hubService.processSummary$.pipe(untilDestroyed(this)).subscribe((res: ProcessSummary) => this.processSummary = res);
    this.hubService.payrollProcessProgress$.pipe(filter((res) => !!res), untilDestroyed(this)).subscribe((res: IPayrollProcessUpdate) => {
      //this.count = this.processedList.length;
      this.value = res.percentage;
      if (res.percentage === 100) {
        this.processing = false;
        this.processingDone = true;
        this.getTaxProvincesForProcessedEmployees();

        this.ipl = {
          sess_id: this.sessionId,
          st_date: this.prProcessStartdate,
          ed_date: this.prProcessEndtdate,
          prg_code: this.payrollGrpId,
          proc_year: this.prProcessYear,
          seq_no: this.proc_seq_number
        };

        this.employeeIDs.length = 0;
        this.filteredPRActiveEmployees.forEach(x => x.flg = 0);
      }
    });
  }

  onProcessButtonClick(): void {
    if (this.employeeIDs.length === 0) {
      const title = this.transloco.translate('info');
      const message = this.transloco.translate('alerts.pleaseSelectEmployee', {}, 'payrollProcess');
      showMsg({
        'type': 'nonsticky',
        'status': 'info',
        'title': title,
        'buttons': {},
        'message': message,
        'delay': '1500'
      });
    } else {
      this.is_cost = false;
      this.processing = true;
      this.flag = 0;
      this.max = this.employeeIDs.length;
      this.employeeIDstring = this.employeeIDs.toString();
      // this.value = 0;
      // this.count = 0;
      this.sessionId = '';
      this.success = 0;
      this.errs = 0;
      this.warnings = 0;
      this.messages = 0;
      if (this.isPayStubActive) {
        this.isPayStubActive = false;
      }
      this.processPR = {
        f: this.flag,
        c: this.payrollGrpId,
        y: this.prProcessYear,
        sd: this.prProcessStartdate,
        ed: this.prProcessEndtdate,
        eid: this.employeeIDstring,
        exflg: this.isExternalFlg,
        cat: this.catList,
        seq_no: this.proc_seq_number,
        connectionId: this.connection.id
      };

      this.hubService.initializeListener(this.filteredPRActiveEmployees, this.filteredPRActiveEmployeesExternal, this.external_para, this.processPeriod, 'process');
      this._payrollService.updatePayrollProcess(this.processPR).pipe(untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(res => {
        if (this.wizardMode == true) {
          this._wizardService.updateFlag(2).pipe(take(1), untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(data => {
            this._wizardService.updateWizard(data);
          });
        }

        this.sessionId = res[0];
        this.jobId = res[1];
      });
      //let listener = this.connection.listenFor('processCompleted');
      //listener.subscribe((res: IPayrollProcessStatusReturn[]) => {
      //  console.log('listener.subscribe -> res');
      //  console.log(res);
      //  this.processedList = res;
      //  console.log('this.processedList');
      //  console.log(this.processedList);
      //  this.count = this.processedList.length;
      //  this.value = (this.count / this.max) * 100;
      //  if (this.processedList !== null) {
      //    for (let item of this.processedList) {
      //      if (this.external_para !== 1) {
      //        let ind = this.filteredPRActiveEmployees.findIndex(x => x.b == item.e);
      //        this.filteredPRActiveEmployees[ind].d = item.f;
      //      } else {
      //        let ind = this.filteredPRActiveEmployeesExternal.findIndex(x => x.b == item.e);
      //        this.filteredPRActiveEmployeesExternal[ind].d = item.f;
      //      }
      //    }

      //  }
      //  if (this.processedList !== null && this.processedList.length === this.max) {
      //    this.processing = false;
      //    showMsg({
      //      'type': 'nonsticky',
      //      'status': 'success',
      //      'title': 'Harmony Payroll System',
      //      'buttons': {},
      //      'message': 'Processing complete.',
      //      'delay': '1000'
      //    });
      //    this.processingDone = true;
      //    this.processedListOutout = this.getOutputDisplayList(this.processedList);
      //    for (let item of this.processedListOutout) {
      //      if (item.a !== null && item.a === 1) {
      //        this.success += 1;
      //      } else if (item.a !== null && item.a === 2) {
      //        this.warnings += 1;
      //      } else if (item.a !== null && item.a === 3) {
      //        this.errs += 1;
      //      } else {
      //        this.messages += 1;
      //      }
      //    }
      //  }
      //  this.getTaxProvincesForProcessedEmployees();
      //  this.ipl = {
      //    sess_id: this.sessionId,
      //    st_date: this.prProcessStartdate,
      //    ed_date: this.prProcessEndtdate,
      //    prg_code: this.payrollGrpId,
      //    proc_year: this.prProcessYear,
      //    seq_no: this.proc_seq_number
      //  };
      //  this.flushPayrollProcessQueueService = this._payrollService.flushPayrollProcessQueue(this.ipl).subscribe(
      //    res => {
      //      // console.log("deleted");
      //    },
      //    err => {
      //      console.log('error occurred');
      //    });
      //  this.employeeIDs.length = 0;
      //  this.filteredPRActiveEmployees.forEach(x => x.flg = 0);
      //});

    }
  }

  onRollbackButtonClick(): void {
    if (this.employeeIDs.length === 0) {
      const title = this.transloco.translate('info');
      const message = this.transloco.translate('alerts.pleaseSelectEmployee', {}, 'payrollProcess');
      showMsg({
        'type': 'nonsticky',
        'status': 'info',
        'title': title,
        'buttons': {},
        'message': message,
        'delay': '1500'
      });
    } else {
      this.is_cost = false;
      this.processing = true;
      this.flag = 1;
      this.max = this.employeeIDs.length;
      this.employeeIDstring = this.employeeIDs.toString();
      this.value = 0;
      // this.count = 0;
      this.sessionId = '';
      this.resetConsoleCounters();
      if (this.isPayStubActive) {
        this.isPayStubActive = false;
      }
      this.processPR = {
        f: this.flag,
        c: this.payrollGrpId,
        y: this.prProcessYear,
        sd: this.prProcessStartdate,
        ed: this.prProcessEndtdate,
        eid: this.employeeIDstring,
        exflg: this.isExternalFlg,
        cat: this.catList,
        seq_no: this.proc_seq_number,
        connectionId: this.connection.id
      };

      this.hubService.initializeListener(this.filteredPRActiveEmployees, this.filteredPRActiveEmployeesExternal, this.external_para, this.processPeriod, 'rollback');
      this._payrollService.updatePayrollProcess(this.processPR).pipe(untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(res => {
        if (this.wizardMode == true) {
          this._wizardService.updateFlag(2).pipe(take(1), untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(data => {
            if (data) {
              this._wizardService.updateWizard(data);
            }
          });
        }

        this.sessionId = res[0];
        this.jobId = res[1];
      });
    }
  }

  getClassesForIcon(code: number, proc_status: string) {
    this.cssClasses.length = 0;
    this.colorClass = '';
    if (proc_status == '' || proc_status.toLowerCase() === 'c') {
      switch (code) {
        case 1: {
          this.colorClass = 'bg-success';
          break;
        }
        case 2: {
          this.colorClass = 'bg-warning';
          break;
        }
        case 3: {
          this.colorClass = 'bg-danger';
          break;
        }
        case 4: {
          this.colorClass = 'bg-info';
          break;
        }
        default: {
          this.colorClass = '';
          break;
        }
      }
    } else if (proc_status != '' && proc_status.toLowerCase() !== 'c') {
      this.colorClass = 'tick-icon-red';
    }
    this.cssClasses.push(this.colorClass);
    return this.cssClasses;
  }

  getEmployeeNameAndNumber(id: number): string {
    if (this.external_para !== 1) {
      let temp = this.filteredPRActiveEmployees.filter(x => x.b == id);
      return temp[0].e + '  ' + temp[0].c;
    } else {
      let temp = this.filteredPRActiveEmployeesExternal.filter(x => x.b == id);
      return temp[0].e + '  ' + temp[0].c;
    }
  }

  getOutputDisplayList(list: IPayrollProcessStatusReturn[]): IProcessedListOutout[] {
    let temp: IProcessedListOutout[] = [];
    for (let item of list) {
      let a = item.f;
      let b = this.getEmployeeNameAndNumber(item.e);
      let msg = item.g.split('|');
      let d: string = '';
      if (msg.length == 1) {
        d = this.transloco.translate(item.g);
      } else {
        msg.forEach(x => d += this.transloco.translate(x.trim()) + ' ');
      }
      temp.push({a: a, b: b, d: d});
    }
    return temp;
  }

  onClosedButtonClick(): void {
    this.processingDone = false;
    this.value = 0;
  }

  onOutputButtonClick(): void {
    this.processingDone = !this.processingDone;
  }

  onclickProcessed(): void {
    this.employeeIDs.length = 0;
    if (this.external_para !== 1) {
      for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
        this.filteredPRActiveEmployees[i].flg = 0;
        if (this.filteredPRActiveEmployees[i].d !== 0 && this.filteredPRActiveEmployees[i].d !== 4) {
          this.filteredPRActiveEmployees[i].flg = 1;
          this.employeeIDs.push(this.filteredPRActiveEmployees[i].b);
        }
      }
    } else {
      for (let i = 0; i < this.filteredPRActiveEmployeesExternal.length; i++) {
        this.filteredPRActiveEmployeesExternal[i].flg = 0;
        if (this.filteredPRActiveEmployeesExternal[i].d !== 0) {
          this.filteredPRActiveEmployeesExternal[i].flg = 1;
          this.employeeIDs.push(this.filteredPRActiveEmployeesExternal[i].b);
        }
      }
    }
  }

  onclickUnProcessed(): void {
    this.employeeIDs.length = 0;
    let count = 0;
    if (this.external_para !== 1) {
      for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
        this.filteredPRActiveEmployees[i].flg = 0;
        if (this.filteredPRActiveEmployees[i].d === 0 || this.filteredPRActiveEmployees[i].d === 4) {
          this.filteredPRActiveEmployees[i].flg = 1;
          this.employeeIDs.push(this.filteredPRActiveEmployees[i].b);

        } else {
          count++;
        }
      }
    } else {
      for (let i = 0; i < this.filteredPRActiveEmployeesExternal.length; i++) {
        this.filteredPRActiveEmployeesExternal[i].flg = 0;
        if (this.filteredPRActiveEmployeesExternal[i].d === 0) {
          this.filteredPRActiveEmployeesExternal[i].flg = 1;
          this.employeeIDs.push(this.filteredPRActiveEmployeesExternal[i].b);
        }
      }
    }
  }

  onclickWithTimesheet(no: number) {
    if (no == 1) {
      for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
        this.filteredPRActiveEmployees[i].flg = 0;
        if (this.filteredPRActiveEmployees[i].with_labor == 1) {
          this.filteredPRActiveEmployees[i].flg = 1;
          this.employeeIDs.push(this.filteredPRActiveEmployees[i].b);
        }
      }
    } else if (no == 0) {
      for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
        this.filteredPRActiveEmployees[i].flg = 0;
        if (this.filteredPRActiveEmployees[i].with_labor == 0) {
          this.filteredPRActiveEmployees[i].flg = 1;
          this.employeeIDs.push(this.filteredPRActiveEmployees[i].b);
        }
      }
    }
  }

  onclickWithWarning(): void {
    for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
      this.filteredPRActiveEmployees[i].flg = 0;
      if (this.filteredPRActiveEmployees[i].d !== 0 && this.filteredPRActiveEmployees[i].d == 2) {
        this.filteredPRActiveEmployees[i].flg = 1;
        this.employeeIDs.push(this.filteredPRActiveEmployees[i].b);
      }
    }
  }

  onclickWithErrors(): void {
    for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
      this.filteredPRActiveEmployees[i].flg = 0;
      if (this.filteredPRActiveEmployees[i].d !== 0 && this.filteredPRActiveEmployees[i].d == 3) {
        this.filteredPRActiveEmployees[i].flg = 1;
        this.employeeIDs.push(this.filteredPRActiveEmployees[i].b);
      }
    }
  }

  onclickClearList(): void {
    this.employeeIDs.length = 0;
    if (this.external_para !== 1) {
      for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
        this.filteredPRActiveEmployees[i].flg = 0;
      }
    } else {
      for (let i = 0; i < this.filteredPRActiveEmployeesExternal.length; i++) {
        this.filteredPRActiveEmployeesExternal[i].flg = 0;
      }
    }
  }

  onclickAll(): void {
    this.employeeIDs.length = 0;
    let count = 0;
    if (this.external_para !== 1) {
      for (let i = 0; i < this.filteredPRActiveEmployees.length; i++) {
        if (this.empWithSpecialSalaryErrs.find(x => x.i == this.filteredPRActiveEmployees[i].b) == undefined) {
          this.filteredPRActiveEmployees[i].flg = 1;
          this.employeeIDs.push(this.filteredPRActiveEmployees[i].b);
        } else {
          count++;
        }
      }
      if (count > 0) {
        const title = this.transloco.translate('warning');
        const message = this.transloco.translate('alerts.employeesCannotBeProcessed', {}, 'payrollProcess');
        showMsg({
          'type': 'sticky',
          'status': 'warning',
          'title': title,
          'buttons': {
            'OK': {
              action: function() {
              }
            }
          },
          'message': message
        });
      }
    } else {
      for (let i = 0; i < this.filteredPRActiveEmployeesExternal.length; i++) {
        this.filteredPRActiveEmployeesExternal[i].flg = 1;
        this.employeeIDs.push(this.filteredPRActiveEmployeesExternal[i].b);
      }
    }
    //console.log(this.employeeIDs);
  }

  quickViewPayStub(empId: number, empName: string): void {
    this.is_cost = false;
    this.processingDone = false;
    this.isPayStubActive = true;
    if (this.external_para !== 1) {
      this.empNo = this.filteredPRActiveEmployees.find(x => x.b == empId).e;
    } else {
      this.empNo = this.filteredPRActiveEmployeesExternal.find(x => x.b == empId).e;
    }
    this.empN = empName;
    this.employeeId = empId;
    let prgcode = this.payrollGrpId;
    let startDate = this.prProcessStartdate;
    let endDate = this.prProcessEndtdate;
    let yr = this.prProcessYear;
    this.empIdClicked = empId;
    this.sdate = this.fromJsonDate(startDate);//this._datePipe.transform(startDate, this.sysPref.df || 'yyyy-MM-dd')
    this.edate = this.fromJsonDate(endDate);//this._datePipe.transform(endDate, this.sysPref.df || 'yyyy-MM-dd')
    if ((this.sysPref.y != null) && (this.sysPref.y == 82)) {
      if (this.taxprovincesUS.length > 0) {
        let temProv: IUSEmpTaxProvinces[] = this.taxprovincesUS.filter(x => x.ID_EMPLOYEE == this.employeeId);
        this.province = '';
        for (let p of temProv) {
          this.province += p.STATE_NAME + ', ';
        }
        this.province = this.province.replace(/,\s*$/, '');
      }
    } else {
      let temp = this.taxprovinces.find(x => x.c == this.employeeId);
      temp !== undefined ? this.province = temp.b : this.province = '';
    }
    this.getPayStub = {sd: startDate, ed: endDate, en: empId, pg: prgcode, py: yr, SequenceNo: this.proc_seq_number};
    if (this.external_para == 1) {
      this.getPayStubService = this._payrollService.getSpecialpayStub(this.getPayStub).pipe(untilDestroyed(this)).subscribe(
        res => {
          this.paystub = res;
        },
        err => {
          console.log('error occurred');
        }
      );
    } else {
      this.getPayStubService = this._laborService.GetCostData(this.payrollGrpId, this.sessionId, this.prProcessStartdate, this.prProcessEndtdate, this.prProcessYear, this.proc_seq_number, this.empIdClicked).pipe(take(1)).pipe(
        mergeMap(data => {
          if (data && data.length > 0) {
            this.is_cost = true;
            this.costData = data?.map(value => ({...value, PROC_OUT_MSG: this.transloco.translate(value.PROC_OUT_MSG)})); // ????? data is ICostData[] and ICostData.PROC_OUT_MSG is key from backend to translate ????
          }
          return this._payrollService.getpayStub(this.getPayStub);
        })
      ).pipe(untilDestroyed(this)).subscribe(res => {
        this.paystub = res;
      });
      //this._payrollService.getpayStub(this.getPayStub).subscribe(
      //  res => {
      //    this.paystub = res;
      //  },
      //  err => { console.log("error occurred"); }
      //);
    }
  }

  getCostData() {
    this.getPayStubService = this._laborService.GetCostData(this.payrollGrpId, this.sessionId, this.prProcessStartdate, this.prProcessEndtdate, this.prProcessYear, this.proc_seq_number, this.empIdClicked).pipe(take(1), untilDestroyed(this)).subscribe(data => {
        //console.log('cost data =>>');
        //console.log(data);
        if (data && data.length > 0) {
          this.costData = data.map(value => ({...value, PROC_OUT_MSG: this.transloco.translate(value.PROC_OUT_MSG)})); // ????? data is ICostData[] and ICostData.PROC_OUT_MSG is key from backend to translate ????
        }
      },
      err => {
        console.log('Error occurred while getting cost data');
      });
  }

  public close() {
    this.opened = false;
  }

  onPaystubEditClick(): void {
    this.isPayStubActive = false;
    this.paycardEditList.length = 0;
    this.paycardEditList = [];
    this.paycardEditListCopy.length = 0;
    this.paycardEditListCopy = [];
    this.editPaycard = {
      sd: this.prProcessStartdate,
      ed: this.prProcessEndtdate,
      en: this.employeeId,
      pg: this.payrollGrpId,
      py: this.prProcessYear,
      SequenceNo: this.proc_seq_number
    };
    let temp = this.taxprovinces.find(x => x.c == this.employeeId);
    temp !== undefined ? this.seq_id = temp.d : this.seq_id = 0;
    if ((this.sysPref.y != null) && (this.sysPref.y == 82)) {
      if (this.taxprovincesUS.length > 0) {
        let temProv: IUSEmpTaxProvinces[] = this.taxprovincesUS.filter(x => x.ID_EMPLOYEE == this.employeeId);
        for (let p of temProv) {
          this.province += p.STATE_NAME + ',';
        }
        this.province = this.province.replace(/,\s*$/, '');
      }
    } else {
      temp !== undefined ? this.province = temp.b : this.province = '';
    }
    this.getPaycardForEditService = this._payrollService.getPaycardForEdit(this.editPaycard).pipe(untilDestroyed(this)).subscribe(
      res => {
        this.paycardEditList = res;
        if (this.paycardEditList !== null) {
          for (let item of this.paycardEditList) {
            item.d = item.d != null ? item.d.toFixed(this.sysPref.i) : item.d;
            item.e = item.e != null ? item.e.toFixed(this.sysPref.i) : item.e;
            item.f = item.f != null ? item.f.toFixed(this.sysPref.i) : item.f;
            item.s = item.s != null ? item.s.toFixed(this.sysPref.i) : item.s;
            item.k = item.k != null ? item.k.toFixed(this.sysPref.h) : item.k;
            item.q = item.q != null ? item.q.toFixed(this.sysPref.h) : item.q;
            item.l = item.l != null ? item.l.toFixed(this.sysPref.g) : item.l;
            item.r = item.r != null ? item.r.toFixed(this.sysPref.g) : item.r;
            if (item.b == this.sectionid_of_memo_to_employee) {
              this.memo_to_employee = item.c;
            } else {
              if (this.paycardEditListCopy.find(x => x.j == item.j && x.m == item.m && x.b == item.b) == undefined) {
                this.paycardEditListCopy.push(item);
              }
            }
          }
        }
      }
    );
  }

  isOverwriteCheck(event: any, i: number): void {
    if (event.target.checked == true) {
      if (this.paycardEditListCopy[i].i == 3) {
        let tempcatDtlCode: number = this.paycardEditListCopy[i].j;
        this.paycardEditListCopy.filter(x => x.i == 3 && x.j == tempcatDtlCode).forEach(y => y.p = 1);
      } else {
        this.paycardEditListCopy[i].p = 1;
      }
    } else {
      if (this.paycardEditListCopy[i].i == 3) {
        let tempcatDtlCode: number = this.paycardEditListCopy[i].j;
        this.paycardEditListCopy.filter(x => x.i == 3 && x.j == tempcatDtlCode).forEach(y => y.p = 0);
      } else {
        this.paycardEditListCopy[i].p = 0;
      }
    }
  }

  onUnitChange(event: any, i: number): void {
    this.paycardEditListCopy[i].k = event.target.value;
    this.paycardEditListCopy[i].d = this.paycardEditListCopy[i].k * this.paycardEditListCopy[i].l;
  }

  onRateChange(event: any, i: number): void {
    this.paycardEditListCopy[i].l = event.target.value;
    this.paycardEditListCopy[i].d = this.paycardEditListCopy[i].k * this.paycardEditListCopy[i].l;
  }

  onAmountChange(event: any, i: number): void {
    this.paycardEditListCopy[i].d = event.target.value;
  }

  onEditPaycardProcessClick(): void {
    this.is_cost = false;
    this.processingDone = false;
    this.editPaycardProcessList.length = 0;
    this.sessionId = '';
    this.success = 0;
    this.errs = 0;
    this.warnings = 0;
    this.messages = 0;
    for (let item of this.paycardEditListCopy) {
      let t: boolean = item.p == 1 ? true : false;
      let temp: IEditPaycardProcess = {
        seq_no: this.proc_seq_number,
        pg: this.payrollGrpId,
        sd: this.prProcessStartdate,
        ed: this.prProcessEndtdate,
        yr: this.prProcessYear,
        eid: item.a,
        amnt: item.d,
        erndcde: item.i,
        dc: item.j,
        unit: item.k,
        rate: item.l,
        ec: item.m,
        ovrw: t,
        employee_memo: this.memo_to_employee,
        connectionId: this.connection.id
      };
      this.editPaycardProcessList.push(temp);
    }

    this.hubService.initializePayCardEditProcess(this.filteredPRActiveEmployees, this.employeeId, this.processPeriod);
    this._payrollService.ProcessEditPaycardObject(this.editPaycardProcessList).pipe(untilDestroyed(this)).subscribe(
      res => {
        this.sessionId = res[0];
        this.jobId = res[1];
        this.hubService.payrollProcessProgress$.pipe(take(1)).subscribe(() => {
          this.onPaystubEditClick();
        });
      },
      err => {
        const title = this.transloco.translate('error');
        const message = this.transloco.translate('alerts.errorOccurred', {}, 'payrollProcess');
        showMsg({
          'type': 'non-sticky',
          'status': 'error',
          'title': title,
          'buttons': {},
          'message': message
        });
      }
    );
  }


  onPayeditCloseButton(): void {
    this.processingDone = false;
    this.value = 0;
  }

  onPaystubCloseButton(): void {
    this.isPayStubActive = false;
  }

  searchEmp(): void {
    this.clearIcon = true;
  }

  onClickClearIcon(): void {
    if (this.clearIcon == true) {
      this.clearIcon = false;
      this.searchText = '';
    } else if (this.clearIcon == false) {
      this.clearIcon = true;
    }
  }

  onOpenButtonClick(): void {
    let parameter = ' AND P.PRLG_CODE=' + this.payrollGrpId;
    let other_parameter = 'Payroll group: ' + this.payroll_grp_name;
    this.authenticationService.currentUser.pipe(untilDestroyed(this)).subscribe(x => {
      this.token = x.token;
      this.companyCode = x.selectedCompany.companyName;
      this.userId = x.id;
      this.reportFilters = JSON.parse(localStorage.getItem('rptFlt' + this.companyCode + this.userId));
      var rf = this.reportFilters ? this.reportFilters.find(x => x.reportId == 11) : null;
      this.para_ytd = rf?.filterControls.find(x => x.name == 'include_year_to_date_amounts') ? rf.filterControls.find(x => x.name == 'include_year_to_date_amounts').value : 0;
      this.rprtSrce = {
        report: this.report,
        parameters: {
          param: parameter,
          parFilter: other_parameter,
          dateFormat: this.date_format,
          timeFormat: this.time_format,
          procYear: this.prProcessYear,
          YTD: this.para_ytd,
          token: this.token
        }
      };
      let r: JSON = JSON.parse(JSON.stringify(this.rprtSrce));
      this.reportSource = r;
      this.opened = true;
    });
  }

  onCostClick(): void {
    this.adj_paystub = {
      Start_Date: this.prProcessStartdate,
      End_Date: this.prProcessEndtdate,
      Emp_Number: this.employeeId,
      PayrollGroup_Id: this.payrollGrpId,
      Proc_Year: this.prProcessYear,
      SequenceNo: this.proc_seq_number
    };
    this._laborService.GetLaborTimesheets(this.adj_paystub).pipe(untilDestroyed(this)).subscribe(lt => {
      this.laborTimesheetList = lt;
      $('#costModal').modal('show');
    });
  }

  private resetConsoleCounters() {
    this.messages = 0;
    this.warnings = 0;
    this.errs = 0;
    this.success = 0;
  }

  getCostSessionId(event: any) {
    this.jobId = event[1];
    let sessionid_cost: string = event[0];

    let listener = this.connection.listenFor('processCompleted');
    listener.pipe(untilDestroyed(this)).subscribe((res: IPayrollProcessStatusReturn[]) => {
      // this.processedList = res;

      this.processingDone = true;
      // if (this.processedList != null && this.processedList.length >= 1) {
      //   this.processedListOutout = this.getOutputDisplayList(this.processedList);
      //   this.resetConsoleCounters();
      //   for (let item of this.processedListOutout) {
      //     if (item.a !== null && item.a === 1) {
      //       this.success += 1;
      //     } else if (item.a !== null && item.a === 2) {
      //       this.warnings += 1;
      //     } else if (item.a !== null && item.a === 3) {
      //       this.errs += 1;
      //     } else {
      //       this.messages += 1;
      //     }
      //   }
      this.ipl = {
        sess_id: sessionid_cost,
        st_date: this.prProcessStartdate,
        ed_date: this.prProcessEndtdate,
        prg_code: this.payrollGrpId,
        proc_year: this.prProcessYear,
        seq_no: this.proc_seq_number
      };
      this._payrollService.flushPayrollProcessQueue(this.ipl).pipe(untilDestroyed(this)).subscribe(response => {
        this._payrollService.getpayStub(this.getPayStub).pipe(untilDestroyed(this)).subscribe(ps => {
          this.paystub = ps;
          this.getCostData();
        });
      });
      // }
    });
  }

  ngOnDestroy() {
    this.hubService.disconnect();
    if (this.getPayrollGroupService) {
      this.getPayrollGroupService.unsubscribe();
    }
    if (this.getPayrollProcessPeriodService) {
      this.getPayrollProcessPeriodService.unsubscribe();
    }
    if (this.getAllPayrollActiveEmployees) {
      this.getAllPayrollActiveEmployees.unsubscribe();
    }
    if (this.updatePayrollProcessService) {
      this.updatePayrollProcessService.unsubscribe();
    }
    if (this.payrollProcesUpdateStatusService) {
      this.payrollProcesUpdateStatusService.unsubscribe();
    }
    if (this.flushPayrollProcessQueueService) {
      this.flushPayrollProcessQueueService.unsubscribe();
    }
    if (this.getServerStatusService) {
      this.getServerStatusService.unsubscribe();
    }
    if (this.getPayrollCategoryTypesforExternalService) {
      this.getPayrollCategoryTypesforExternalService.unsubscribe();
    }
    if (this.getpaycatEmployeesService) {
      this.getpaycatEmployeesService.unsubscribe();
    }
    if (this.checkErrorsInspecialPayProcessService) {
      this.checkErrorsInspecialPayProcessService.unsubscribe();
    }
    if (this.getPaycardForEditService) {
      this.getPaycardForEditService.unsubscribe();
    }
    if (this.getTaxProvincesForEmployeesService) {
      this.getTaxProvincesForEmployeesService.unsubscribe();
    }
    if (this.wizardMode$) {
      this.wizardMode$.unsubscribe();
    }
    if (this.getAuthenticationTokenService) {
      this.getAuthenticationTokenService.unsubscribe();
    }

  }

  onViewNotes(emp: IPayrollActiveEmpList) {
    this.selectedEmployee = emp;
    $('#selectedEmployeeNoteModal').modal('show');
  }
}
