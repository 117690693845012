<ng-container *transloco="let translate; read: 'visaFinalize'">
  <div class="card">
    <div class="card-body">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-8">
              <div class="row mb-3">
                <label class="col-6 text-start fs-5">{{translate('netEmployeePayments')}}</label>
                <label class="col-6 text-end fs-5"> {{payrunSummaryData?.Net | currency}}</label>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="row">
                    <div class="col-8 text-start fs-5">
                      <label *ngIf="!isUs">{{translate('craRemittance')}}</label>
                      <label *ngIf="isUs">&nbsp;{{translate('remittance')}}</label>
                    </div>
                    <div class="col-4 text-end">
                      <label class="text-end fs-5"> {{payrunSummaryData?.Federal_Employee + payrunSummaryData?.Federal_Company  | currency}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label class="ps-4">{{translate('employeeRemittance')}}&nbsp;
                        <span class="text-black-50" *ngIf="!isUs">({{translate('craEmployeeRemittanceText')}})</span>
                      </label>
                    </div>
                    <div class="col-auto text-end">
                      <label class="text-end"> {{payrunSummaryData?.Federal_Employee | currency}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label class="ps-4">{{translate('companyRemittance')}}&nbsp;
                        <span class="text-black-50" *ngIf="!isUs">({{translate('craCompanyRemittanceText')}})</span>
                      </label>
                    </div>
                    <div class="col-auto text-end">
                      <label class="text-end"> {{payrunSummaryData?.Federal_Company | currency}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3" *ngIf="!isUs">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 text-start fs-5">
                      <label class="text-start">{{translate('revenueQuebecRemittance')}}</label>
                    </div>
                    <div class="col-6 text-end">
                      <label class="text-end fs-5"> {{payrunSummaryData?.Quebec_Employee + payrunSummaryData?.Quebec_Company  | currency}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-start">
                      <label class="text-start ps-4">{{translate('employeeRemittance')}}</label>
                    </div>
                    <div class="col-6 text-end">
                      <label class="text-end"> {{payrunSummaryData?.Quebec_Employee | currency}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-start">
                      <label class="text-start ps-4">{{translate('companyRemittance')}}</label>
                    </div>
                    <div class="col-6 text-end">
                      <label class="text-end"> {{payrunSummaryData?.Quebec_Company | currency}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-6 text-start">
                  <label class="text-start fs-5">{{translate('totalPayments')}}</label>
                </div>
                <div class="col-6 text-end">
                  <label class="text-end fs-5"> {{payrunSummaryData?.Total_Payments_Current | currency}}</label>
                </div>
                <div class="col-6 text-start">
                  <label class="text-center fs-5 ps-5">{{translate('vsLastPayrun')}}</label>
                </div>
                <div class="col-6 text-end">
                  <label class="text-end fs-5"> {{payrunSummaryData?.Total_Payment_Previous | currency}}</label>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <canvas baseChart [labels]="donutChartConfig.labels" [datasets]="donutChartConfig.datasets" chartType="doughnut"
                      [options]="donutChartConfig.options" height="260"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-0">
        <div class="card-body border">
          <div class="row">
            <div class="col-12 text-center mb-2"><h3 class="text-decoration-underline">{{translate('payrunSummary')}}</h3></div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('payPeriod')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start"> {{groupInfo?.payrollPeriod}}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('processingDate')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start"> {{today | date: sysPref?.df || 'yyyy-MM-dd'}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start">{{groupInfo?.processingDate | date: sysPref?.df || 'yyyy-MM-dd'}}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('paymentDate')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start"> {{groupInfo?.cd | date:  sysPref?.df || 'yyyy-MM-dd' }}</label>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('paymentMethod')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start">{{payrunSummaryData?.Payment_method}}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('payrollGroup')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start"> {{groupInfo?.gn}}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('payFrequency')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start"> {{groupInfo?.payfrequency}}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('totalEmployees')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start">{{payrunSummaryData?.Total_employees}}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('totalEmployeesPaid')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start">{{payrunSummaryData?.Total_employees_paid}}</label>
                </div>
              </div>
              <ng-container *ngIf="payrunSummaryData?.Employees_Zero_Net">

                <div class="row">
                  <div class="col-6">
                    <label class="text-start fs-5 fw-bold">{{translate('employeesWithZero')}}</label>
                  </div>
                  <div class="col-6">
                    <label class="text-start">{{payrunSummaryData?.Employees_Zero_Net}}</label>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="payrunSummaryData?.Employees_Negative_Net">
                <div class="row">
                  <div class="col-6">
                    <label class="text-start fs-5 fw-bold">{{translate('employeesWithNegative')}}</label>
                  </div>
                  <div class="col-6">
                    <label class="text-start">{{payrunSummaryData?.Employees_Negative_Net}}</label>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold" *ngIf="!isUs">{{translate('accruedVacation')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start" *ngIf="!isUs"> {{payrunSummaryData?.Accrued | currency}}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label class="text-start fs-5 fw-bold">{{translate('totalCost')}}</label>
                </div>
                <div class="col-6">
                  <label class="text-start">{{payrunSummaryData?.Total_Payments_Current | currency}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2 d-flex justify-content-end">
              <button class="btn btn-primary d-print-none me-2" (click)="postBtnClicked()">{{translate('post')}}</button>
              <button class="btn btn-primary d-print-none" (click)="openPrintDialog()"><i class="fa fa-print"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-none">
        <div class="col">
          <table class="table tablegenerated">
            <thead>
              <tr>
                <th width="4%">#</th>
                <th width="21%">{{translate('start')}}</th>
                <th width="21%">{{translate('end')}}</th>
                <th width="21%">{{translate('payment')}}</th>
                <th width="21%">{{translate('lastPosted')}}</th>
                <th width="5%"></th>
                <th width="6%"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="payschedule">
                <ng-container *ngFor="let c of payschedule; let k=index">
                  <tr [ngStyle]="{'background-color':(c.l==1) ? '#ddd' : 'white'  }">
                    <td width="4%">{{c.i}}</td>
                    <td width="21%">{{c.s | date: sysPref?.df || 'yyyy-MM-dd' }}</td>
                    <td width="21%">{{c.e | date: sysPref?.df || 'yyyy-MM-dd'}}</td>
                    <td width="21%">{{c.c | date: sysPref?.df || 'yyyy-MM-dd'}}</td>
                    <td width="21%">{{c.p | date: sysPref?.df || 'yyyy-MM-dd'}}</td>
                    <td width="11%">
                      <div class="editDelete-btn-wrapper">
                        <!--                    <img src="../css/icons/lock.png" class="lockicon" *ngIf="c.z == 0 && c.locked > 0">&lt;!&ndash; c.d === 1 (click)="unlockpayPeriod(c.i)"&ndash;&gt;-->
                        <!--                    <img src="../css/icons/UNLOCK.png" class="lockicon" *ngIf="(c.PROC_SEQ_NO > 0 && c.z > 0)" (click)="checkToLockpayPeriod(c.i)">-->
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-container>
    <!-- Modal -->
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="lockModal" aria-hidden="true" id="lockmodal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <ng-container *ngIf="empset1 !== null">
              <ul>
                <li *ngFor="let emp of empset1; let i = index">
                  {{i + 1}}. {{emp.n}} {{emp.m}} - <span style="color: red; font-style:italic;">{{emp.o}}</span><span *ngIf="emp.p == 1">{{translate('specialSalaryProcess')}}</span>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="empset1 == null && empset4 !== null && processWarning">
              <ul>
                <li *ngFor="let emp of empset4; let i = index">
                  {{i + 1}}. {{emp.n}} {{emp.m}} - <span style="color: orange; font-style:italic;">{{emp.o}}</span><span *ngIf="emp.p == 1">{{translate('specialSalaryProcess')}}</span>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="empset1 == null && empset2 !== null && chequeWarning">
              <ul>
                <li *ngFor="let emp of empset2; let i = index">
                  {{i + 1}}. {{emp.n}} {{emp.m}}
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="empset1 == null && empset2 == null && empset5 !== null && chequeWarning">
              <ul>
                <li *ngFor="let emp of empset5; let i = index">
                  {{i + 1}}. {{emp.n}} {{emp.m}}
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="empset1 == null && empset2 == null && empset5 == null && empset6 !== null && chequeWarning">
              <ul>
                <li *ngFor="let emp of empset6; let i = index">
                  {{i + 1}}. {{emp.n}} {{emp.m}}
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="empset1 == null && empset2 == null && empset5 == null && empset6 == null && empset7 !== null && chequeWarning">
              <ul>
                <li *ngFor="let emp of empset7; let i = index">
                  {{i + 1}}. {{emp.n}} {{emp.m}}
                </li>
              </ul>
            </ng-container>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" *ngIf="empset1 !== null"><span transloco="ok"></span></button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onClickWarningOK()"
                    *ngIf="empset1 == null && (empset4 !== null || empset2 !== null || empset5 !== null || empset6 !== null || empset7 !== null)">
              OK
            </button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    *ngIf="empset1 == null && (empset4 !== null || empset2 !== null || empset5 !== null || empset6 !== null || empset7 !== null)">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="redirectModal" aria-hidden="true" id="redirectModal" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12 wrap-item-center">
                <span>{{translate('validations.periodPostedAndLocked')}}</span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="d-grid gap-2 col-6 mx-auto">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onRedirect(1)">{{translate('createGlRecords')}}</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onRedirect(2)">{{translate('generateReports')}}</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onRedirect(3)">{{translate('processWizard')}}</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onRedirect(4)">{{translate('returnToDashboard')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Error Modal -->
  <ng-container>
    <div class="modal fade" id="ErrorModal" tabindex="-1" role="dialog" aria-labelledby="ErrorModal" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h6>
              {{translate('validations.cannotPost')}}
            </h6>
            <ul>
              <li *ngFor="let emp of empListWithError">
                {{emp.b}}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="bluebtn" data-bs-dismiss="modal"><span transloco="ok"></span></button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
