import {Component, OnInit} from '@angular/core';
import {AuthBroadcastMessage, AuthenticationService} from '@shared/services';
import {Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.authService.authBroadcast.onmessage = (ev: MessageEvent<AuthBroadcastMessage>) => {
      if (ev.origin === window.origin && ev.data.sessionId !== this.authService.sessionId) {
        switch (ev.data?.message) {
          case 'login':
            this.router.navigate(['/']).then(() => window.location.reload());
            break;
          case 'hmacLogout':
            this.authService.hmacLogout();
            this.router.navigate(['/login']).then(() => window.location.reload());
            break;
          case 'logout':
            this.authService.logout();
            this.router.navigate(['/login']).then(() => window.location.reload());
            break;
        }
      }
    };
  }
}
