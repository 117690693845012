<ng-container *transloco="let translate; read: 'payrollCategoryEntitlmentEmployeeWise'">
  <div class="row"><h4 class="col">{{translate('assignPayrollCategoriesToEmployees')}}</h4></div>
  <div class="card">
    <div class="card-body">
      <div class="row justify-content-between mb-2">
        <div class="col-sm-4">
          <button class="btn btn-primary" openModal modalTarget="addcat" [disabled]="!empSelected"><i class="fas fa-plus"></i>&nbsp;<span transloco="add"></span> </button>
        </div>
        <div class="col-sm-4 d-flex justify-content-end" *ngIf="wizardService.onWizardMode">
          <a *ngIf="empIdAdj!=0 && empSelected"
             [routerLink]="['/process/processwizard/wizard/PayrollProcess', empSelected.prgc]">
            <button class="btn btn-outline-secondary" type="button">{{translate('backToProcess')}}</button>
          </a>
        </div>
      </div>
      <div class="row justify-content-center mb-2">
        <div class="col-auto">
          <img [src]="empProfilePath ? empProfilePath : 'assets/images/avatar/Male-Avatar.png'"
               onerror="javascript:this.src='assets/images/avatar/Male-Avatar.png'" class="img-fluid avatar-sm border rounded-circle" alt="Employee Image" />
        </div>
        <div class="col-sm-6 d-flex align-items-center">
          <kendo-combobox type="search"
                          name="emplist"
                          id="emplist"
                          class="flex-grow-1"
                          [(ngModel)]="empSelected"
                          (valueChange)="onEmpSelect(empSelected&&empSelected.ie ? empSelected.ie : null, externalp)"
                          required
                          #emplist="ngModel"
                          [data]="empList"
                          [textField]="'a'"
                          [valueField]="'ie'"
                          [filterable]="true"
                          (filterChange)="handleFilterEmp($event)"
                          [suggest]="true"
                          [placeholder]="translate('pleaseSelectAnEmployee')"></kendo-combobox>
        </div>
        <div class="col-auto d-flex align-items-center">
          <div class="btn-group" dropdown container="body" placement="bottom left">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
              <i class="fas fa-filter"></i><span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let listItem of dropdownListValues" (click)="onSelectList(listItem.name)">
                <span class="dropdown-item">{{listItem.name}}</span>
              </li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><span class="dropdown-item" (click)="onSelectList('')"><span transloco="clearFilter"></span></span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-5">
        <div class="col-sm-8 d-flex justify-content-center">
          <button class="btn btn-outline-primary btn-sm me-2" (click)="filterPcListByDate(empSelected.s, empSelected.e, 3)" [disabled]="empSelected==null">
            <i class="mdi mdi-text-box-search"></i>{{translate('showAll')}}
          </button>
          <button class="btn btn-outline-primary btn-sm" (click)="filterPcListByDate(empSelected.s, empSelected.e, 0)" [disabled]="empSelected==null">
            <i class="mdi mdi-calendar-clock"></i>{{translate('showForThePeriod')}}
          </button>
          <span class="px-3 py-1">{{empDateRange}}</span>
          <button class="btn btn-outline-primary btn-sm" (click)="onLrflgClick(2)" type="button" [disabled]="!empSelected">
            <i class="fas fa-ellipsis-h"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <!--Grid Starts-->
          <div class="row justify-content-between">
            <div class="col-sm-10">
              <div class="row">
                <div class="col-sm-4">
                  <div class="row">
                    <label class="col-form-label col-sm-6" for="daily-hours">{{translate('dailyHours')}}</label>
                    <div class="col-sm-6">
                      <input class="form-control" type="number" step="0.05" id="daily-hours" name="daily-hours" [(ngModel)]="daily_hrs" (input)="hoursChange()"
                             [disabled]="!empSelected" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-8" *ngIf="is_unit_enabled">
                  <div class="row">
                    <label for="working-hours" class="col-form-label col-sm-5">{{translate('workingHoursPerPeriod')}}</label>
                    <div class="col-sm-4">
                      <input class="form-control" type="number" step="0.05" id="working-hours" name="working-hours" [(ngModel)]="working_hrs"
                             (input)="hoursChange()" [disabled]="!empSelected" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-success" (click)="onHoursSave()" [disabled]="!hours_edited"><i class="fas fa-save"></i></button>
            </div>
          </div>
          <table *ngIf="empSelected" class="table table-striped table-scroll table-responsive">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>{{translate('openingAmount')}}</th>
                <th>{{translate('openingUnits')}}</th>
                <th><i class="fas fa-building"></i></th>
                <th><i class="fas fa-user-tie"></i></th>
                <th><i class="fas fa-th"></i></th>
                <th>{{translate('minMax')}}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody style="height: 42vh">
              <tr *ngFor="let p of payrollCatList">
                <td>
                  <div class="list-icon" [ngClass]="p.tc | payrollCategoryClass : p.t">
                    <i class="{{p.tc | payrollCategoryIcon}}"></i>
                  </div>
                </td>
                <td>
                  <span>{{p.a}}</span>
                </td>
                <td>
                  <ng-container *ngIf="p.t==2">
                    <span><i class="mdi mdi-timer-outline"></i></span>
                    <span class="px-2">{{p.pf | date: sysPref?.df ? sysPref?.df : 'yyyy/MM/dd'}}- {{p.pt | date:sysPref?.df ? sysPref?.df : 'yyyy/MM/dd'}}</span>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="(p.e == 7)">

                    <!--{{p.oa}}-->
                    {{p.iu != 1 ? (p.oa | number:'1.' + sysPref.g + '-' + sysPref.g) : (p.oa | number:'1.2-2')}}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="(p.e == 7) && (p.iu == 1 || p.iu==5)">

                    <!--{{p.ou}}-->
                    {{p.iu == 1 ? (p.ou | number:'1.' + sysPref.g + '-' + sysPref.g) : (p.ou | number:'1.2-2')}}
                  </ng-container>
                </td>
                <td>
                  <span *ngIf="p.e==3 || p.e==8">{{p.iu == 1 ? (p.ya | number:'1.' + sysPref.h + '-' + sysPref.h) : (p.ya | number:'1.' + sysPref.g + '-' + sysPref.g)}}</span>
                </td>
                <td>
                  {{p.iu == 1 ? (p.ea | number:'1.' + sysPref.g + '-' + sysPref.g) : (p.ea | number:'1.' + sysPref.g + '-' + sysPref.g)}}
                </td>
                <td>
                  <ng-container *ngIf="(p.tc!=15) && (p.iu==1 || p.iu==5)">{{p.u | number:'1.' + sysPref.h + '-' + sysPref.h}}</ng-container>
                </td>
                <td>
                  <div *ngIf="p.iu==5">
                    <div class="row">
                      <div class="col-12">
                        <span>{{p.emax | number: '1.2-2' }} - {{translate('max')}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <span>{{p.emin | number: '1.2-2' }} - {{translate('min')}}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="p.iu!=5">
                    <ng-container *ngIf="p.maxf==1; else minTemp">
                      <span>{{p.emax | number: '1.2-2' }} - {{translate('max')}}</span>
                    </ng-container>
                    <ng-template #minTemp>
                      <ng-container *ngIf="p.minf==1; else empty">
                        <span>{{p.emin | number: '1.2-2' }} - {{translate('min')}}</span>
                      </ng-container>
                    </ng-template>
                    <ng-template #empty><span></span></ng-template>
                  </div>
                </td>
                <td>
                  <!--<a data-bs-toggle="modal" data-bs-target="#editcat" (click)="onPcEdit(p.dc)"><img src="/assets/image_system/add new 2.png" class="icon-Size me-2" /></a>-->
                  <button class="btn btn-outline-secondary btn-sm me-2 mb-2" openModal modalTarget="editcat" (click)="onPcEdit(p.dc)">
                    <i class="fas fa-pencil-alt"></i>
                  </button>
                  <button class="btn btn-outline-danger btn-sm mb-2" (click)="delete(p.dc, p.ei)"><i class="fas fa-trash"></i></button>
                </td>
                <td>
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input mb-2" id="id{{p.dc}}" [checked]="p.ie==1" (change)="setEmpStatus($event, p.dc)" />
                    <label class="form-check-label" for="id{{p.dc}}"></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--Grid Ends-->
        </div>
      </div>
    </div>
  </div>

  <!--AddNew-->
  <div class="modal le fade in" id="addcat" tabindex="-1" role="dialog" aria-labelledby="addcatLabel" data-bs-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <b *ngIf="empSelected">{{empSelected.a}}</b>
          <button type="button" class="btn-close" closeModal modalTarget="addcat" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="row">
              <div class="col-12 mb-2">
                <label class="form-label">{{translate('payrollCategory')}}</label>
                <kendo-combobox type="search" name="pclist" id="pclist" class="form-control p-0" [(ngModel)]="addNewPc" (valueChange)="onPcSelect(addNewPc.o)"
                                ngModel required #emplist="ngModel"
                                [data]="filteredPcList"
                                [textField]="'a'"
                                [valueField]="'o'"
                                [filterable]="true"
                                (filterChange)="handleFilterCat($event)"
                                [suggest]="true"
                                [placeholder]="translate('pleaseSelectAnPayrollCategory')">
                </kendo-combobox>
              </div>
            </div>
            <ng-container *ngIf="addNewPc">
              <!-- E,D,X,L-F-SAL -->
              <!--if basis is script then Amount will be available-->
              <ng-container *ngIf="(addNewPc.iu==0 && ((addNewPc.p==1 && addNewPc.t!=3) || addNewPc.p==2 || addNewPc.p==5 || addNewPc.p==6  || addNewPc.p==4 || addNewPc.p==7)) || (addNewPc.p==8 && addNewPc.iu==0) || (addNewPc.iu==5)">
                <div class="row">
                  <div class="col-12 mb-2">
                    <label class="form-label" *ngIf="addNewPc.iu!=5"><span transloco="amount"></span></label>
                    <label class="form-label" *ngIf="addNewPc.iu==5">{{translate('amountRate')}}</label>
                    <ng-container *ngIf="addNewPc.pbeyr == 0 || addNewPc.pbeyr == null">
                      <input class="form-control" [(ngModel)]="obj.ea" [pattern]="regex" #eaVal1="ngModel"
                             [disabled]="addNewPc.paf==1" name="flatamt" type="number" min="0" step="0.01" required />
                      <div *ngIf="eaVal1.invalid && (eaVal1.dirty || eaVal1.touched)" class="invalid-feedback">
                        <span *ngIf="eaVal1.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="addNewPc.pbeyr == 1">
                      <input class="form-control" [(ngModel)]="obj.ya" [pattern]="regex" #yaVal11="ngModel"
                             [disabled]="addNewPc.paf==1" name="flatamt" type="number" min="0" step="0.01" required />
                      <div *ngIf="yaVal11.invalid && (yaVal11.dirty || yaVal11.touched)" class="invalid-feedback">
                        <span *ngIf="yaVal11.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="col-12 mb-2" *ngIf="addNewPc.p == 7">
                    <label class="form-label">{{translate('openingAmount')}}</label>
                    <input class="form-control" [(ngModel)]="obj.oa" [pattern]="regex" #oaVal1="ngModel"
                           name="openamnt" type="number" min="0" step="0.01" required />
                    <div class="invalid-feedback" *ngIf="oaVal1.invalid && (oaVal1.dirty || oaVal1.touched)">
                      <span *ngIf="oaVal1.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row" *ngIf="(addNewPc.p==8 && addNewPc.iu==3)">
                <div class="col-12 mb-2">
                  <label class="form-label"><span transloco="amount"></span></label>
                  <ng-container *ngIf="addNewPc.pbeyr == 0 || addNewPc.pbeyr == null">
                    <input class="form-control" [(ngModel)]="obj.ea" [pattern]="regex" #oaVal2="ngModel"
                           [disabled]="addNewPc.paf==1 || (addNewPc.fc==2 ||addNewPc.fc==3 ||addNewPc.fc==4 ||addNewPc.fc==5 ||addNewPc.fc==6 ||addNewPc.fc==7)"
                           name="flatamt" type="number" min="0" step="0.01" required />
                    <div class="invalid-feedback" *ngIf="oaVal2.invalid && (oaVal2.dirty || oaVal2.touched)">
                      <span *ngIf="oaVal2.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="addNewPc.pbeyr == 1">
                    <input class="form-control" [(ngModel)]="obj.ya" [pattern]="regex" #yaVal1="ngModel"
                           [disabled]="addNewPc.paf==1 || (addNewPc.fc==2 ||addNewPc.fc==3 ||addNewPc.fc==4 ||addNewPc.fc==5 ||addNewPc.fc==6 ||addNewPc.fc==7)"
                           name="flatamt" type="number" min="0" step="0.01" required />
                    <div class="invalid-feedback" *ngIf="yaVal1.invalid && (yaVal1.dirty || yaVal1.touched)">
                      <span *ngIf="yaVal1.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Con-F -->
              <ng-container *ngIf="addNewPc.p==3 || addNewPc.p==8 && addNewPc.iu==2">
                <ng-container *ngIf="addNewPc.p==8; else contribution">
                  <div class="row">
                    <div *ngIf="!addNewPc.pbeyr" class="col-12 mb-2">
                      <label class="form-label">{{translate('percentage')}}</label>
                      <input [(ngModel)]="obj.ea" name="empPercent" #empPercentCtr="ngModel" [pattern]="regex" [min]="0" [max]="100"
                             [disabled]="addNewPc.paf==1" step="0.01" type="number" class="form-control" />
                      <div *ngIf="empPercentCtr.invalid && (empPercentCtr.dirty || empPercentCtr.touched)">
                        <span *ngIf="empPercentCtr?.errors?.min || empPercentCtr?.errors?.max">{{translate('validations.percentageMustBetween0100')}}</span>
                        <span *ngIf="empPercentCtr.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </div>
                    <div *ngIf="addNewPc.pbeyr" class="col-12 mb-2">
                      <label class="form-label">{{translate('percentage')}}</label>
                      <input [(ngModel)]="obj.ya" name="eyrPercent" #eyrPercentCtr="ngModel" [pattern]="regex" [min]="0" [max]="100"
                             [disabled]="addNewPc.yaf==1" step="0.01" type="number" class="form-control" />
                      <div class="invalid-feedback" *ngIf="eyrPercentCtr.invalid && (eyrPercentCtr.dirty || eyrPercentCtr.touched)">
                        <span *ngIf="eyrPercentCtr.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                        <span *ngIf="eyrPercentCtr?.errors?.min || eyrPercentCtr?.errors?.max">{{translate('percentageMustBetween0100')}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #contribution>
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label" *ngIf="addNewPc.iu==0"><span transloco="amount"></span></label>
                      <label class="form-label" *ngIf="addNewPc.iu==2">{{translate('percentage')}}</label>
                    </div>
                    <div class="col-6 mb-2">
                      <input type="text" [(ngModel)]="obj.ea" [pattern]="regex" #eaVal="ngModel"
                             [disabled]="addNewPc.paf==1 || (addNewPc.fc==2 ||addNewPc.fc==3 ||addNewPc.fc==4 ||addNewPc.fc==5 ||addNewPc.fc==6 ||addNewPc.fc==7)"
                             name="empAmt" class="form-control" required />
                      <div class="invalid-feedback" *ngIf="eaVal.invalid && (eaVal.dirty || eaVal.touched)">
                        <span *ngIf="eaVal.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <input [pattern]="regex" [(ngModel)]="obj.ya" #yaval="ngModel"
                             [disabled]="addNewPc.yaf==1 || (addNewPc.fc==2 ||addNewPc.fc==3 ||addNewPc.fc==4 ||addNewPc.fc==5 ||addNewPc.fc==6 ||addNewPc.fc==7)"
                             step="0.01" name="eyrAmt" type="number" class="form-control" required />
                      <div class="invalid-feedback" *ngIf="yaval.invalid && (yaval.dirty || yaval.touched)">
                        <span *ngIf="yaval.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <!-- E,D,X,L-F-UNIT -->
              <ng-container *ngIf="(addNewPc.iu==1 && (addNewPc.p==1 || addNewPc.p==2 || addNewPc.p==5 || addNewPc.p==6 || addNewPc.p==4 || addNewPc.p==7 || addNewPc.p==8)) || (addNewPc.iu==5)">
                <ng-container *ngIf="addNewPc.iu!=5">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <label class="form-label"><span transloco="rate"></span></label>
                      <ng-container *ngIf="addNewPc.pbeyr == 0 || addNewPc.pbeyr == null">
                        <input [pattern]="regex1" #eaval="ngModel" [(ngModel)]="obj.ea" [disabled]="addNewPc.paf==1"
                               class="form-control" name="rate" type="number" min="0" step="0.01" required />
                        <div class="invalid-feedback" *ngIf="eaval.invalid && (eaval.dirty || eaval.touched)">
                          <span *ngIf="eaval.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe') + sysPref.g}}</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="addNewPc.pbeyr == 1">
                        <input [pattern]="regex1" #yaVal="ngModel" [(ngModel)]="obj.ya" [disabled]="addNewPc.paf==1"
                               class="form-control" name="rate" type="number" min="0" step="0.01" required />
                        <div class="invalid-feedback" *ngIf="yaVal.invalid && (yaVal.dirty || yaVal.touched)">
                          <span *ngIf="yaVal.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe') + sysPref.g}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <!--if basis is script then Unit will be available-->
                <ng-container *ngIf="((addNewPc.p==1 && addNewPc.t!=3) || addNewPc.p==2 || addNewPc.p==5 || addNewPc.p==6 || addNewPc.p==4 || addNewPc.p==7 || addNewPc.p==8) || (addNewPc.iu==5)">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <label class="form-label"><span transloco="units"></span></label>
                      <input [pattern]="regex2" #uVal="ngModel" [(ngModel)]="obj.u" class="form-control"
                             name="unit" type="number" min="0" step="1" required />
                      <div class="invalid-feedback" *ngIf="uVal.invalid && (uVal.dirty || uVal.touched)">
                        <span *ngIf="uVal.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe') + sysPref.h}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="addNewPc.p == 7">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <label class="form-label">{{translate('openingUnit')}}</label>
                      <input class="form-control" [(ngModel)]="obj.ou" name="openunit" type="number" min="0"
                             step="0.01" required />
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <!--if basis is script then Maximum will be available-->
              <ng-container *ngIf="addNewPc.maxf==1 || addNewPc.iu==5">
                <div class="row">
                  <div class="col-sm-12 mb-2">
                    <label class="form-label"><span transloco="maximum"></span></label>
                    <input [(ngModel)]="addNewPc.emax" #maxEdit="ngModel" [pattern]="regex" [disabled]="addNewPc.maxw == 1" step="0.01" min="0"
                           name="maxEdit" type="number" class="form-control" required />
                    <div class="invalid-feedback" *ngIf="maxEdit.invalid && (maxEdit.dirty || maxEdit.touched)">
                      <span *ngIf="maxEdit.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!--if basis is script then Minimum will be available-->
              <ng-container *ngIf="addNewPc.minf==1 || addNewPc.iu==5">
                <div class="row">
                  <div class="col-sm-12 mb-2">
                    <label class="form-label"><span transloco="minimum"></span></label>
                    <input [(ngModel)]="addNewPc.emin" #minEdit="ngModel" [pattern]="regex" [disabled]="addNewPc.minw == 1" step="0.01" min="0"
                           name="minEdit" type="number" class="form-control" required />
                    <div class="invalid-feedback" *ngIf="minEdit.invalid && (minEdit.dirty || minEdit.touched)">
                      <span *ngIf="minEdit.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!--Variable Show Period-->
              <ng-container *ngIf="addNewPc.t==2">
                <div class="row">
                  <div class="col-12 d-flex align-items-center">
                    <span><i class="fas fa-calendar p-2"></i></span>
                    <span class="p-2" *ngIf="empSelected">{{dateRange}}</span>
                    <span class="p-2" *ngIf="!empSelected">yyyy-mm-dd-yyyy-mm-dd</span>
                    <button class="btn btn-sm btn-outline-secondary" (click)="onLrflgClick(0)" type="button" closeModal modalTarget="addcat">
                      <i class="fas fa-ellipsis-h"></i>
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" [disabled]="((f.dirty || f.touched) && !f.valid)" (click)="onSubmit(f)" closeModal modalTarget="addcat">
            <i class="fas fa-save"></i>&nbsp;<span transloco="save"></span>
          </button>
          <button class="btn btn-warning" type="reset" closeModal modalTarget="addcat" (click)="cancelAdd()"><i class="fas fa-ban"></i>&nbsp;<span transloco="cancel"></span></button>
        </div>
      </div>
    </div>
  </div>

  <!--Edit Modal-->
  <div class="modal ri fade in" id="editcat" tabindex="-1" role="dialog" aria-labelledby="editcatLabel" data-bs-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <b *ngIf="empSelected">{{empSelected.a}}</b>
          <button type="button" class="btn-close" closeModal modalTarget="editcat" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form #ed="ngForm" (ngSubmit)="edit()">
            <div class="row mb-3">
              <div class="col-12">
                {{translate('payrollCategory')}}
              </div>
            </div>
            <ng-container *ngIf="pcSelected">
              <div class="row">
                <div class="col-auto">
                  <div class="list-icon" [ngClass]="pcSelected.e | payrollCategoryClass : pcSelected.t">
                    <i class="{{pcSelected.e | payrollCategoryIcon}}"></i>
                  </div>
                </div>
                <div class="col-8 d-flex align-items-center">{{pcSelected.a}}</div>
              </div>
              <!-- E,D,X,L,BS,AC,IT-F-SAL -->
              <!--if basis is script then Amount will be available-->
              <ng-container *ngIf="((pcSelected.iu==0 && ((pcSelected.e==1 && pcSelected.t!=3) || pcSelected.e==2 || pcSelected.e==5 
                     || pcSelected.e==6 || pcSelected.e==4 || pcSelected.e==7)) || (pcSelected.e==8 && pcSelected.iu ==0)) || (pcSelected.iu==1 && pcSelected.e==7) || (pcSelected.iu==5)">
                <div class="row">
                  <div class="col-12" *ngIf="pcSelected.iu!=1">
                    <label class="form-label" *ngIf="pcSelected.iu!=5"><span transloco="amount"></span></label>
                    <label class="form-label" *ngIf="pcSelected.iu==5">{{translate('amountRate')}}</label>
                    <input [(ngModel)]="pcSelected.ea" [pattern]="regex" #eaval4="ngModel" [disabled]="pcSelected.paf==1"
                           class="form-control" name="flatamt" type="number" min="0" step="0.01"
                           maxlength="10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                           required />
                    <div class="invalid-feedback" *ngIf="eaval4.invalid && (eaval4.dirty || eaval4.touched)">
                      <span *ngIf="eaval4.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="pcSelected.e==7">
                  <div class="row">
                    <div class="col-12">
                      <label>{{translate('openingAmount')}}</label>
                      <input [(ngModel)]="pcSelected.oa" [pattern]="regex" #oaval4="ngModel" class="form-control" (change)="opnAmtChanged = 1"
                             name="opeamt" type="number" min="0" step="0.01" maxlength="10"
                             oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" required />
                      <div class="invalid-feedback" *ngIf="oaval4.invalid && (oaval4.dirty || oaval4.touched)">
                        <span *ngIf="oaval4.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <!-- Con-F -->
              <ng-container *ngIf="pcSelected.e==3 || pcSelected.e==8 && pcSelected.iu==2">
                <ng-container *ngIf="pcSelected.e==8; else contributionEdit">
                  <div class="row">
                    <div *ngIf="!pcSelected.pbeyr" class="col-12">
                      <label class="form-label">{{translate('percentage')}}</label>
                      <input [pattern]="regex1" [(ngModel)]="pcSelected.ea" name="empEdit" #empEditPercentCtr="ngModel"
                             [disabled]="pcSelected.paf==1" [min]="0" [max]="100" type="number" class="form-control" />
                      <div class="invalid-feedback" *ngIf="empEditPercentCtr.invalid && (empEditPercentCtr.dirty || empEditPercentCtr.touched)">
                        <span *ngIf="empEditPercentCtr?.errors?.min || empEditPercentCtr?.errors?.max">{{translate('percentageMustBetween0100')}}</span>
                        <span *ngIf="empEditPercentCtr.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe') + sysPref.g}}.</span>
                      </div>
                    </div>
                    <div *ngIf="pcSelected.pbeyr" class="col-12">
                      <input [pattern]="regex1" [(ngModel)]="pcSelected.ya" name="eyrEdit" #eyrEditPercentCtr="ngModel"
                             [disabled]="pcSelected.yaf==1" [min]="0" [max]="100" step="0.01" type="number" class="form-control" />
                      <div class="invalid-feedback" *ngIf="eyrEditPercentCtr.invalid && (eyrEditPercentCtr.dirty || eyrEditPercentCtr.touched)">
                        <span *ngIf="eyrEditPercentCtr?.errors?.min || eyrEditPercentCtr?.errors?.max">{{translate('percentageMustBetween0100')}}</span>
                        <span *ngIf="eyrEditPercentCtr.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe') + sysPref.g}}.</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #contributionEdit>
                  <div class="row">
                    <div class="col-12">
                      <label *ngIf="pcSelected?.iu==0">{{translate('employeeAmount')}}</label>
                      <label *ngIf="pcSelected?.iu==2">{{translate('employeePercentage')}}</label>
                    </div>
                    <div class="col-6">
                      <input [pattern]="regex" #empAmtval="ngModel" [(ngModel)]="pcSelected.ea" [disabled]="true" step="0.01" name="empAmt" type="number"
                             class="form-control" />
                      <!--(pcSelected.paf==1 || (pcSelected?.fc==2 || pcSelected?.fc==3 || pcSelected?.fc==4 || pcSelected?.fc==5 ||pcSelected?.fc==6 || pcSelected?.fc==7))? true : false-->
                      <div class="invalid-feedback" *ngIf="empAmtval.invalid && (empAmtval.dirty || empAmtval.touched)">
                        <span *ngIf="empAmtval.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <input [pattern]="regex" #eyrAmtval="ngModel" #eyrEditPercentCtr="ngModel" [(ngModel)]="pcSelected.ya" [disabled]="true" step="0.01"
                             name="eyrAmt" type="number" class="form-control" />
                      <!--(pcSelected.yaf==1 || (pcSelected?.fc==2 || pcSelected?.fc==3 || pcSelected?.fc==4 || pcSelected?.fc==5 ||pcSelected?.fc==6 || pcSelected?.fc==7))? true : false-->
                      <div class="invalid-feedback" *ngIf="eyrAmtval.invalid && (eyrAmtval.dirty || eyrAmtval.touched)">
                        <span *ngIf="eyrAmtval.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <!-- E,D,X,L,BS,AC-F-UNIT -->
              <!--if basis is script then Unit will be available-->
              <ng-container *ngIf="pcSelected.iu==1 || pcSelected.iu==5">
                <ng-container *ngIf="(pcSelected.e==1 || pcSelected.e==2 || pcSelected.e==5 || pcSelected.e==6 || pcSelected.e==4 || pcSelected.e==7 || pcSelected.e==8) && (pcSelected.iu!=5)">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label"><span transloco="rate"></span></label>
                      <input [pattern]="regex1" #rateval="ngModel" [(ngModel)]="pcSelected.ea" [disabled]="pcSelected.paf==1 ? true : false"
                             class="form-control" name="rate" type="number" min="0" required />
                      <div class="invalid-feedback" *ngIf="rateval.invalid && (rateval.dirty || rateval.touched)">
                        <span *ngIf="rateval.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe') + sysPref.g}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="((pcSelected.e==1 && pcSelected.t!=3) || pcSelected.e==2 || pcSelected.e==5 || pcSelected.e==6 || pcSelected.e==4 || pcSelected.e==7 || pcSelected.e==8)">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label"><span transloco="units"></span></label>
                      <input [pattern]="regex2" #unitvalue="ngModel" [(ngModel)]="pcSelected.u" class="form-control"
                             name="units" type="number" min="0" step="1" required />
                      <div class="invalid-feedback" *ngIf="unitvalue.invalid && (unitvalue.dirty || unitvalue.touched)">
                        <span *ngIf="unitvalue.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe') + sysPref.h}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="pcSelected.e==7">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label">{{translate('openingUnit')}}</label>
                      <input [(ngModel)]="pcSelected.ou" class="form-control" name="opeunit" type="number" (change)="opnAmtChanged = 1"
                             min="0" step="0.01" pattern="^\d*(\.\d{0,2})?$" maxlength="7"
                             oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" required />
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <!--if basis is script then Maximum will be available-->
              <ng-container *ngIf="pcSelected.maxf==1 || pcSelected.iu==5">
                <div class="row">
                  <div class="col-lg-12 mb-2">
                    <label class="form-label"><span transloco="maximum"></span></label>
                    <input [(ngModel)]="pcSelected.emax" #maxEdit="ngModel" [pattern]="regex" [disabled]="pcSelected.maxw == 1" step="0.01"
                           min="0" name="maxEdit" type="number" class="form-control" required />
                    <div class="invalid-feedback" *ngIf="maxEdit.invalid && (maxEdit.dirty || maxEdit.touched)">
                      <span *ngIf="maxEdit.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!--if basis is script then Minimum will be available-->
              <ng-container *ngIf="pcSelected.minf==1 || pcSelected.iu==5">
                <div class="row">
                  <div class="col-lg-12 mb-2">
                    <label class="form-label"><span transloco="minimum"></span></label>
                    <input [(ngModel)]="pcSelected.emin" #minEdit="ngModel" [pattern]="regex" [disabled]="pcSelected.minw == 1" step="0.01"
                           min="0" name="minEdit" type="number" class="form-control" required />
                    <div class="invalid-feedback" *ngIf="minEdit.invalid && (minEdit.dirty || minEdit.touched)">
                      <span *ngIf="minEdit.errors?.pattern">{{translate('validations.maximumDecimalsShouldBe2')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!--Variable Show Period-->
              <ng-container *ngIf="pcSelected.t==2">
                <div class="row p-2">
                  <div class="col-12 d-flex align-items-center">
                    <i class="fas fa-calendar p-2"></i>
                    <span class="p-2"> {{dateRangeEdit}}</span>
                    <button class="btn btn-sm btn-outline-secondary" type="button" (click)="onLrflgClick(1)" closeModal modalTarget="editcat">
                      <i class="fas fa-ellipsis-h"></i>
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" [disabled]="((ed.dirty && !ed.valid) || (pcSelected != null && pcSelected.iu == 4))" (click)="edit()"
                  closeModal modalTarget="editcat">
            <i class="fas fa-save"></i>&nbsp;<span transloco="save"></span>
          </button>
          <button class="btn btn-warning" type="button" closeModal modalTarget="editcat" (click)="cancelEdit()"><i class="fas fa-ban"></i>&nbsp;<span transloco="cancel"></span></button>
        </div>
      </div>
    </div>
  </div>

  <!-- Date Edit Modal Starts -->
  <div [ngClass]="(lrflg==0)?'modal le fade in':'modal ri fade in'" id="dateEditModalLeft" tabindex="-1" role="dialog"
       aria-labelledby="dateEditModalLeftLabel"
       data-bs-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="empDateEditLabel">{{translate('selectOnePeriod')}}</h5>
          <button type="button" (click)="cancelDateSelection();" class="btn-close" closeModal modalTarget="dateEditModalLeft" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!--DATE SELECTION STARTS-->
          <div class="row" *ngIf="isOnDateSlt">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <table class="table table-scroll">
                <thead>
                  <tr>
                    <th>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [checked]="allChecked" (change)="dateToggleAll($event)" />
                      </div>
                    </th>
                    <th>{{translate('startDate')}}</th>
                    <th>{{translate('endDate')}}</th>
                  </tr>
                </thead>

                <tbody style="height: 35vh">
                  <ng-container *ngIf="empSelected">
                    <tr *ngFor="let s of payScheduleForDftEmps">
                      <td *ngIf="s.ie == empSelected.ie"><input type="checkbox" [checked]="s.isChecked" (change)="checkOnClick($event, s.f, s.i)" /></td>
                      <td *ngIf="s.ie == empSelected.ie">{{s.s | date: sysPref?.df ? sysPref?.df : 'yyyy/MM/dd'}}</td>
                      <td *ngIf="s.ie == empSelected.ie">{{s.e | date: sysPref?.df ? sysPref?.df : 'yyyy/MM/dd'}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <!--DATE SELECTION ENDS-->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" [disabled]="checkedSchedules.length==0" (click)="processDateSelection();" closeModal modalTarget="dateEditModalLeft">
            <i class="fas fa-save"></i>&nbsp;<span transloco="save"></span>
          </button>
          <button type="reset" class="btn btn-warning" aria-label="Close" closeModal modalTarget="dateEditModalLeft" (click)="cancelDateSelection();">
            <i class="fas fa-ban"></i>&nbsp;<span transloco="cancel"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Date Edit Modal Ends-->
</ng-container>










