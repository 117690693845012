import {DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '@shared/services';
import {CookieService} from 'ngx-cookie-service';
import {SalaryProcessMessageService} from '@services/salary-process-message.service';
import {IPayrollProcessUpdate, ProcessSummary} from '@shared/models/ipayroll-group';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
  //styleUrls: ['./topbar.component.scss'],
  //providers: [{
  //  provide: TRANSLOCO_SCOPE,
  //  useValue: {scope: 'header', loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))}
  //}]
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  userName: string;

  backgroundProcessPopDownShow = false;
  backgroundProcessProgressCount: number = 2;
  payrollProcessProgress: IPayrollProcessUpdate = null;
  showPayrollProgressBadge = false;
  backgroundProcessProgress: any[] = [4];

  processSummary: ProcessSummary = null;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    // public languageService: LanguageService,
    public cookiesService: CookieService,
    private salaryProcessMessage: SalaryProcessMessageService
  ) {
  }

  listLang = [
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    {text: 'French', flag: 'assets/images/flags/french.jpg', lang: 'fr'}
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.salaryProcessMessage.payrollProcessProgress$.pipe(untilDestroyed(this)).subscribe(res => {
      this.payrollProcessProgress = res;
      this.showPayrollProgressBadge = !!this.payrollProcessProgress?.status;
      if (this.payrollProcessProgress?.percentage === 100) {
        this.clearPayrollProgressIcon();
      }
    });

    this.salaryProcessMessage.processSummary$.pipe(untilDestroyed(this)).subscribe((res: ProcessSummary) => this.processSummary = res);
    this.openMobileMenu = false;
    this.element = document.documentElement;
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.authService.currentUser.subscribe(user => this.userName = user?.email ?? 'User');
  }

  setLanguage(text: string, lang: string, flag: string) {
    // this.countryName = text;
    // this.flagvalue = flag;
    // this.cookieValue = lang;
    // this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  onOpenProcessQueue() {
    this.backgroundProcessPopDownShow = true;
    this.showPayrollProgressBadge = this.payrollProcessProgress?.status !== 'Completed';
  }

  private clearPayrollProgressIcon() {
    if (this.backgroundProcessPopDownShow) {
      this.showPayrollProgressBadge = false;
    }
  }

  onProcessQueueDropDownClose($event: boolean) {
    this.backgroundProcessPopDownShow = $event;
    if ($event === false && this.payrollProcessProgress?.percentage === 100) {
      this.salaryProcessMessage.clear();
    }
  }
}
