import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBank} from '@shared/models/ibank';
import {Observable} from 'rxjs';
import {ILocString} from 'src/app/shared/models/iLocalization';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class BankListService {

  constructor(private http: HttpClient) { }

  getBankList(): Observable<IBank[]> {
    return this.http.get<IBank[]>(API_END_POINT.payroll.bank + "GetBankList");
  }
  getBankListForBank(): Observable<IBank[]> {
    return this.http.get<IBank[]>(API_END_POINT.payroll.bank + "GetBankListForBank");
  }
  getBankInterfaces(bankCode: number): Observable<any[]> {
    return this.http.get<any[]>(API_END_POINT.payroll.udf + "GetBankInterfaces?bankCode="+bankCode);
  }
  //getBankByCode(code: number): Observable<IBank[]> {
  //  return this.http.get<IBank[]>(API_END_POINT.payroll.bank + "GetBankByCode?code=" + code);
  //}

  addBank(bankAdd: IBank): Observable<number> {
    //console.log("addBank:"+JSON.stringify(bankAdd))
    return this.http.post<number>(API_END_POINT.payroll.bank + "AddBank", bankAdd);
  }

  updateBank(bankEdit: IBank): Observable<IBank> {
//console.log("editBank:"+JSON.stringify(bankEdit))
    return this.http.post<IBank>(API_END_POINT.payroll.bank + "UpdateBank", bankEdit);
  }

  DeleteBank(bnk_code: number): Observable<ILocString> {
    return this.http.delete<ILocString>(API_END_POINT.payroll.bank + "DeleteBank?bnk_code=" + bnk_code);
  }
}
