import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  IBankFileLines,
  ICustomAccount,
  IDataExport,
  IDefinedAccountSource,
  IDefineDataItem,
  IGLProcess,
  ISourceConfig
} from 'src/app/shared/models/idata-export';
import {API_END_POINT} from 'src/app/shared/utils/api-end-points';


@Injectable({
  providedIn: 'root'
})
export class DataExportService {

  constructor(private http: HttpClient) { }

  getDataExportList(): Observable<IDataExport[]> {

    return this.http.get<IDataExport[]>(API_END_POINT.payroll.dataExport + "GetDataExportList");
  }

  getExportDataByID(id: number): Observable<IDataExport> {
    return this.http.get<IDataExport>(API_END_POINT.payroll.dataExport + "GetExportDataByID?id=" + id);
  }

  getBankNextSeqNum(id: number): Observable<string> {
    return this.http.get<string>(API_END_POINT.payroll.dataExport + "GetBankNextSeqNum?id=" + id);
  }

  getDataItemList(): Observable<IDefineDataItem[]> {
    return this.http.get<IDefineDataItem[]>(API_END_POINT.payroll.dataExport + "GetDataItemList");
  }

  getSelectedDataItemList(id: number): Observable<IDefineDataItem[]> {
    return this.http.get<IDefineDataItem[]>(API_END_POINT.payroll.dataExport + "GetSelectedDataItemList?id=" + id);
  }

  getAccountSourceList(): Observable<IDefinedAccountSource[]> {
    return this.http.get<IDefinedAccountSource[]>(API_END_POINT.payroll.dataExport + "GetAccountSourceList");
  }

  getSelectedAccountSourceList(id: number): Observable<IDefinedAccountSource[]> {
    return this.http.get<IDefinedAccountSource[]>(API_END_POINT.payroll.dataExport + "GetSelectedAccountSourceList?id=" + id);
  }

  getSourceConfigList(id: number): Observable<ISourceConfig[]> {
    return this.http.get<ISourceConfig[]>(API_END_POINT.payroll.dataExport + "GetSourceConfigList?id=" + id);
  }

  addCustomAccount(account: ICustomAccount): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.dataExport + "AddCustomAccount", account);
  }

  updateCustomAccount(account: ICustomAccount): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.dataExport + "UpdateCustomAccount", account);
  }

  //deleteCustomAccount(id: number): Observable<any> {
  //  return this.http.get<number>(API_END_POINT.payroll.dataExport + "DeleteCustomAccount?id=" + id);
  //}

  //getCustomAccountByID(id: number): Observable<ICustomAccount> {
  //  return this.http.get<ICustomAccount>(API_END_POINT.payroll.dataExport + "GetCustomAccountByID?id=" + id);
  //}

  addDataExport(dataExport: IDataExport): Observable<any> {
    return this.http.post<number>(API_END_POINT.payroll.dataExport + "AddDataExport", dataExport);
  }

  updateDataExport(dataExport: any): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.dataExport + "UpdateDataExport", dataExport);
  }

  deleteDataExport(id: number): Observable<any> {
    return this.http.get<void>(API_END_POINT.payroll.dataExport + "DeleteDataExport?id=" + id);
  }

  addDataExportItem(list: IDefineDataItem[]): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.dataExport + "AddDataExportItem", list);
  }

  addDataExportSource(list: IDefinedAccountSource[]): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.dataExport + "AddDataExportSource", list);
  }

  addDataExportSourceConfig(list: ISourceConfig[]): Observable<any> {
    return this.http.post<void>(API_END_POINT.payroll.dataExport + "AddDataExportSourceConfig", list);
  }

  processGLItems(id: number, sd: Date, d: Date, y: number, c: number, a: number, o: number, ge: number, gd: number, gc: number): Observable<IGLProcess[]> {
    let bDate: Date = new Date(d);
    let sDate: Date = new Date(sd);
    let para = new HttpParams().set("id", String(id)).set("sd", sDate.toISOString().substring(0, 10)).set("d", bDate.toISOString().substring(0, 10)).set("y", String(y)).set("c", String(c)).set("a", String(a)).set("o", String(o)).set("ge", String(ge)).set("gd", String(gd)).set("gc", String(gc));
    return this.http.get<IGLProcess[]>(API_END_POINT.payroll.dataExport + "ProcessGLItems", { params: para });
  }

  rollBackGLItems(id: number, sd: Date, d: Date, y: number, c: number, a: number, o: number): Observable<any> {
    let bDate: Date = new Date(d);
    let sDate: Date = new Date(sd);
    let para = new HttpParams().set("id", String(id)).set("sd", sDate.toISOString().substring(0, 10)).set("d", bDate.toISOString().substring(0, 10)).set("y", String(y)).set("c", String(c)).set("a", String(a)).set("o", String(o));
    return this.http.get<void>(API_END_POINT.payroll.dataExport + "RollBackGLItems", { params: para });
  }

  deleteDataExportItems(id: number): Observable<any> {
    return this.http.get<void>(API_END_POINT.payroll.dataExport + "DeleteDataExportItems?id=" + id);
  }

  deleteDataExportSources(id: number): Observable<any> {
    return this.http.get<void>(API_END_POINT.payroll.dataExport + "DeleteDataExportSources?id=" + id);
  }

  deleteDataExportSourceConfigs(id: number): Observable<any> {
    return this.http.get<void>(API_END_POINT.payroll.dataExport + "DeleteDataExportSourceConfigs?id=" + id);
  }

  isGLDetailsEditable(id: number): Observable<any> {
    return this.http.get<number>(API_END_POINT.payroll.dataExport + "IsGLDetailsEditable?id=" + id);
  }

  getBankFileLines(i: number, a: number, p: string, o: number, d: Date, s: string, include: number): Observable<IBankFileLines[]> {
    let bDate: Date = new Date(d);
    let para = new HttpParams().set("i", String(i)).set("a", String(a)).set("p", String(p)).set("o", String(o)).set("d", bDate.toISOString().substring(0, 10)).set("s", String(s)).set("include", String(include));
    //console.log('getBankFileLines -> para');
    //console.log(para);
    return this.http.get<IBankFileLines[]>(API_END_POINT.payroll.dataExport + "GetBankFileLines", { params: para });
  }

}
