import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IPassword} from '@shared/models/iemployee';
import {API_END_POINT} from '@shared/utils/api-end-points';
import {forkJoin, Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private http: HttpClient) { }

  //GetCompanyName(): Observable<any> {
  //  return this.http.get<any>(API_END_POINT.account.global + "GetCompanyName");
  //}

  GetVisibleInternalImportUtility(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetVisibleInternalImportUtility");
  }

  GetVersionNumber(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetVersionNumber");
  }

  GetSTEVersion(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetSTEVersion");
  }

  GetSelectedPayrollYear(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetSelectedPayrollYear");
  }

  GetPayrollYear(): Observable<any> {
    let res1 = this.GetSelectedPayrollYear();
    let res2 = this.http.get<any>(API_END_POINT.account.global + "GetLatestTaxVersionOfYear");
    return forkJoin([res1, res2]);
  }

  IsPayrollFilterActive(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "IsPayrollFilterActive");
  }

  IsCoreFilterActive(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "IsCoreFilterActive");
  }

  GetPayrollFilterIDs(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetPayrollFilterIDs");
  }

  GetCoreFilterIDs(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetCoreFilterIDs");
  }

  //GetLoggedInUserName(): Observable<any> {
  //  return this.http.get<any>(API_END_POINT.account.global + "GetLoggedInUserName");
  //}

  //GetLoggedInUser(): Observable<any> {
  //  return this.http.get<any>(API_END_POINT.account.global + "GetLoggedInUser");
  //}

  //GetAvailableModules(): Observable<any> {
  //  return this.http.get<any>(API_END_POINT.account.global + "GetAvailableModules");
  //}

  DecryptPassword(encryptedPwd: IPassword): Observable<any> {
    return this.http.post<any>(API_END_POINT.account.global + "DecryptPassword", encryptedPwd);
  }

  GetLatestTaxVersionOfYear(procYear: string): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetLatestTaxVersionOfYear?procYear=" + procYear);
  }

  //GetLoggedInEmployeeID(): Observable<any> {
  //  return this.http.get<any>(API_END_POINT.account.global + "GetLoggedInEmployeeID");
  //}

  IsCurrentUserESS(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "IsCurrentUserESS");
  }

  //GetCurrentLoggedInDatabaseName(): Observable<any> {
  //  return this.http.get<any>(API_END_POINT.account.global + "GetCurrentLoggedInDatabaseName");
  //}

  IsDatabaseUpdateRequired(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "IsDatabaseUpdateRequired");
    //../api/Global/
  }

  UpdateDatabaseVersion(): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "UpdateDatabaseVersion");
  }

  GetPageSecurityRightsForLoggedInUser(securityKeys: string[]): Observable<any> {
    return this.http.get<any>(API_END_POINT.account.global + "GetPageSecurityRightsForLoggedInUser?securityKeys=" + securityKeys);
  }

  LogoutCurrentUser(): void {
    this.http.post<void>(API_END_POINT.account.global + "logout",null).subscribe();
  }

}
